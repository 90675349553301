<script>


import $ from 'jquery'; 

import Popper from "vue3-popper";

import axios from 'axios';

import DocumentEdit from '@/components/Core/Documents/DocumentEdit.vue'
import DocumentDelete from '@/components/Core/Documents/DocumentDelete.vue'

	
//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import axios from 'axios';

export default defineComponent ({
	name: 'LibraryPreview',
	mixins: [ MixinGeneralFunct ],
	components: {
		DocumentEdit
		,DocumentDelete
		,Popper
	},
	props: {'libraryId': String, 'auth':Object, 'secData':Object},

	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
		
			preloader:true,
						
			optionsGalleries:[],
			selectedLibrary:'',

			selectedDocumentId:'',

			//form
			library:[],
			documents:[]
		}
	},
	
	mounted:function(){
		
		
		this.loadDefaultValues();
		//console.log(this.libraryId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			var bodyFormData = new FormData();
			bodyFormData.set('libraryId',this.libraryId);
		
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Library/getLibraryPreviewData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
			//	console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.library = response.data.library;
				self.documents = response.data.documents;
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},

		
		
		closeModalBoxSecureHelper:function(myform){
			this.closeModalBoxSecure(myform);
		},
		
		//otocenie fotky o 90
		rotatePhoto:function(recordID){
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',recordID);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Library/controlPhotoRotate.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
				//	console.log('success2', response.data);
					if(response.data==1){
						self.createMessageBox('success','Photo','photoWasRotated');
						self.loadDefaultValues();
					}else if(response.data==0){
						self.createMessageBox('warning','Photo','photoCantBeRotated');
					}else if(response.data==2){
						self.createMessageBox('danger','Photo','photoWasNotRotated');
					}else if(response.data==3){
						self.createMessageBox('danger','Photo','accessDenied');
					}
					
					
				}
			})
			.catch(error => {
					console.log(error.response)
			}); 
		},

		//modal box gallery editacia
		loadModalFormDocumentEdit:function(recordID){
			this.cSession();
		//	console.log(recordID)
			this.selectedDocumentId=recordID;
			$('#myModalEditDocument').addClass('modal');
			$('#myModalEditDocument').modal({backdrop: "static"});
		},

		//modal box gallery delete
		loadModalFormDocumentDelete:function(recordID){
			this.cSession();
		//	console.log(recordID)
			this.selectedDocumentId=recordID;
			$('#myModalDeleteDocument').addClass('modal');
			$('#myModalDeleteDocument').modal({backdrop: "static"});
		},

		reloadAfterChange() {
		//	console.log(e);
			this.loadDefaultValues();
			this.selectedDocumentId='';
		},

		//zmena stavu
		changeState:function(recordId,state){
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('recordId',recordId);
			bodyFormData.set('state',state);
			
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Document/controlDocumentChangeState.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
				//	console.log('success2', response.data);
					if(response.data.resCode==1){
						self.createMessageBox('success','Document','dataWasSaved');
						self.loadDefaultValues();
					}else if(response.data.resCode==0){
						self.createMessageBox('warning','Document','dataCantBeSaved');
					}else if(response.data.resCode==2){
						self.createMessageBox('danger','Document','dataWasNotSaved');
					}else if(response.data.resCode==3){
						self.createMessageBox('danger','Document','accessDenied');
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
	
		}
		
	},
	
	watch: {
		libraryId() {
			this.loadDefaultValues();
		},
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalPreviewLibrary" class="fade show " role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
			<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.Preview') }} <span><b>{{ library.title }}</b> </span></h6>
				</div>
		
				<div class="modal-body">
					<div v-if="documents.length>0" class="row">
						<div class="col-12">
						
							<table class="table table-hover table-sm">
										
								<thead>
									<tr>
										<th>ID</th>
										
										<th>{{$t('msgCore.Action')}}</th>

										<th colspan="2">{{$t('msgCore.State')}}</th>
										
										<th>{{$t('msgCore.Title')}}</th>
										
										<th>{{$t('msgCore.Extension')}}</th>
										
										<th>{{$t('msgCore.Size')}}</th>
										
										<th>{{$t('msgCore.Created')}}</th>
										
										<th>{{$t('msgCore.Modified')}}</th>

										<th>{{$t('msgCore.AccessLevel')}}</th>									
									</tr>
								</thead>
						
							
								<tr v-for="document in documents" :key="document" >
									<td>{{document.id}}</td>
									
									<td>
										<div class="btn-group">				
											<button type="button" :disabled="!auth.documentsEdit" v-on:click="loadModalFormDocumentEdit(document.id)" :title="$t('msgCore.btnEdit')" id="myModalBtn" class="btn btn-secondary btn-sm"><i class="smire-icon fas fa-edit"></i></button>
											
											<button type="button" :disabled="!auth.documentsDelete" v-on:click="loadModalFormDocumentDelete(document.id)" :title="$t('msgCore.btnDelete')" id="myModalBtn" class="btn btn-danger btn-sm"><i class="smire-icon fas fa-trash-alt"></i></button>
																									
										</div>
									</td>

									<td  :title="$t('msgCore.' + document.state_name)">
										<i class="fas fa-circle" :class="{'text-success':document.state==1,'text-danger':document.state==2}" ></i>
									</td>	

									<td>
										<div class="togglebutton">
											<label>
												<input v-model='document.isActive' :disabled="!auth.documentsChangeState" type="checkbox" v-on:change="changeState(document.id,document.state)">
												<span class="toggle"></span>
											</label>
										</div>
									</td>
										
									<td><a :href="document.url">{{document.title}}</a></td>
									
									<td>{{document.extension}}</td>
									
									<td>{{document.fileSize}}</td>
									
									<td>
										<Popper :content="document.creator+', '+document.createdView">
											<button class="personCircle v-center" v-bind:style="{background:document.creatorColor}">{{ document.creatorNameLetter+''+document.creatorSurnameLetter }}</button>
										</Popper>
									</td>

									<td>
										<Popper v-if="document.editor" :content="document.editor+', '+document.modifiedView">
											<button class="personCircle v-center" v-bind:style="{background:document.editorColor}">{{ document.editorNameLetter+''+document.editorSurnameLetter }}</button>
										</Popper>
									</td>
									
									<td>{{document.access_name}}</td>	
								
								</tr>
							
							</table>
							
						</div>
					</div>
					
					<div v-else class="row">
						<div class="col-12">
							<div  class="alert alert-warning" role="alert">
								{{$t('msgCore.forThisSelectExistAnyData')}}
							</div>	
						</div>							
					</div>
					
				</div>
				
				<div class="modal-footer">
					<div class="btn-group">
						<button type="button" v-on:click="closeModalBoxSecureHelper('myModalPreviewLibrary',null)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>

						
					</div>	
				</div>
					
			</div>

		</div>
		
	</div>
	
	<DocumentEdit :auth="auth" :secData="secData" :documentId="String(selectedDocumentId)" @changeRecord="reloadAfterChange"></DocumentEdit>
	<DocumentDelete :auth="auth" :secData="secData" :documentId="String(selectedDocumentId)" @changeRecord="reloadAfterChange"></DocumentDelete>

</template>


