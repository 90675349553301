<script>

export default {
	name: 'MainMenu',
	
	components: {
	
	},
	
	data() {
		return {
			prefix:'',
			activeItem: null,
			activeClass: null,
			currentUrl: null
		}
	},
	
	props: {'propActLang': String, 'menuData': Array},
	
	mounted:function(){
		//if(localStorage.Lang!=null) {this.actLang=localStorage.Lang} else { this.actLang='sk'}
		
		var myPath = window.location.pathname;
		var n = myPath.length;
		this.currentUrl=myPath.substr(1,n-2);
		if(myPath.substring(0, 7)=='/admin/'){
			this.prefix = '/admin';
			var str = myPath.substr(1);
			var resNumber = str.indexOf("/")
			var res = str.substr(resNumber);
			n = res.length;
			res = res.substr(1,n-2);
			
			this.currentUrl=res;
	
		}
		
	},
	
	methods: {
		
		selectItem(i) {
			for(var j = 0; j < this.menuData.length; j++){
				//this.menuData[j].activeParent=false;
			}
            if(this.activeItem === i){
				this.activeClass = this.activeClass===i?null:i;
			}else{
				this.activeClass = i
			}
			this.activeItem = i;
        }
	
	},	
	
	watch: {
		propActLang () {
			this.$i18n.locale = this.propActLang
		},
		/*menuData () {
			for(var i = 0; i < this.menuData.length; i++){
				//this.menuData[i].activeParent=false;
				for(var j = 0; j < this.menuData[i].subMenus.length; j++){
					if(this.menuData[i].subMenus[j].urlOrigin==this.currentUrl){
						//this.menuData[i].activeParent=true;
					}
				}
			}
		}*/
	},

	computed:{
		
	},
	
}

</script>

<template>
	<ul class='main-menu'>
		<li v-for='(menuItem, index) in menuData' :key="menuItem"	:class='{ active: index === activeClass||menuItem.activeParent}' v-on:click='selectItem(index)'><a :href='menuItem.url' :class='{deeper:menuItem.isDeeper, haveSubmenu:menuItem.isDeeper, active: index === activeClass}'  >{{$t('msgCore.'+menuItem.name)}}</a><ul v-if='menuItem.isDeeper' class='submenu'><li v-for='(menuItem2) in menuItem.subMenus' :key="menuItem2"><a :href='menuItem2.url' :class='{active:menuItem2.urlOrigin==currentUrl}'> {{$t('msgCore.'+menuItem2.name)}}</a></li></ul></li></ul>
</template>


