<script>


import Popper from "vue3-popper";

import axios from 'axios';

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import tinymce from 'tinymce';
//import axios from 'axios';


//modal box preview photos to article


export default defineComponent ({
	name: 'LibraryEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
		Popper
		
	},
	props: {'libraryId': String, 'auth':Object, 'secData':Object},
	emits: ["libraryId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			recordID:null,
			isNewRecord:true,
		
			optionsAccessLevels: [],
			optionsCategories:[],
			optionsStates:[],
			optionsLanguages:[],
			
			//form
			library:[]
			
		}
	},
	
	mounted:function(){
	
		//console.log(this.libraryId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			//console.log(this.libraryId);				
			if(this.libraryId){
				this.isNewRecord=false;
			}else{
				this.isNewRecord=true;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('libraryId',this.libraryId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Library/getLibraryData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.library = response.data.library;
				
				self.optionsAccessLevels = response.data.optionsAccessLevels;
				self.optionsStates = response.data.optionsStates;
				self.optionsCategories = response.data.optionsCategories;
				self.optionsLanguages = response.data.optionsLanguages;
		
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,libraryId){
			this.unlockLibrary(libraryId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		
		checkSave:function(){
				
			if(!this.library.title){this.library.titleError = true; }else{this.library.titleError = false;}
			if(!this.library.alias){this.library.aliasError = true; }else{this.library.aliasError = false;}
			if(!this.library.category){this.library.categoryError = true; }else{this.library.categoryError = false;}
			if(!this.library.state){this.library.stateError = true; }else{this.library.stateError = false;}
			if(!this.library.access){this.library.accessError = true; }else{this.library.accessError = false;}
			
			if(this.library.title && this.library.alias && this.library.category && this.library.state && this.library.access){
				return true;
			}else{
				this.createMessageBox('warning','Library',this.$t('msgCore.fillInAllData'));
				return false;
			}
		},	
		
		saveLibrary:function(){
							
			if(this.checkSave()){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myLibrary',JSON.stringify(this.library));
				
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Library/controlLibrarySave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
					//	console.log('success2', response.data);
						if(response.data.resCode==1){
							self.createMessageBox('success','Library',this.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')
						
							self.closeModalBoxSecure('myModalEditLibrary');
							
						}else if(response.data.resCode==0){
							self.createMessageBox('warning','Library',this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalEditLibrary');
						}else if(response.data.resCode==2){
							self.createMessageBox('danger','Library',this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalEditLibrary');
						}else if(response.data.resCode==3){
							self.createMessageBox('danger','Library',this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalEditLibrary');
						}
						self.library = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
				
			}
			
		},
		
		//odomknutie zaznamu
		unlockLibrary:function(libraryId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',libraryId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Library/controlLibraryUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
				//	console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},

		createAlias:function(){
				
				if(this.library.title && this.isNewRecord){
					
					var bodyFormData = new FormData();
					bodyFormData.set('title',this.library.title);
					
					axios({
						method: 'post',
						url: '/admins/app/core/Library/controlLibraryCreateAlias.php',
						data: bodyFormData
					})
					.then(response => {
						if (response.data.error) {
								console.log('error', response.data.error)
						} else {
						//	console.log('success2', response.data);
							this.library.alias = response.data.library.alias;
						}
					})
  
					.catch(error => {
						console.log(error.response)
					}); 
				}
			},
			
			resetIntroducePhoto:function(){
				this.article.photo = '';
				this.article.photoData = '';
			},

			

			//natiahne udaje k vybranej fotke
			photoData:function(photoId){
			
				var bodyFormData = new FormData();
				bodyFormData.set('photoId',photoId);

				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Library/getLibraryPhotoData.php',
					data: bodyFormData
				})
				.then(function (response) {
					//console.log(response.data);
					self.article.photoData = response.data.photoData;
					
				})
					.catch(function (error) {
					console.log(error);
				});
			},
			
			//akcia po vybere fotky
			reloadAfterSelect(e) {
			//	console.log(e);
				this.article.photo=e;
				this.photoData(e);
			}

	},
	
	watch: {
		libraryId() {
			this.loadDefaultValues();
		},
		'library.title': function () {
			this.createAlias();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalEditLibrary" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
		<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.Library') }} <span v-if="!library.isNewRecord"><b>{{ library.title }}</b> id: {{library.id}}</span></h6>
				</div>
		
				<div v-if="accData.modulAcc && (auth.librariesNew || auth.librariesEdit)" class="modal-body">
						
						<div class="row">
							<div class="col-12">
								<div v-if="library.isCheckedOut && secData.userId != library.isCheckedOutId" class="angle">
									<span class="angle-inner" :class="{'angle-warning':library.isCheckedOut}"><i class="fa" :class="{'angle-warning':library.isCheckedOut, 'fa-exclamation-triangle':library.isCheckedOut}" aria-hidden="true"></i> {{$t('msgCore.Edited')}} </span >
									
									<Popper v-if="library.isCheckedOut" :content="library.isCheckedOutName+', '+library.isCheckedOutDate">
										<button class="personCircle v-center" v-bind:style="{background:library.isCheckedOutColor}">{{ library.isCheckedOutLetterName+''+library.isCheckedOutLetterSurname }}</button>
									</Popper>
								</div>
							</div>
						</div>
						
						<div class="row">
						
							<div class="col-md-8 col-12">
						
								<div class="form-row">
									
																				
									<div class="form-group col-md-6 col-12">
										<label :class="{'text-danger':library.titleError}"><b>{{ $t('msgCore.Title') }}</b> </label>
										<input type="text" class="form-control form-control-sm" :class="{'is-invalid':library.titleError}" v-model="library.title" v-on:change="checkSave()">
										<div v-show="library.titleError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
									</div>
									
									<div class="form-group col-md-6 col-12">
										<label :class="{'text-danger':library.aliasError}"><b>{{ $t('msgCore.alias') }}</b> </label>
										<input type="text" :disabled="!isNewRecord" class="form-control form-control-sm" :class="{'is-invalid':library.aliasError}" v-model="library.alias" v-on:change="checkSave()">
										<div v-show="library.aliasError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
									</div>
									
									<div class="form-group col-md-4 col-12">
										<label :class="{'text-danger':library.categoryError}"><b>{{ $t('msgCore.Category') }}</b></label>
																							
										<select v-model='library.category' class="form-control form-control-sm" :class="{'is-invalid':library.categoryError}" v-on:change="checkSave()" >
											<option  value="">{{ $t('msgCore.selectCategory') }}</option>
											<option v-for="(option) in optionsCategories" :value="option.value" :key="option" >{{ option.title }}</option>
										</select>
										<div v-show="library.categoryError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectCategory')}}</span></div>
									</div>
									
									<div class="form-group col-md-4 col-12">
										<label><b>{{ $t('msgCore.folder') }}</b> </label>
										<input type="text" disabled class="form-control form-control-sm" v-model="library.folder" >
										
									</div>
									
									
																			
									
								</div>
							</div>	
								
							<div class="col-md-4 col-12 form-control-system-data">
								
								<div class="form-row">
								
									<div class="form-group col-12">
										<label :class="{'text-danger':library.accessError}"><b>{{ $t('msgCore.AccessLevel') }}</b></label>
																							
										<select v-model='library.access' class="form-control form-control-sm " :class="{'is-invalid':library.accessError}" v-on:change="checkSave()">
											<option value="">{{ $t('msgCore.selectLevel') }}</option>
											<option v-for="(option) in optionsAccessLevels" :value="option.value" :key="option" >{{ option.title }}</option>
										</select>
										<div v-show="library.accessError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectLevel')}}</span></div>
									</div>
									
									<div class="form-group col-12">
										<label :class="{'text-danger':library.stateError}"><b>{{ $t('msgCore.State') }}</b></label>
										
											<div  v-for="(option) in optionsStates" :key="option" class="form-check">
											<label class="form-check-label">
												<input class="form-check-input" type="radio" name="optionsStates"  v-model='library.state' v-on:change="checkSave()" :value="option.value">
												{{ $t('msgCore.'+option.title) }}
												<span class="circle">
												<span class="check"></span>
												</span>
											</label>
											</div>
											<div v-show="library.stateError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectState')}}</span></div>
									</div>
								
								</div>
								
							</div>										
								
						</div>	
							
					
				</div>
				
				<div v-else class="modal-body">
					<div class="row">
						<div class="col-12">
							<div class="alert alert-warning" role="alert">
								{{$t('msgCore.accessDenied')}}
							</div>
						</div>
					</div>
				</div>
				
				<div class="modal-footer">
				
					<div class="btn-group">
						<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditLibrary',library.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
						
						<button type="button" :disabled="((!auth.librariesEdit) || (!auth.librariesNew)) || !accData.modulAcc" v-on:click="saveLibrary()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
					</div>	
					
				</div>
				
			</div>

		</div>
			
	</div>
		

	
</template>


