import { createApp } from 'vue'
import App from './App.vue'


//import { QrReader } from 'vue3-qr-reader';//funguje ale je to kopia z povodneho, cez setup()
import { createI18n } from 'vue-i18n'
import { createRouter, createWebHistory } from 'vue-router'


//views
import myLoginPage from './components/Common/LoginPage.vue'
import UserChangePassForm from './components/Core/UserChangePassword/ChangePass.vue'
import UsersList from './components/Core/Users/Users.vue'
import GroupsList from './components/Core/Groups/Groups.vue'
import LevelsList from './components/Core/Levels/Levels.vue'
import ModsList from './components/Core/Mods/Mods.vue'
import MenuItemsList from './components/Core/MenuItems/MenuItems.vue'
import ParametersList from './components/Core/Parameters/Parameters.vue'
import LangMutationList from './components/Core/Mutations/Mutations.vue'
import ArticlesList from './components/Core/Articles/Articles.vue'
import ArticleCategoriesList from './components/Core/ArticleCategories/ArticleCategories.vue'
import GalleryCategoriesList from './components/Core/GalleryCategories/GalleryCategories.vue'
import LibraryCategoriesList from './components/Core/LibraryCategories/LibraryCategories.vue'
import GalleriesList from './components/Core/Galleries/Galleries.vue'
import LibrariesList from './components/Core/Libraries/Libraries.vue'
import DocumentsList from './components/Core/Documents/Documents.vue'
import PhotosList from './components/Core/Photos/Photos.vue'
import SysLogsList from './components/Core/SysLogs/BasicList.vue'
import custDashboard from './components/Custom/Dashboard.vue'

//reinoo
import reinooDays from './components/CustomReinoo/ReinooDays/BasicList.vue'
import reinooMonths from './components/CustomReinoo/ReinooMonths/BasicList.vue'
import reinooYears from './components/CustomReinoo/ReinooYears/BasicList.vue'
import reinooPohyb from './components/CustomReinoo/ReinooPohyb/BasicList.vue'


import pathNotFound from './components/Common/PageWasNotFound.vue'

//router
const webFolder = ''; //admins
const router = createRouter({
  //history: createWebHistory('/'),
  history: createWebHistory('/' + webFolder),
 // mode: 'history',
  routes: [
	{ path: '/', component: custDashboard },
	
	{ path: '/users', component: UsersList },
	{ path: '/groups', component: GroupsList },
	{ path: '/levels', component: LevelsList },
	{ path: '/mods', component: ModsList },
	{ path: '/menu-items', component: MenuItemsList },
	{ path: '/parameters', component: ParametersList },
	{ path: '/lang-mutations', component: LangMutationList },
	{ path: '/articles', component: ArticlesList },
	{ path: '/article-categories', component: ArticleCategoriesList },
	{ path: '/gallery-categories', component: GalleryCategoriesList },
	{ path: '/library-categories', component: LibraryCategoriesList },
	{ path: '/galleries', component: GalleriesList },
	{ path: '/libraries', component: LibrariesList },
	{ path: '/documents', component: DocumentsList },
	{ path: '/photos', component: PhotosList },
	{ path: '/user-change-password', component: UserChangePassForm },
	{ path: '/sys-logs', component: SysLogsList },
	{ path: '/login', component: myLoginPage },

	//mailing
	{ path: '/reinoo-days', component: reinooDays },
	{ path: '/reinoo-months', component: reinooMonths },
	{ path: '/reinoo-years', component: reinooYears },
	{ path: '/reinoo-pohyb', component: reinooPohyb },


	{ path: '/:pathMatch(.*)*', component: pathNotFound }
  ],
})



//import jazykov
import { languages } from './importLangs.js'
import { defaultLocale } from './importLangs.js'
const messages = Object.assign(languages)
const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: 'sk',
  messages,
})




//createApp(App).use(router).use(i18n).use(QrReader).mount('#app')


const app = createApp(App)
app.config.globalProperties.adminfolder = webFolder;
//app.component("Datepicker", Datepicker);
app.use(router).use(i18n)//.use(QrReader)
// add more functionality to myV3App

// now we're ready to mount
app.mount('#app')

//.use(QrReader)

