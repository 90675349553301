<script>

import Popper from "vue3-popper";

import axios from 'axios';

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'


export default defineComponent ({
	name: 'GalleryEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
		Popper
		
	},
	props: {'galleryId': String, 'auth':Object, 'secData':Object},
	emits: ["galleryId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			recordID:null,
			isNewRecord:true,
		
			optionsAccessLevels: [],
			optionsCategories:[],
			optionsStates:[],
			optionsLanguages:[],
			
			//form
			gallery:[]
			
		}
	},
	
	mounted:function(){
	
		//console.log(this.galleryId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			if(this.galleryId=='null'){
				this.isNewRecord=true;
			}else if(this.galleryId && this.galleryId>0){
				this.isNewRecord=false;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('galleryId',this.galleryId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Gallery/getGalleryData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.gallery = response.data.gallery;
				
				self.optionsAccessLevels = response.data.optionsAccessLevels;
				self.optionsStates = response.data.optionsStates;
				self.optionsCategories = response.data.optionsCategories;
				self.optionsLanguages = response.data.optionsLanguages;
		
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,galleryId){
			this.unlockGallery(galleryId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		
		checkSave:function(){
				
			if(!this.gallery.title){this.gallery.titleError = true; }else{this.gallery.titleError = false;}
			if(!this.gallery.alias){this.gallery.aliasError = true; }else{this.gallery.aliasError = false;}
			if(!this.gallery.category){this.gallery.categoryError = true; }else{this.gallery.categoryError = false;}
			if(!this.gallery.state){this.gallery.stateError = true; }else{this.gallery.stateError = false;}
			if(!this.gallery.access){this.gallery.accessError = true; }else{this.gallery.accessError = false;}
			
			if(this.gallery.title && this.gallery.alias && this.gallery.category && this.gallery.state && this.gallery.access){
				return true;
			}else{
				this.createMessageBox('warning','Gallery',this.$t('msgCore.fillInAllData'));
				return false;
			}
		},	
		
		saveGallery:function(){
							
			if(this.checkSave()){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myGallery',JSON.stringify(this.gallery));
				
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Gallery/controlGallerySave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data.resCode==1){
							self.createMessageBox('success','Gallery',self.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')
						
							self.closeModalBoxSecure('myModalEditGallery');
							
						}else if(response.data.resCode==0){
							self.createMessageBox('warning','Gallery',self.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalEditGallery');
						}else if(response.data.resCode==2){
							self.createMessageBox('danger','Gallery',self.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalEditGallery');
						}else if(response.data.resCode==3){
							self.createMessageBox('danger','Gallery',self.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalEditGallery');
						}
						self.gallery = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
				
			}
			
		},
		
		//odomknutie zaznamu
		unlockGallery:function(galleryId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',galleryId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Gallery/controlGalleryUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},

		createAlias:function(){
				
				if(this.gallery.title && this.isNewRecord){
					
					var bodyFormData = new FormData();
					bodyFormData.set('title',this.gallery.title);
					
					axios({
						method: 'post',
						url: '/admins/app/core/Gallery/controlGalleryCreateAlias.php',
						data: bodyFormData
					})
					.then(response => {
						if (response.data.error) {
								console.log('error', response.data.error)
						} else {
							console.log('success2', response.data);
							this.gallery.alias = response.data.gallery.alias;
						}
					})
  
					.catch(error => {
						console.log(error.response)
					}); 
				}
			},
			
			resetIntroducePhoto:function(){
				this.article.photo = '';
				this.article.photoData = '';
			},

			

			//natiahne udaje k vybranej fotke
			photoData:function(photoId){
			
				var bodyFormData = new FormData();
				bodyFormData.set('photoId',photoId);

				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Gallery/getGalleryPhotoData.php',
					data: bodyFormData
				})
				.then(function (response) {
					//console.log(response.data);
					self.article.photoData = response.data.photoData;
					
				})
					.catch(function (error) {
					console.log(error);
				});
			},
			
			//akcia po vybere fotky
			reloadAfterSelect(e) {
				console.log(e);
				this.article.photo=e;
				this.photoData(e);
			}

	},
	
	watch: {
		galleryId() {
			this.loadDefaultValues();
		},
		'gallery.title': function () {
			this.createAlias();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalEditGallery" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
		<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.Gallery') }} <span v-if="!gallery.isNewRecord"><b>{{ gallery.title }}</b> id: {{gallery.id}}</span></h6>
				</div>
		
						<div v-if="accData.modulAcc && (auth.galleriesNew || auth.galleriesEdit)" class="modal-body">
								
								<div class="row">
									<div class="col-12">
										<div v-if="gallery.isCheckedOut && secData.userId != gallery.isCheckedOutId" class="angle">
											<span class="angle-inner" :class="{'angle-warning':gallery.isCheckedOut}"><i class="fa" :class="{'angle-warning':gallery.isCheckedOut, 'fa-exclamation-triangle':gallery.isCheckedOut}" aria-hidden="true"></i> {{$t('msgCore.Edited')}} </span >
											
											<Popper v-if="gallery.isCheckedOut" :content="gallery.isCheckedOutName+', '+gallery.isCheckedOutDate">
												<button class="personCircle v-center" v-bind:style="{background:gallery.isCheckedOutColor}">{{ gallery.isCheckedOutLetterName+''+gallery.isCheckedOutLetterSurname }}</button>
											</Popper>
										</div>
									</div>
								</div>
								
								<div class="row">
								
									<div class="col-md-8 col-12">
								
										<div class="form-row">
											
																						
											<div class="form-group col-md-6 col-12">
												<label :class="{'text-danger':gallery.titleError}"><b>{{ $t('msgCore.Title') }}</b> </label>
												<input type="text" class="form-control form-control-sm" :class="{'is-invalid':gallery.titleError}" v-model="gallery.title" v-on:change="checkSave()">
												<div v-show="gallery.titleError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
											</div>
											
											<div class="form-group col-md-6 col-12">
												<label :class="{'text-danger':gallery.aliasError}"><b>{{ $t('msgCore.alias') }}</b> </label>
												<input type="text" :disabled="!isNewRecord" class="form-control form-control-sm" :class="{'is-invalid':gallery.aliasError}" v-model="gallery.alias" v-on:change="checkSave()">
												<div v-show="gallery.aliasError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
											</div>
											
											<div class="form-group col-md-4 col-12">
												<label :class="{'text-danger':gallery.categoryError}"><b>{{ $t('msgCore.Category') }}</b></label>
																									
												<select v-model='gallery.category' class="form-control form-control-sm" :class="{'is-invalid':gallery.categoryError}" v-on:change="checkSave()">
													<option  value="">{{ $t('msgCore.selectCategory') }}</option>
													<option v-for="(option) in optionsCategories" :value="option.value" :key="option">{{ option.title }}</option>
												</select>
												<div v-show="gallery.categoryError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectCategory')}}</span></div>
											</div>
											
											<div class="form-group col-md-4 col-12">
												<label><b>{{ $t('msgCore.folder') }}</b> </label>
												<input type="text" disabled class="form-control form-control-sm" v-model="gallery.folder" >
												
											</div>
											
											
																					
											
										</div>
									</div>	
										
									<div class="col-md-4 col-12 form-control-system-data">
										
										<div class="form-row">
										
											<div class="form-group col-12">
												<label :class="{'text-danger':gallery.accessError}"><b>{{ $t('msgCore.AccessLevel') }}</b></label>
																									
												<select v-model='gallery.access' class="form-control form-control-sm " :class="{'is-invalid':gallery.accessError}" v-on:change="checkSave()">
													<option value="">{{ $t('msgCore.selectLevel') }}</option>
													<option v-for="(option) in optionsAccessLevels" :value="option.value" :key="option">{{ option.title }}</option>
												</select>
												<div v-show="gallery.accessError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectLevel')}}</span></div>
											</div>
											
											<div class="form-group col-12">
												<label :class="{'text-danger':gallery.stateError}"><b>{{ $t('msgCore.State') }}</b></label>
												
													<div  v-for="(option) in optionsStates" :key="option" class="form-check">
													<label class="form-check-label">
														<input class="form-check-input" type="radio" name="optionsStates"  v-model='gallery.state' v-on:change="checkSave()" :value="option.value">
														{{ $t('msgCore.'+option.title) }}
														<span class="circle">
														<span class="check"></span>
														</span>
													</label>
													</div>
													<div v-show="gallery.stateError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectState')}}</span></div>
											</div>
										
										</div>
										
									</div>										
										
								</div>	
									
							
						</div>
						
						<div v-else class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="alert alert-warning" role="alert">
										{{$t('msgCore.accessDenied')}}
									</div>
								</div>
							</div>
						</div>
						
						<div class="modal-footer">
						
							<div class="btn-group">
								<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditGallery',gallery.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
								
								<button type="button" :disabled="!auth.galleriesEdit || !accData.modulAcc" v-on:click="saveGallery()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
							</div>	
							
						</div>
					
			
				
				
			
			</div>

		</div>
			
	</div>
		

	
</template>


