<script>

export default {
	name: 'MainVersion',
	
	components: {
	
	},
	
	data() {
		return {
			
		}
	},
	
	props: {'version': String},
	
	mounted:function(){
		
	},
	
	methods: {
			
	},	
	
	watch: {
		
	},

	computed:{
		
	},
	
}

</script>

<template>
	<p>{{version}}</p>
</template>


