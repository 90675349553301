<script>

export default {
	name: 'CountRecordsOptions',
	
	components: {
	
	},
	
	data() {
		return {
			optionsCountRecords:[{value:5,title:5},{value:10,title:10},{value:20,title:20},{value:50,title:50},{value:100,title:100}]
			,myCountRecords:''
		}
	},
	emits: ["changeAfterSelectCount"], 
	props: {'selectedRecords': Number},
	
	mounted:function(){
		this.myCountRecords=this.selectedRecords;
	},
	
	methods: {
		selCount:function(){
			this.$emit('changeAfterSelectCount', this.myCountRecords)
		}
	},	
	
	watch: {
		
	},

	computed:{
		
	},
	
}

</script>

<template>
	<div class="form-group form-group-filter">		
		<select v-model="myCountRecords" class="form-control form-control-sm" v-on:change="selCount()" >
			
			<option v-for="(option) in optionsCountRecords" :value="option.value" :key="option" >{{ option.title }}</option>
		
		</select>
	</div>
</template>


