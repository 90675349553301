<script>


import axios from 'axios';

var sha512 = require('js-sha512');

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import axios from 'axios';

export default defineComponent ({
	name: 'UserEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: {'userId': String, 'auth':Object, 'secData':Object},
	emits: ["userId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			recordID:null,
		
			basicData:[],
			
			user:[]
		}
	},
	
	mounted:function(){
		
		
		
		//console.log(this.userId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			//console.log(this.userId);				
			if(this.userId){
				this.isNewRecord=false;
			}else{
				this.isNewRecord=true;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('recordID',this.userId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/User/getUserPswdData.php',
				data: bodyFormData
			})
			.then(function (response) {
				
				self.preloader=false
				
				//console.log(response.data);
				self.accData = response.data.accData;
				
				self.basicData = response.data.basicData;
				self.user = response.data.user;
			
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,userId){
			this.unlockUser(userId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		//odomknutie zaznamu
		unlockUser:function(){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',this.userId);
			
			
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/User/controlUserUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						//self.createMessageBox('success','User','dataWasUnlocked');
						//self.loadDefaultValues();
						//self.$emit('userId', '') //tu pokracuj emit !!!!!!!!!!!!! pri zavreti modal okna
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//self.createMessageBox('warning','User','dataCantBeUnlocked');
						//self.loadDefaultValues();
					}else if(response.data==2){
						//self.createMessageBox('danger','User','dataWasNotUnlocked');
						//self.loadDefaultValues();
					}else if(response.data==3){
						//self.createMessageBox('danger','User','accessDenied');
						//self.loadDefaultValues();
					}
					
				}
			})
			.catch(error => {
				console.log(error)
			}); 
			//self.$emit('update:userId', '')
		},
		
		savePswd:function(){
							
			if(this.checkPasswordV2()){
				
				var bodyFormData = new FormData();
				bodyFormData.set('id',this.userId);
				bodyFormData.set('p',sha512(this.user.password));
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/User/controlUserPswdSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log(response.data)
						if(response.data.resCode==1){
							self.createMessageBox('success','Password',this.$t('msgCore.dataWasSaved'));
							self.unlockUser(this.userId);
							self.$emit('changeRecord', '')
							self.user = [];
							self.closeModalBoxSecure('myModalEditUserPswd');
						}else if(response.data.resCode==0){
							self.createMessageBox('warning','Password',this.$t('msgCore.dataCantBeSaved'));
						}else if(response.data.resCode==2){
							self.createMessageBox('danger','Password',this.$t('msgCore.dataWasNotSaved'));
						}else if(response.data.resCode==3){
							self.createMessageBox('danger','Password',this.$t('msgCore.accessDenied'));
						}
						
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
			
			}
			
		},
		
		
		
			
			
		checkPasswordV2:function()
		{
			//1.
			if(this.user.password.length < 6){
				this.user.passwordError = true;
				this.createMessageBox('danger','Password','passwordIsTooShort');
				return false;
			}
			
			//2.
			var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/; 
			if (!re.test(this.user.password)) {
				this.user.passwordError = true;	
				this.createMessageBox('danger','Password','passwordMustHaveSmallBigLettersAndNumbers');
				
				return false;
			}
			
			//3.
			if (this.user.password != this.user.passwordRepeat){
				this.user.passwordError = true;		
				this.user.passwordRepeatError = true;	
				this.createMessageBox('danger','Password','passwordsDoNotMatch');
				
				return false;
			}
			
			this.user.passwordError = false;
			this.user.passwordRepeatError = false;
			return true;

		},
		
		//generuje nahodne heslo
		generatePswd:function(){
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/User/controlUserPswdGenerate.php'
			})
			.then(response => {
				if(response.data.error) {
					console.log('error', response.data.error)
				}else{
					if(response.data.resCode==3){
						self.createMessageBox('danger','Password','accessDenied');
					}else{
						self.user.password = response.data.newPswd; 
						self.user.passwordRepeat = response.data.newPswd;
						self.checkPasswordV2();
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
		
		},
		
		//zobrazi heslo
		showPswd: function(){
			this.user.showInput = this.user.showInput===true?false:true;
		}
		
			
		
	},
	
	watch: {
		userId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalEditUserPswd" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
			<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.User') }} <b><span>{{ basicData.name }} {{ basicData.surname }}</span></b></h6>
				</div>
		
				<div v-if="accData.modulAcc && (auth.usersChangePasswd)" class="modal-body">
				
					<div class="row">
						<div class="col-12">
							<p>{{$t('msgCore.setPassword')}} <button type="button" v-on:click="generatePswd()" :title="$t('msgCore.GeneratePassword')" class="btn btn-success btn-sm"><i class="smire-icon fas fa-key"></i></button></p>
						</div>	
					</div>
					
					<div class="form-row">
						
						<div class="form-group col-md-3 col-12">
							<div class="input-group">
								<input :type="user.showInput ? 'text' : 'password'" autocomplete="new-password" v-on:change="checkPasswordV2()" class="form-control form-control-sm" :class="{'is-invalid':user.passwordError}" v-model="user.password" :placeholder="$t('msgCore.Password')" >
								<button type="button" class="btn btn-outline-info btn-sm my-btn" v-on:click="showPswd()">
									<i v-if="!user.showInput" :title="$t('msgCore.showPassword')" class="smire-icon far fa-eye"></i>
									<i v-else :title="$t('msgCore.hidePassword')" class="smire-icon far fa-eye-slash"></i>
								</button>
							</div>
							<div v-show="user.passwordError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
						</div>
						
						<div class="form-group col-md-3 col-12">
							<div class="input-group">
								<input :type="user.showInput ? 'text' : 'password'" autocomplete="new-password" v-on:change="checkPasswordV2()" class="form-control form-control-sm" :class="{'is-invalid':user.passwordRepeatError}" v-model="user.passwordRepeat" :placeholder="$t('msgCore.passwordRepeat')" >
								<button type="button"  class="btn btn-outline-info btn-sm my-btn" v-on:click="showPswd()">
									<i v-if="!user.showInput" :title="$t('msgCore.showPassword')" class="smire-icon far fa-eye"></i>
									<i v-else :title="$t('msgCore.hidePassword')" class="smire-icon far fa-eye-slash"></i>
								</button>								
							</div>
							<div v-show="user.passwordRepeatError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
						</div>	
					</div>	
					
				</div>
				
				<div v-else class="modal-body">
					<div class="row">
						<div class="col-12">
							<div class="alert alert-warning" role="alert">
								{{$t('msgCore.accessDenied')}}
							</div>
						</div>
					</div>
				</div>
				
				<div class="modal-footer">
				
					<div class="btn-group">
						<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditUserPswd',user.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
						
						<button type="button" :disabled="(!auth.usersChangePasswd) || !accData.modulAcc" v-on:click="savePswd()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
					</div>	
					
				</div>
				
			</div>

		</div>
		
	</div>
		
		
</template>


