<script>



import axios from 'axios';


//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import axios from 'axios';

export default defineComponent ({
	name: 'GroupDelete',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: {'groupId': String, 'auth':Object, 'secData':Object},
	emits: ["groupId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
		
			preloader:true,
			
			recordID:null,
			
			//form
			group:[]
		}
	},
	
	mounted:function(){
		
		//console.log(this.groupId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
	
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			//console.log(this.groupId);				
			if(this.groupId){
				this.isNewRecord=false;
			}else{
				this.isNewRecord=true;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('recordID',this.groupId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Group/getGroupData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.group = response.data.group;
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,groupId){
			this.unlockGroup(groupId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		//odomknutie zaznamu
		unlockGroup:function(groupId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',groupId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Group/controlGroupUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},
		
		deleteGroup:function(groupId){
			
			if(groupId){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myGroup',JSON.stringify(this.group));
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Group/controlGroupDelete.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data==1){
							self.createMessageBox('success','Group',this.$t('msgCore.dataWasDeleted'));
							self.$emit('changeRecord', '')
							self.closeModalBoxSecure('myModalDeleteGroup');
						}else if(response.data==0){
							self.createMessageBox('warning','Group',this.$t('msgCore.dataCantBeDeleted'));
							self.closeModalBoxSecure('myModalDeleteGroup');
						}else if(response.data==2){
							self.createMessageBox('danger','Group',this.$t('msgCore.dataWasNotDeleted'));
							self.closeModalBoxSecure('myModalDeleteGroup');
						}else if(response.data==3){
							self.createMessageBox('danger','Group',this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalDeleteGroup');
						}
						self.group = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
			}
			
		}
	
		
	},
	
	watch: {
		groupId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	
	<div id="myModalDeleteGroup" class="fade show " role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width:100%; max-width: none;">
			<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.DELETE_RECORD') }}</h6>
				</div>
		
						<div v-if="accData.modulAcc && (auth.groupsDelete)" class="modal-body">
									
									<form id="myAdvanceDataDelete">
										<div class="form-row">
										
											
											<div class="form-group col-md-2 col-12">
																																						
												<label><b>{{ $t('msgCore.ID') }}</b></label><br>
												{{group.id}}
											</div>
											
											<div class="form-group col-md-10 col-12">
																																						
												<label><b>{{ $t('msgCore.Title') }}</b></label><br>
												{{ group.title }}
											</div>
											
																							
											<div class="form-group col-md-6 col-12">
												<label for="sum"><b>{{ $t('msgCore.AccessLevel') }}</b> </label><br>
												{{ group.access_name }}
											</div>
											
											
											
										</div>	
									</form>
								
						</div>
						
						<div v-else class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="alert alert-warning" role="alert">
										{{$t('msgCore.accessDenied')}}
									</div>
								</div>
							</div>
						</div>
						
						
						
						<div class="modal-footer">
						
							<div class="btn-group">
								<button type="button" v-on:click="closeModalBoxSecureHelper('myModalDeleteGroup',group.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>

								<button type="button" :disabled="(!auth.groupsDelete) || !accData.modulAcc" v-on:click="deleteGroup(group.id)" class="btn btn-danger btn-sm">{{ $t('msgCore.btnDelete') }}</button>
							</div>	
							
						</div>
					
			</div>

		</div>
		
	</div>
	
		
</template>


