<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
//library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
//import $ from 'jquery'; 

import axios from 'axios';
//import { VideoPlayer } from 'vue-video-player'

//import datePicker from 'vue-bootstrap-datetimepicker';
//import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

//import Datepicker from 'vue3-date-time-picker';
//import 'vue3-date-time-picker/dist/main.css'
	
//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import axios from 'axios';

export default defineComponent ({
	name: 'ModEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
		//Datepicker
	},
	props: {'modId': String, 'auth':Object, 'secData':Object},
	emits: ["modId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			recordID:null,
			isNewRecord:true,
			
			
			optionsAccessLevels:[],
			optionsStates:[],
			
			//form
			mod:[]
		}
	},
	
	mounted:function(){
		
		
		
		//console.log(this.modId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
		//	console.log(this.modId);				
			if(this.modId){
				this.isNewRecord=false;
			}else{
				this.isNewRecord=true;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('recordID',this.modId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Mod/getModData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
			//	console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.mod = response.data.mod;
				
			
				self.optionsAccessLevels = response.data.optionsAccessLevels;
				self.optionsStates = response.data.optionsStates;
		
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,modId){
			this.unlockMod(modId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		
		checkSave:function(){
				
			if(!this.mod.title){this.mod.titleError = true;}else{this.mod.titleError = false;}
			if(!this.mod.url){this.mod.urlError = true;}else{this.mod.urlError = false;}
			if(!this.mod.access){this.mod.accessError = true;}else{this.mod.accessError = false;}
			if(!this.mod.state){this.mod.stateError = true;}else{this.mod.stateError = false;}
			
			if(!this.mod.titleError && !this.mod.accessError && !this.mod.stateError && !this.mod.urlError){
				return true;
			}else{
				this.createMessageBox('warning','Group','fillInAllData');
				return false;
			}
		},	
		
		saveMod:function(){
				
			if(this.checkSave()){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myMod',JSON.stringify(this.mod));
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Mod/controlModSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
					//	console.log('success2', response.data);
						if(response.data.resCode==1){
							self.createMessageBox('success','Mod',this.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')
							self.closeModalBoxSecure('myModalEditMod');
						}else if(response.data.resCode==0){
							self.createMessageBox('warning','Mod',this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalEditMod');
						}else if(response.data.resCode==2){
							self.createMessageBox('danger','Mod',this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalEditMod');
						}else if(response.data.resCode==3){
							self.createMessageBox('danger','Mod',this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalEditMod');
						}
						self.mod = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 		
				
			}
			
		},
		
		//odomknutie zaznamu
		unlockMod:function(modId){
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',modId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Mod/controlModUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
				//	console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		}
		
	},
	
	watch: {
		modId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
		<div id="myModalEditMod" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
			<div class="modal-dialog modal-lg" style="width:100%; max-width: none;">
			<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="modal-title">{{ $t('msgCore.Mod') }} <span v-if="mod.isNewRecord">{{ $tc('msgCore.new',0) }}</span><span v-else>id: <b>{{ mod.title }}</b> {{mod.id}}</span></h6>
						
						
					</div>
			
							<div v-if="accData.modulAcc && (auth.modsEdit || auth.modsNew)" class="modal-body">
								
								<div class="row">
									<div class="col-12">
										<div v-if="mod.isCheckedOut && secData.userId != mod.isCheckedOutId" class="angle">
											<span class="angle-inner" :class="{'angle-warning':mod.isCheckedOut}"><i class="fa" :class="{'angle-warning':mod.isCheckedOut, 'fa-exclamation-triangle':mod.isCheckedOut}" aria-hidden="true"></i> {{$t('msgCore.Edited')}} </span >
											
											<span v-if="mod.isCheckedOut" class="personCircle v-center" v-bind:style="{background:mod.isCheckedOutColor}" v-bind:title="mod.isCheckedOutName+', '+mod.isCheckedOutDate">{{ mod.isCheckedOutLetterName+''+mod.isCheckedOutLetterSurname }}</span>
										</div>
									</div>
								</div>
								
								<div class="row">
									
									<div class="col-md-8 col-12">
									
											<div class="form-row">
												
												<div class="form-group col-md-2 col-3">
													<label><b>{{ $t('msgCore.ID') }}</b></label>
													<input type="text" class="form-control form-control-sm" v-model="mod.id" >
													
												</div>
											
												<div class="form-group col-md-10 col-12">
													<label :class="{'text-danger':mod.titleError}"><b>{{ $t('msgCore.Title') }}</b> </label>
													<input type="text" class="form-control form-control-sm" :class="{'is-invalid':mod.titleError}" v-on:change="checkSave()" v-model="mod.title" >
													<div v-show="mod.titleError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
												</div>
												
												
												
												<div class="form-group col-md-6 col-12">
													<label :class="{'text-danger':mod.urlError}"><b>{{ $t('msgCore.url') }}</b></label>
													<input type="text" class="form-control form-control-sm" :class="{'is-invalid':mod.urlError}" v-model="mod.url">
													<div v-show="mod.urlError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
												</div>
												
												<div class="form-group col-md-4 col-8">
													<label :class="{'text-danger':mod.accessModError}"><b>{{ $t('msgCore.Access') }}</b></label>
																										
													<select v-model='mod.accessMod' class="form-control form-control-sm " :class="{'is-invalid':mod.accessModError}" v-on:change="checkSave()">
														<option  value="">{{ $t('msgCore.selectLevel') }}</option>
														<option v-for="(option) in optionsAccessLevels"  :key="option" :value="option.value">{{ option.title }}</option>
													</select>
													<div v-show="mod.accessModError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectLevel')}}</span></div>
												</div>
												
												<div class="form-group col-md-2 col-4">
													<label for="sum"><b>{{ $t('msgCore.Version') }}</b> </label><br>
													<input type="text" class="form-control form-control-sm" v-model="mod.version">
												</div>												
												
												
											</div>	
									</div>
									
									<div class="col-md-4 col-12 form-control-system-data">
									
										<div class="form-group col-12">
											<label :class="{'text-danger':mod.accessError}"><b>{{ $t('msgCore.AccessLevel') }}</b></label>
																								
											<select v-model='mod.access' class="form-control form-control-sm " :class="{'is-invalid':mod.accessError}" v-on:change="checkSave()">
												<option  value="">{{ $t('msgCore.selectLevel') }}</option>
												<option v-for="(option) in optionsAccessLevels" :key="option" :value="option.value">{{ option.title }}</option>
											</select>
											<div v-show="mod.accessError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectLevel')}}</span></div>
										</div>
																						
										
										<div class="form-group col-12">
											<label :class="{'text-danger':mod.stateError}"><b>{{ $t('msgCore.State') }}</b></label>
											
												<div  v-for="(option) in optionsStates" :key="option"  class="form-check">
												<label class="form-check-label">
													<input class="form-check-input" type="radio" name="optionsStates"  v-model='mod.state' v-on:change="checkSave()" :value="option.value">
														{{ $t('msgCore.'+option.title) }}
													<span class="circle">
													<span class="check"></span>
													</span>
												</label>
												</div>
											<div v-show="mod.stateError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectState')}}</span></div>
										</div>
									
									</div>
									
								</div>	
							</div>
							
							<div v-else class="modal-body">
								<div class="row">
									<div class="col-12">
										<div class="alert alert-warning" role="alert">
											{{$t('msgCore.accessDenied')}}
										</div>
									</div>
								</div>
							</div>
							
							
							
							<div class="modal-footer">
							
								<div class="btn-group">
									<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditMod',mod.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
									
									<button type="button" :disabled="((!auth.modsEdit) || (!auth.modsNew)) || !accData.modulAcc" v-on:click="saveMod()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
								</div>	
								
							</div>
						
				
					
					
				
				</div>

			</div>
			
		</div>
		
</template>


