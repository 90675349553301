<script>
import { defineComponent } from 'vue'
import { BarChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default defineComponent({
  name: 'dennaNavstevnost',
	data() {
		return {
			options: []
     
		}
	},
  props: {
    chartData: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      required: false
    },
  },
  components: {
    BarChart
  }
  ,mounted () {
   
  },
  
	methods: {
	
	},
	
	watch: {
    
   
	},
	
	created() {

	}
})
</script>

<template>
  <BarChart :chartData="chartData" :options="chartOptions"  />
</template>