<script>


import $ from 'jquery'; 

import UserEdit from '@/components/Core/Users/UserEdit.vue'
import UserDelete from '@/components/Core/Users/UserDelete.vue'
import UserGroups from '@/components/Core/Users/UserGroups.vue'
import UserPswd from '@/components/Core/Users/UserPswd.vue'
import UserLogins from '@/components/Core/Users/UserLogins.vue'
import UserSysLogs from '@/components/Core/Users/UserSysLogs.vue'

import MyPagination from '@/components/SystemElements/Pagination.vue'
import MyCountRecords from '@/components/SystemElements/CountRecordsOptions.vue'

import Popper from "vue3-popper";

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';

import axios from 'axios';

export default {
	name: 'Users',
	mixins: [ MixinGeneralFunct ],
	components: {
		Popper
		,UserEdit,UserDelete,UserGroups,UserPswd,UserLogins,UserSysLogs
		,MyPagination,MyCountRecords
	},
	props: ["auth","secData","params"],
	data() {
		return {
			prefix:'',
		
			
			accData:[],
			
			preloader:true,
			
			menuItems:[],
			actLangs:[],
			
			helpboxData: [],
			
			basicDataCount:0,
			basicData:[],
			selectedFilterText:'',
			selectedGroups:[],
			
			optionsGroups:[],
			
			selectedUserId:'',
			
			countPages:0,
			selectedPage:1,
			selectedRecords:10,
			selectedCol:'a.id',
			selectedSort:'desc',
			
			selectedIds:[],
			selectAllIds:false,
			
			
		
			showFilter:false,
			filterExtended:true,
			
			showedHelpbox:false
		}
	},
	
	mounted:function(){
	
	},
	
	methods: {
		
		//na fontende aj preview aj tabula, zober povodne idcka a idcka po aktualizacii a tie naviac zobraz na par sekund inou farbou, alebo ako nove.
		
		//default data
		
		
		loadDefaultValues:function(){
			
			//show loader
			this.preloader=true
			
			//var filterGroups = [];
			this.selectedGroups = [];
			for(let i = 0; i < this.optionsGroups.length; i++){
				if(this.optionsGroups[i].isSelected==true){
					this.selectedGroups.push(this.optionsGroups[i].id);
				}
			}
			
			var filterGroupsTmp = JSON.stringify(this.selectedGroups);
			
			var self = this;
			axios.get('/admins/app/core/Users/getUserList.php',{
				params:{
					filterText: this.selectedFilterText,
					filterGroups: filterGroupsTmp,
					
					//system
					selectedIds: this.selectedIds,
					orderCol: this.selectedCol,
					orderSort: this.selectedSort,
					page: this.selectedPage,
					records: this.selectedRecords
					
				}
			})
			.then(function (response) {
				console.log(response.data)
				//hide loader
				self.preloader=false
				
				//access data
				self.accData = response.data.accData;
				
				//helpbox data
				self.helpboxData = 'guidebook.guideUsers';
										
			//	if(app.accessToEmployee){
					
					
					self.countPages = response.data.countPages;
					
					self.basicDataCount = response.data.basicDataCount;
					self.basicData = response.data.basicData;
					
					//oznacenie poloziek cez strankovac
					var indexToSelect;
					var selectedIdsTEMP = [];
					selectedIdsTEMP = response.data.selectedIds; 
					self.selectedIds = [];
					for(let i = 0; i < selectedIdsTEMP.length; i++){
						self.selectedIds.push(Number(selectedIdsTEMP[i]));
					}
					for(let i = 0; i < self.basicData.length; i++){
						indexToSelect = self.selectedIds.indexOf(self.basicData[i].id);
						if(indexToSelect>=0){
							self.basicData[i].selectedId=true;
						}
					}
					self.selectAllIds = false;
					
					self.optionsGroups = response.data.optionsGroups;
					
					//kontrola oznacenych skupin po vrateni vysledku
					if(self.optionsGroups.length>0){
						for(let i = 0; i < self.optionsGroups.length; i++){
							indexToSelect = self.selectedGroups.indexOf(self.optionsGroups[i].id);
							if(indexToSelect>=0){
								self.optionsGroups[i].isSelected=true;
							}
						}
					}
					
					//app.countLimit();
					
			//	}
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		
		
		//odomknutie zaznamu
		unlockUser:function(userId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',userId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/User/controlUserUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.createMessageBox('success','User',this.$t('msgCore.dataWasUnlocked'));
						self.loadDefaultValues();
					}else if(response.data==0){
						self.createMessageBox('warning','User',this.$t('msgCore.dataCantBeUnlocked'));
						self.loadDefaultValues();
					}else if(response.data==2){
						self.createMessageBox('danger',this.$t('msgCore.dataWasNotUnlocked'));
						self.loadDefaultValues();
					}else if(response.data==3){
						self.createMessageBox('danger','User',this.$t('msgCore.accessDenied'));
						self.loadDefaultValues();
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},
		
		
		//zrusenie aktivnych prihlaseni
		unBlockUser:function(userId){
			this.cSession();	
			var bodyFormData = new FormData();
			bodyFormData.set('myUserId',userId);
			
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/User/controlUserUnBlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
				//	console.log('success2', response.data);
					if(response.data==1){
						self.createMessageBox('success','User',this.$t('msgCore.dataWasUnblocked'));
						self.loadDefaultValues();
					}else if(response.data==0){
						self.createMessageBox('warning','User',this.$t('msgCore.dataWasUnblocked'));
						self.loadDefaultValues();
					}else if(response.data==2){
						self.createMessageBox('danger','User',this.$t('msgCore.dataWasUnblocked'));
						self.loadDefaultValues();
					}else if(response.data==3){
						self.createMessageBox('danger','User',this.$t('msgCore.accessDenied'));
						self.loadDefaultValues();
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
								
			
		
		},
		
		//zrusenie pokusov o prihlasenie
		resetAttemptsUser:function(userId){
			this.cSession();	
			var bodyFormData = new FormData();
			bodyFormData.set('myUserId',userId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/User/controlUserResetAttempts.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
				//	console.log('success2', response.data);
					if(response.data==1){
						self.createMessageBox('success','User',this.$t('msgCore.dataWasUnblocked'));
						self.loadDefaultValues();
					}else if(response.data==0){
						self.createMessageBox('warning','User',this.$t('msgCore.dataWasUnblocked'));
						self.loadDefaultValues();
					}else if(response.data==2){
						self.createMessageBox('danger','User',this.$t('msgCore.dataWasUnblocked'));
						self.loadDefaultValues();
					}else if(response.data==3){
						self.createMessageBox('danger','User',this.$t('msgCore.accessDenied'));
						self.loadDefaultValues();
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
								
			
		
		},
		
		
		//systemove funkcie k zoznamu
		//(od)oznacenie vsetkych poloziek
		unSelectIds:function(){
			if(this.selectAllIds){
				for(let i = 0; i < this.basicData.length; i++){
					this.basicData[i].selectedId=true;
					this.selectedIds.push(this.basicData[i].id);
				}
				this.selectedIds = this.selectedIds.filter( this.onlyUnique );
			}else{
				for(let i = 0; i < this.basicData.length; i++){
					this.basicData[i].selectedId=false;
					var indexToRemove = this.selectedIds.indexOf(this.basicData[i].id);
					if(indexToRemove>=0){
						this.selectedIds.splice(indexToRemove, 1);
						
					}
					//this.selectedIds = [];
				}
				this.selectedIds = this.selectedIds.filter( this.onlyUnique );
			}
			//console.log(this.selectedIds);
		},
		
		//oznacenie jednej polozky
		checkSelectIds:function(index,id){
			if(this.basicData[index].selectedId==true){
				this.selectedIds.push(id);
			}else{
				var indexToRemove = this.selectedIds.indexOf(id);
				this.selectedIds.splice(indexToRemove, 1);
			}
			
			this.selectedIds = this.selectedIds.filter( this.onlyUnique );	
			//console.log(this.selectedIds);
		},
		
		//radenie zoznamu - orders by
		orderByCol:function(col){
			
			if(col === this.selectedCol) {
				this.selectedSort = this.selectedSort==='asc'?'desc':'asc';
			}
			this.selectedCol = col;
			
			this.loadDefaultValues();
		},
		
		//select page 
		selPage:function(x){
			
			if(x=='prev' || x=='next'){
				if(x=='prev'&&this.selectedPage!=1){this.selectedPage=this.selectedPage-1}
				if(x=='next'&&this.selectedPage!=this.countPages){this.selectedPage=this.selectedPage+1}
			}else{
				if(this.selectedPage != x){
					this.selectedPage = Number(x);
				}
			}
			//console.log(this.selectedPage);
			this.loadDefaultValues();
			
		},

		//active menu
		isActive: function (col) {
			return this.selectedCol === col;
		},
		isAsc: function (col) {
			if(this.selectedCol === col && this.selectedSort === 'asc'){
				return true;
			}
		},
		isDesc: function (col) {
			if(this.selectedCol === col && this.selectedSort === 'desc'){
				return true;
			}
		},
		
		
		
		//zobrazi filtre
		showFilters: function(){
			if(!this.showFilter) {
				this.showFilter = true;
			}else{
				this.showFilter = false;
			}
		},
		
		//vycisti text filter
		resetTextFilter: function(){
			this.selectedFilterText = '';
			this.loadDefaultValues();
		},
		
		// zobrazi napovedu
		showHelpbox: function(){
			if(!this.showedHelpbox) {
				this.showedHelpbox = true;
			}else{
				this.showedHelpbox = false;
			}
		},
		// systemove funkcie k zoznamu KONIEC
		
		
		//modal box user editacia
		loadModalFormUserEdit:function(recordID){
			this.cSession();
			//console.log(recordID)
			this.selectedUserId=recordID;
			$('#myModalEditUser').addClass('modal');
			$('#myModalEditUser').modal({backdrop: "static"});
		},
		
		//modal box user delete
		loadModalFormUserDelete:function(recordID){
			this.cSession();
			//console.log(recordID)
			this.selectedUserId=recordID;
			$('#myModalDeleteUser').addClass('modal');
			$('#myModalDeleteUser').modal({backdrop: "static"});
		},
		
		//modal box user select access groups
		loadModalFormUserGroupEdit:function(recordID){
			this.cSession();
			//console.log(recordID)
			this.selectedUserId=recordID;
			$('#myModalEditUserGroup').addClass('modal');
			$('#myModalEditUserGroup').modal({backdrop: "static"});
		},
		
		//modal box user change pswd
		loadModalFormUserSetPswd:function(recordID){
			this.cSession();
			//console.log(recordID)
			this.selectedUserId=recordID;
			$('#myModalEditUserPswd').addClass('modal');
			$('#myModalEditUserPswd').modal({backdrop: "static"});
		},
		
		//modal box user logins list
		loadModalLoginsList:function(recordID){
			this.cSession();
			//console.log(recordID)
			this.selectedUserId=recordID;
			$('#myModalListLogins').addClass('modal');
			$('#myModalListLogins').modal({backdrop: "static"});
		},
		
		//modal box user sys logs
		loadModalSysLogsList:function(recordID){
			this.cSession();
			//console.log(recordID)
			this.selectedUserId=recordID;
			$('#myModalListSysLogs').addClass('modal');
			$('#myModalListSysLogs').modal({backdrop: "static"});
		},
		
		reloadAfterChange() {
			//console.log(e);
			this.loadDefaultValues();
			this.selectedUserId='';
			//this.cart.push(e);
			//this.total = this.shoppingCartTotal;
		},
		
		//vyber stranky v strankovaci
		reloadAfterChangeNrPage(selectedPage) {
			this.selectedPage=selectedPage
			this.loadDefaultValues();
		},

		//vyber poctu zaznamov
		reloadAfterChangeCountRecords(selectedCount) {
			this.selectedRecords=selectedCount
			this.loadDefaultValues();
		},
		
	},	
	
			
	watch: {
		'auth.usersList': function () {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
}

</script>

<template>
	
	<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
				
		<div v-if="accData.modulAcc"> 
		
			<div class="row">
				<div class="col-12">
									
						<div class="row">
							<div class="col-6">
								<h5>{{ $t('msgCore.menu-users') }}</h5>	 
							</div> 
							
							<div class="col-6 right">
								
								<button type="button" :disabled="!auth.usersNew" v-on:click="loadModalFormUserEdit(null)" class="btn btn-primary btn-sm"><i class="smire-icon fas fa-plus"></i> {{ $t('msgCore.btnAdd') }}</button>
								
								<button type="button" :disabled="!auth.usersUpload" v-on:click="loadModalFormUserUpload()" :title="$t('msgCore.btnUpload')" class="btn btn-primary btn-sm"><i class="smire-icon fas fa-upload"></i> {{ $t('msgCore.btnUpload') }}</button>
								
							</div> 
						</div>

						<!-- filters basic -->
						<div class="row">
						
							<div class="input-group col-md-4 col-12">
								<button type="button" :title="$t('msgCore.btnFilter')" class="btn btn-info btn-sm my-btn" v-on:click="showFilters()">
									<span v-if="filterExtended">
										<i v-if="!showFilter" class="smire-icon fas fa-search-plus"></i> 
										<i v-else class="smire-icon fas fa-search-minus"></i>
									</span>
									<span v-else>
										<i class="smire-icon fas fa-search"></i> 
									</span>
									{{ $t('msgCore.btnFilter') }}
								</button>
								<input type="text" class="form-control form-control-sm" v-model='selectedFilterText' :placeholder="$t('msgCore.searchTitle')" v-on:change="loadDefaultValues()">
								<button type="button" :title="$t('msgCore.btnStorno')" class="btn btn-outline-info btn-sm my-btn" v-on:click="resetTextFilter()">
									<i class="smire-icon fas fa-times"></i>
								</button>
							</div>
							
						</div>

						<!-- filters extended -->
						<div v-show="showFilter && filterExtended" class="row">
							
							<div class="form-group-filter col-12">
								
								<div v-for="(option) in optionsGroups" :key="option" class="form-check form-check-inline">
									<label class="form-check-label">	
										<input v-model.number='option.isSelected' class="form-check-input" type="checkbox" v-on:change="loadDefaultValues()">{{ option.title }}
										<span class="form-check-sign">
											<span class="check"></span>
										</span>
									</label>
								</div>
							
							</div>

						</div>
						
				</div>
			</div>	

			
			<!-- zakladny zoznam -->
			<div v-if="basicDataCount>0">
			
					<div class="row">
						<div class="col-12">
		
							<div class="table-responsive">
								<table class="table table-hover table-sm">
								
									<thead>
										<tr>
											<th class="orderBy" v-on:click="orderByCol('a.id')" :class="{ active: isActive('a.id'), asc: isAsc('a.id'), desc: isDesc('a.id')}">id</th>
										
											<th width="10" class="align-middle">
												<div class="form-check">
													<label class="form-check-label">
													<input v-model="selectAllIds" v-on:change="unSelectIds()" class="form-check-input" type="checkbox">
													<span class="form-check-sign">
														<span class="check"></span>
													</span>
													</label>
												</div>
											</th>
										
											<th>
												{{$t('msgCore.Action')}}
											</th>
											
											<th>{{$t('msgCore.State')}}</th>
											
											<th>{{$t('msgCore.Type')}}</th>
											
											<th>{{$t('msgCore.TitleBeforeShort')}}</th>
											
											<th>{{$t('msgCore.Name')}}</th>
											<th>{{$t('msgCore.Surname')}}</th>
											
											<th>{{$t('msgCore.TitleAfterShort')}}</th>
											
											<th>{{$t('msgCore.MultiLogin')}}</th>
											
											<th>{{$t('msgCore.Validity')}}</th>
											
											<th>{{$t('msgCore.Email')}}</th>
											
											<th>{{$t('msgCore.Groups')}}</th>
											
											<th>{{$t('msgCore.Levels')}}</th>
											
											<th>{{$t('msgCore.Created')}}</th>
											
											<th>{{$t('msgCore.Modified')}}</th>
											
											<th>{{$t('msgCore.AccessLevel')}}</th>
																			
										</tr>
									</thead>
									
									
									<tr v-for="(basicObj, index) in basicData" :key="basicObj">
									
										<td>{{ basicObj.id }}</td>
										
										<td class="align-middle">
											<div class="form-check">
												<label class="form-check-label">
												<input v-model.number='basicObj.selectedId' class="form-check-input" type="checkbox" v-on:change="checkSelectIds(index,basicObj.id);">
												<span class="form-check-sign"><span class="check"></span></span>
												</label>
											</div>	
										</td>
									
										<td>
											<button v-if="basicObj.isCheckedOut" type="button" :disabled="!auth.usersUnlock" v-on:click="unlockUser(basicObj.id)" v-bind:title="basicObj.checkedOutName+', '+basicObj.isCheckedOutDate" class="btn btn-sm"><i class="smire-icon fas fa-lock"></i></button>
										
											<div class="input-group">	
																		
												<div class="btn-group">				
													<button type="button" :disabled="!auth.usersEdit" v-on:click="loadModalFormUserEdit(basicObj.id)" :title="$t('msgCore.btnEdit')" class="btn btn-secondary btn-sm"><i class="smire-icon fas fa-edit"></i></button>
													
													<button type="button" :disabled="!auth.usersDelete" v-on:click="loadModalFormUserDelete(basicObj.id)" :title="$t('msgCore.btnDelete')" class="btn btn-danger btn-sm"><i class="smire-icon fas fa-trash-alt"></i></button>
																											
												</div>	
												
												<div class="btn-group">	
												
													<button type="button" :disabled="!auth.usersChangeGroups" v-on:click="loadModalFormUserGroupEdit(basicObj.id)" :title="$t('msgCore.accessGroups')" class="btn btn-warning btn-sm"><i class="smire-icon fas fa-cog"></i></button>
											
													<button type="button" :disabled="!auth.usersChangePasswd" v-on:click="loadModalFormUserSetPswd(basicObj.id)" :title="$t('msgCore.changePassword')" class="btn btn-warning btn-sm"><i class="smire-icon fas fa-shield-alt"></i></button>
													
													<button type="button" :disabled="!auth.usersShowLoginData" v-on:click="loadModalLoginsList(basicObj.id)" :title="$t('msgCore.loginData')" class="btn btn-success btn-sm"><i class="smire-icon fas fa-clipboard-list"></i></button>
													
													<button type="button" :disabled="!auth.usersShowSysLogData" v-on:click="loadModalSysLogsList(basicObj.id)" :title="$t('msgCore.sysLogData')" class="btn btn-primary btn-sm"><i class="smire-icon fas fa-info"></i></button>
													
													<button v-if="basicObj.logins.length>0" type="button" :disabled="!auth.usersUnblock" v-on:click="unBlockUser(basicObj.id)" :title="$t('msgCore.btnUnblock')" class="btn btn-danger btn-sm"><i class="smire-icon fas fa-unlock"></i></button>
													
													<button v-if="basicObj.attempts.length>0" type="button" :disabled="!auth.usersUnblock" v-on:click="resetAttemptsUser(basicObj.id)" :title="$t('msgCore.btnResetAttempts')" class="btn btn-danger btn-sm"><i class="smire-icon fas fa-undo-alt"></i></button>
													
													
													
												</div>	
											</div>	
										</td>
										
										<td :title="basicObj.state_name">
											<i class="fas fa-circle" :class="{'text-success':basicObj.state==1,'text-danger':basicObj.state==2}" ></i>
										</td>

										<td :title="$t('msgCore.'+basicObj.type_name)">
											<i class="fas fa-circle" v-bind:style="{color:basicObj.color}" ></i>
											<i v-if="basicObj.type==1" :class="{'text-success':basicObj.type==1}" class="fas fa-sitemap"></i>
											<i v-if="basicObj.type==2" :class="{'text-danger':basicObj.type==2}" class="fas fa-external-link-square-alt"></i>
										</td>														
											
										<td >{{ basicObj.titleBefore }}</td>
										<td ><i v-if="basicObj.titleInName" class="fas fa-exclamation-triangle red"></i>{{ basicObj.name }}</td>
										<td ><i v-if="basicObj.titleInSurname" class="fas fa-exclamation-triangle red"></i>{{ basicObj.surname }}</td>
										<td >{{ basicObj.titleAfter }}</td>
										
										<td ><span v-if="basicObj.multi==0"><i class="fas fa-infinity"></i></span><span v-else>{{ basicObj.multi }} ({{basicObj.logins.length}})</span>
										<span v-if="!basicObj.isSetPassword" :title="$t('msgCore.PasswordIsNotSet')"><i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i></span>
										
										</td>	

										<td>
											<i v-if="!basicObj.isValid" class="fas fa-exclamation-triangle red" aria-hidden="true"></i>
											<i v-if="basicObj.isWarning && basicObj.isValid" class="fas fa-exclamation-triangle text-warning" ></i>
											<i v-if="!basicObj.isWarning && basicObj.isValid" class="fas fa-check text-success" ></i>
											<span v-if="basicObj.isWarning || !basicObj.isValid">{{ basicObj.warningTime }}</span>
										</td>
										
										
										<td >{{ basicObj.email }}</td>														
										
										<td >
											<span v-for="(group) in basicObj.accessGroups" :key="group">
												<span >{{ group.title }}</span><br>
											</span>
										</td>
										
										<td >
											<span v-for="(level) in basicObj.accessLevels" :key="level">
												<span >{{ level.title }}</span><br>
											</span>
										</td>
										
										<td>
											<Popper :content="basicObj.creator+', '+basicObj.createdView">
												<button class="personCircle v-center" v-bind:style="{background:basicObj.creatorColor}">{{ basicObj.creatorNameLetter+''+basicObj.creatorSurnameLetter }}</button>
											</Popper>
                                        </td>

										<td>
											<Popper v-if="basicObj.editor" :content="basicObj.editor+', '+basicObj.modifiedView">
												<button class="personCircle v-center" v-bind:style="{background:basicObj.editorColor}">{{ basicObj.editorNameLetter+''+basicObj.editorSurnameLetter }}</button>
											</Popper>
                                        </td>
                                        
										
									
										<td >{{ basicObj.access_name }}</td>
										
										
									</tr>
										

								
								</table>	
							</div>		
						
						</div>
					</div>
						
					<div class="row row-pagination">
						<div class="col-6 col-md-3 col-lg-2">
							<MyCountRecords :selectedRecords="selectedRecords" @changeAfterSelectCount="reloadAfterChangeCountRecords"></MyCountRecords>
						</div>
						
						<div class="col-6 col-md-3 col-lg-2">
							<div class="form-group form-group-filter">
								<span class="btn btn-default btn-sm">{{$t('msgCore.Count')}}: {{basicDataCount}}</span>
								<span v-if="selectedIds.length>0" class="btn btn-default btn-sm">Vybr.: {{selectedIds.length}}</span>
							</div>
						</div>
						
						<div class="col-12 col-md-6 col-lg-8">	
							<MyPagination :countPages="countPages" :selectedPage="selectedPage" @changeAfterSelectPage="reloadAfterChangeNrPage"></MyPagination>
						</div>	
					</div>	
					
				
			</div>	
			
			<div v-else class="row">
				<div class="col-12">
					<div  class="alert alert-warning" role="alert">
						{{$t('msgCore.forThisSelectExistAnyData')}}
					</div>	
				</div>							
			</div>
			
		</div>	
		
		<div v-else >
			<div class="row">
				<div class="col-12">
					<div class="alert alert-warning" role="alert">
						{{$t('msgCore.accessDenied')}}
					</div>
				</div>
			</div>
		</div>
	
		<UserEdit :auth="auth" :secData="secData" :userId="String(selectedUserId)" @changeRecord="reloadAfterChange"></UserEdit>
		
		<UserDelete :auth="auth" :secData="secData" :userId="String(selectedUserId)" @changeRecord="reloadAfterChange"></UserDelete>
		
		<UserGroups :auth="auth" :secData="secData" :userId="String(selectedUserId)" @changeRecord="reloadAfterChange"></UserGroups>
		
		<UserPswd :auth="auth" :secData="secData" :userId="String(selectedUserId)" @changeRecord="reloadAfterChange"></UserPswd>
		
		<UserLogins :auth="auth" :secData="secData" :userId="String(selectedUserId)" @changeRecord="reloadAfterChange"></UserLogins>
		
		<UserSysLogs :auth="auth" :secData="secData" :userId="String(selectedUserId)" @changeRecord="reloadAfterChange"></UserSysLogs>
</template>


