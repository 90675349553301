<script>

export default {
	name: 'MainLogo',
	
	components: {
	
	},
	
	data() {
		return {
			
		}
	},
	
	//props: {'version': Array},
	
	mounted:function(){
		
	},
	
	methods: {
			
	},	
	
	watch: {
		
	},

	computed:{
		
	},
	
}

</script>

<template>
	<div>
		<div class='container-fluid'>
			<div class='row'>
				<div class='col-8'>
					<img class='img-fluid' src='/images/logo-reinoo.svg'>
				</div>
			</div>
				
		</div>
	</div>
</template>


