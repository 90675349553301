<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
//library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";


import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
Fancybox.bind("[data-fancybox]", {
  // Your options go here
});


//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';

import axios from 'axios';

export default {
	name: 'Photos',
	mixins: [ MixinGeneralFunct ],
	components: {
	
	},
	props: {'photoIds': Array, 'auth':Object, 'secData':Object},
	emits: ["changeAfterDeleteBulk"],
	
	data() {
		return {
			prefix:'',
		
            params:[],
            accData:[],
           
            preloader:true,

            menuItems:[],
            actLangs:[],
            
            helpboxData: [],
            
            basicDataCount:0,
            basicData:[],
            selectedFilterText:'',
            
            selectedGallery:'',
            optionsGalleries:[],

            selectedPhotoId:'',
			

            countPages:0,
			optionsCountRecords:[],
			selectedPage:1,
			selectedRecords:10,
			selectedCol:'a.id',
			selectedSort:'desc',
			
			selectedPhotos:[],
			selectedPhotosAllowed:[],
			
		
			showFilter:false,
			filterExtended:true,
			
			showedHelpbox:false


		}
	},
	
	mounted:function(){
		
		this.loadDefaultValues();
	
	},
	
	methods: {
		
				
		loadDefaultValues:function(){
			
			//show loader
			this.preloader=true
			
						
			var self = this;
			axios.get('/admins/app/core/Photo/deletePhotosBulk/getSelectedData.php',{
				params:{
					photoIDs: this.photoIds,
				}
			})
			.then(function (response) {
				//console.log(response.data)
			
                //hide loader
				self.preloader=false

                //access data
				self.accData = response.data.accData;
				
				//helpbox data
				self.helpboxData = 'guidebook.guidePhotos';
				
				self.selectedPhotos = response.data.selectedData;

				//funkcia v tomto pripade nema ziaden vyznam
				self.checkSelectedPhotos();
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
				
		//kontrola vybraneho zaznamu
		//funkcia v tomto pripade nema ziaden vyznam
		checkSelectedPhotos:function(){
			
			//pole s platnymi udajmi pre zmenu datumu
			this.selectedPhotosAllowed = [];
			
			for(let i = 0; i < this.selectedPhotos.length; i++){
				this.selectedPhotosAllowed.push(this.selectedPhotos[i].id);
			}
			
			//console.log(appPhotoDeleteBulk.selectedPhotosAllowed);
		},
		
		
		deletePhotos:function(){
				
			if(this.selectedPhotosAllowed.length>0){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myPhotoIDs',this.selectedPhotosAllowed);
				
				var self = this;
				axios({
					method: 'post',
					url: '/admins/app/core/Photo/deletePhotosBulk/controlDeleteBulkPhotos.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data==1){
							self.createMessageBox('success','Photo',this.$t('msgCore.dataWasSaved'));
							self.closeModalBoxSecure('myModalDeleteBulkPhoto');
							self.$emit('changeAfterDeleteBulk', '')
						}else if(response.data==0){
							self.createMessageBox('warning','Photo',this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalDeleteBulkPhoto');
						}else if(response.data==2){
							self.createMessageBox('danger','Photo',this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalDeleteBulkPhoto');
						}
					}
				})
				.catch(error => {
						console.log(error.response)
				}); 
				
			
			}
			
		},
		
		//systemove funkcie k zoznamu
		//(od)oznacenie vsetkych poloziek
		unSelectIds:function(){
			if(this.selectAllIds){
				for(let i = 0; i < this.basicData.length; i++){
					this.basicData[i].selectedId=true;
					this.selectedIds.push(this.basicData[i].id);
				}
				this.selectedIds = this.selectedIds.filter( this.onlyUnique );
			}else{
				for(let i = 0; i < this.basicData.length; i++){
					this.basicData[i].selectedId=false;
					var indexToRemove = this.selectedIds.indexOf(this.basicData[i].id);
					if(indexToRemove>=0){
						this.selectedIds.splice(indexToRemove, 1);
						
					}
					//this.selectedIds = [];
				}
				this.selectedIds = this.selectedIds.filter( this.onlyUnique );
			}
			//console.log(this.selectedIds);
		},
		
		//oznacenie jednej polozky
		checkSelectIds:function(index,id){
			if(this.basicData[index].selectedId==true){
				this.selectedIds.push(id);
			}else{
				var indexToRemove = this.selectedIds.indexOf(id);
				this.selectedIds.splice(indexToRemove, 1);
			}
			
			this.selectedIds = this.selectedIds.filter( this.onlyUnique );	
			//console.log(this.selectedIds);
		},
		
		//radenie zoznamu - orders by
		orderByCol:function(col){
			
			if(col === this.selectedCol) {
				this.selectedSort = this.selectedSort==='asc'?'desc':'asc';
			}
			this.selectedCol = col;
			
			this.loadDefaultValues();
		},
		
		//select page 
		selPage:function(x){
			
			if(x=='prev' || x=='next'){
				if(x=='prev'&&this.selectedPage!=1){this.selectedPage=this.selectedPage-1}
				if(x=='next'&&this.selectedPage!=this.countPages){this.selectedPage=this.selectedPage+1}
			}else{
				if(this.selectedPage != x){
					this.selectedPage = Number(x);
				}
			}
			//console.log(this.selectedPage);
			this.loadDefaultValues();
			
		},

		//active menu
		isActive: function (col) {
			return this.selectedCol === col;
		},
		isAsc: function (col) {
			if(this.selectedCol === col && this.selectedSort === 'asc'){
				return true;
			}
		},
		isDesc: function (col) {
			if(this.selectedCol === col && this.selectedSort === 'desc'){
				return true;
			}
		},
		
		
		
		//zobrazi filtre
		showFilters: function(){
			if(!this.showFilter) {
				this.showFilter = true;
			}else{
				this.showFilter = false;
			}
		},
		
		//vycisti text filter
		resetTextFilter: function(){
			this.selectedFilterText = '';
			this.loadDefaultValues();
		},
		
		// zobrazi napovedu
		showHelpbox: function(){
			if(!this.showedHelpbox) {
				this.showedHelpbox = true;
			}else{
				this.showedHelpbox = false;
			}
		},
		// systemove funkcie k zoznamu KONIEC
		
	},	
	
			
	watch: {
		photoIds() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
}

</script>

<template>
	
	
				
	<div id="myModalDeleteBulkPhoto" class="fade show" role="dialog" style="z-index: 1040; display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
			<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.bulkDelete') }} </h6>
					
				</div>
							
				<div class="modal-body">
											
					<div class="row">
						<div class="col-12">
							<p>Počet vybraných záznamov: {{ selectedPhotos.length }}</p>
						</div>
					</div>
													
					<div class="table-responsive">
						<table class="table table-hover table-sm">
						
							<thead>
								<tr>
									<th>id</th>
								
									<th>{{$t('msgCore.suits')}}</th>
									
									<th>{{$t('msgCore.title')}}</th>
									
									<th>{{$t('msgCore.preview')}}</th>
								
								</tr>
							</thead>
							
							<tr v-for="(basicObj) in selectedPhotos" :key="basicObj">
							
								<td>{{ basicObj.id }}</td>
																								
								<td class="align-middle">
									<i v-if="basicObj.isAllowedError" class="fas fa-times red" aria-hidden="true"></i>
									<i v-else class="fas fa-check green"  aria-hidden="true"></i>
								</td>									
								
								<td><span>{{ basicObj.title }}</span></td>
					
								<td>
									<a class="fancybox" data-fancybox="gallery" :href="basicObj.fotoData"><img :src="basicObj.fotoData" style="height: 70px;"></a>
								</td>
							</tr>

						</table>	
					</div>		
				
				</div>
				
				<div class="modal-footer">
				
					<div class="btn-group">
						<button type="button" v-on:click="closeModalBoxSecure('myModalDeleteBulkPhoto')" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
						
						<button type="button" :disabled="!auth.photosDeleteBulk" v-on:click="deletePhotos()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnDelete') }}</button>	
					</div>	
					
				</div>
				
			</div>

		</div>
				
	</div>
		
</template>


