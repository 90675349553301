<script>

import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'



import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
Fancybox.bind("[data-fancybox]", {
  // Your options go here
});

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';

import axios from 'axios';

export default {
	name: 'ReinooPohyb',
	mixins: [ MixinGeneralFunct ],
	components: {
		Datepicker
	},
	props: ["auth","secData","params"],
	data() {
		return {
			prefix:'',
		
            
            accData:[],
           
            preloader:true,

            menuItems:[],
            actLangs:[],
            
            helpboxData: [],
            
            basicDataCount:0,
            basicData:[],
            selectedFilterText:'',
            
			selectedYear:'',
			selectedMonth:'',
			selectedDate:'',

            selectedCategoryId:'',

            countPages:0,
			selectedPage:1,
			selectedRecords:10,
			selectedCol:'a.id',
			selectedSort:'desc',
			
			selectedIds:[],
			selectAllIds:false,
						
			optionsYears:[],
			optionsMonths:[],
			optionsCompanies:[],

			filterExtended:true,
			showFilter:true,
			
			showedHelpbox:false


		}
	},
	
	mounted:function(){
		
		var d = new Date();
		d.setDate(d.getDate() - 1);
		this.selectedDate = this.formatDate(d);
	},
	
	methods: {
		
		
		loadDefaultValues:function(){
			
			//show loader
			this.preloader=true
			
			var self = this;
			axios.get('/admins/app/custom/ReinooPohyb/getBasicData.php',{
				params:{
					filterDay:this.selectedDate
					
				}
			})
			.then(function (response) {
				//console.log(response.data)
			
                //hide loader
				self.preloader=false

               //access data
				self.accData = response.data.accData;
				
				//helpbox data
				self.helpboxData = response.data.helpbox;
				
				self.basicData = response.data.basicData;
				
			
		
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
				
	
		
		//systemove funkcie k zoznamu
		
		
		//radenie zoznamu - orders by
		orderByCol:function(col){
			
			if(col === this.selectedCol) {
				this.selectedSort = this.selectedSort==='asc'?'desc':'asc';
			}
			this.selectedCol = col;
			
			this.loadDefaultValues();
		},
		
		//select page 
		selPage:function(x){
			
			if(x=='prev' || x=='next'){
				if(x=='prev'&&this.selectedPage!=1){this.selectedPage=this.selectedPage-1}
				if(x=='next'&&this.selectedPage!=this.countPages){this.selectedPage=this.selectedPage+1}
			}else{
				if(this.selectedPage != x){
					this.selectedPage = Number(x);
				}
			}
			//console.log(this.selectedPage);
			this.loadDefaultValues();
			
		},

		//active menu
		isActive: function (col) {
			return this.selectedCol === col;
		},
		isAsc: function (col) {
			if(this.selectedCol === col && this.selectedSort === 'asc'){
				return true;
			}
		},
		isDesc: function (col) {
			if(this.selectedCol === col && this.selectedSort === 'desc'){
				return true;
			}
		},
		
		
		
		//zobrazi filtre
		showFilters: function(){
			if(!this.showFilter) {
				this.showFilter = true;
			}else{
				this.showFilter = false;
			}
		},
		
		//vycisti text filter
		resetTextFilter: function(){
			this.selectedFilterText = '';
			this.loadDefaultValues();
		},
		
		// zobrazi napovedu
		showHelpbox: function(){
			if(!this.showedHelpbox) {
				this.showedHelpbox = true;
			}else{
				this.showedHelpbox = false;
			}
		},

		//vyber stranky v strankovaci
		reloadAfterChangeNrPage(selectedPage) {
			this.selectedPage=selectedPage
			this.loadDefaultValues();
		},

		//vyber poctu zaznamov
		reloadAfterChangeCountRecords(selectedCount) {
			this.selectedRecords=selectedCount
			this.loadDefaultValues();
		}

		// systemove funkcie k zoznamu KONIEC



				
	},	
	
			
	watch: {
		'auth.reinooPohybList': function () {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
}

</script>

<template>
	
	<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
	<div id="version" class="version"><p>{{accData.version}}</p></div>
				
		<div v-if="accData.modulAcc" > 
					
            <div class="row">
                <div class="col-12">
                                    
                        <div class="row">
                            <div class="col-6">
                                <h5>Prehľad rokov</h5>	 
                            </div> 
                            
                            <div class="col-6 right">
                                
                               
                            </div> 
                        </div>

                      

                        <!-- filters extended -->
						<div v-show="showFilter && filterExtended" class="row">
												
							
							
							<div class="input-group form-group-filter col-md-4 col-12">
								<Datepicker v-model="selectedDate" format="yyyy-MM-dd" :locale="$i18n.locale"  @update:modelValue="loadDefaultValues()" :cancelText="$t('msgCore.Cancel')" :selectText="$t('msgCore.Select')" ></Datepicker>
							</div>				
						</div>
                        
                    
                </div>
            </div>	

            
            <!-- zakladny zoznam -->
            <div v-if="basicData.length>0">
						
				<div class="row">
					<div class="col-12">
	
						<div class="table-responsive">
											<table class="table table-hover table-sm">
											
												<thead>
													<tr>
																									
														<th>
														hisDatumACas
														</th>
														
														<th>
														hisJmeno
														</th>
														
														<th>
														hisPrijmeni
														</th>
														
														<th>
														hisOddeleni
														</th>
														
														<th>
														hisNazevJednotky
														</th>
														
														<th>
														hisPruchod
														</th>
														
													</tr>
													
												</thead>
												
												
												<tr v-for="(basicObj) in basicData" :key="basicObj" :class="{'table-danger':basicObj.hisPruchod == 0}" >
																			
													<td  >{{ basicObj.hisDatumACas }}</td>
												
													
												
													<td  >{{ basicObj.hisJmeno }}</td>
												
													<td  >{{ basicObj.hisPrijmeni }}</td>
												
													<td  >{{ basicObj.hisOddeleni }}</td>
													
													<td  >{{ basicObj.hisNazevJednotky }}</td>
												
													<td  >{{ basicObj.hisPruchod }}</td>
												
												</tr>
													

											
											</table>	
										</div>
					
					</div>
				</div>
					
			
								
			</div>	
            
            <div v-else class="row">
                <div class="col-12">
                    <div  class="alert alert-warning" role="alert">
                        {{$t('msgCore.forThisSelectExistAnyData')}}
                    </div>	
                </div>							
            </div>
            
        </div>	
        
        <div v-else class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="alert alert-warning" role="alert">
                        {{$t('msgCore.accessDenied')}}
                    </div>
                </div>
            </div>
        </div>
	
		
</template>


