<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
//library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
//import $ from 'jquery'; 

import axios from 'axios';
//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import axios from 'axios';

export default defineComponent ({
	name: 'UserLogins',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: {'userId': String, 'auth':Object, 'secData':Object},
	emits: ["userId","changeRecord"],
	data() {
		return {
			prefix:'',
		
			accData: [],
			
			menuItems: [],
			preloader:true,
							
			basicDataCount:0,
			basicData:[],
			
			user:[],
			
			countPages:0,
			selectedPage:1,
			selectedRecords:10,
			selectedCol:'a.id',
			selectedSort:'desc',
			
			selectedIds:[], //???
			selectAllIds:false,
					
			filterExtended:false,
			showFilter:false
		}
	},
	
	mounted:function(){
		
		
		
		//console.log(this.userId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			//console.log(this.userId);				
						
			var bodyFormData = new FormData();
			bodyFormData.set('userId',this.userId);	
			bodyFormData.set('orderCol',this.selectedCol);	
			bodyFormData.set('orderSort',this.selectedSort);	
			bodyFormData.set('page',this.selectedPage);	
			bodyFormData.set('records',this.selectedRecords);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/User/getLoginsData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
				
				self.optionsCountRecords = response.data.optionsCountRecords;
				self.countPages = response.data.countPages;
				
				self.basicDataCount = response.data.basicDataCount;
				self.basicData = response.data.basicData;
			
				self.user = response.data.user;
				
				//self.countLimit();				
			
			})
				.catch(function (error) {
				console.log(error);
			
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,userId){
			this.unlockUser(userId);
			this.user=[];
			this.closeModalBoxSecure(myform);
		},
		
		
		
		//odomknutie zaznamu
		unlockUser:function(){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',this.userId);
			
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/User/controlUserUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						//self.createMessageBox('success','User','dataWasUnlocked');
						//self.loadDefaultValues();
						//self.$emit('userId', '') //tu pokracuj emit !!!!!!!!!!!!! pri zavreti modal okna
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//self.createMessageBox('warning','User','dataCantBeUnlocked');
						//self.loadDefaultValues();
					}else if(response.data==2){
						//self.createMessageBox('danger','User','dataWasNotUnlocked');
						//self.loadDefaultValues();
					}else if(response.data==3){
						//self.createMessageBox('danger','User','accessDenied');
						//self.loadDefaultValues();
					}
					
				}
			})
			.catch(error => {
				console.log(error)
			}); 
			
		},
		
		//radenie zoznamu - orders by
		orderByCol:function(col){
			
			if(col === this.selectedCol) {
				this.selectedSort = this.selectedSort==='asc'?'desc':'asc';
			}
			this.selectedCol = col;
			
			this.loadDefaultValues();
		},
		
		//select page 
		selPage:function(x){
			
			if(x=='prev' || x=='next'){
				if(x=='prev'&&this.selectedPage!=1){this.selectedPage=this.selectedPage-1}
				if(x=='next'&&this.selectedPage!=this.countPages){this.selectedPage=this.selectedPage+1}
			}else{
				if(this.selectedPage != x){
					this.selectedPage = Number(x);
				}
			}
			//console.log(this.selectedPage);
			this.loadDefaultValues();
			
		},

		//active menu
		isActive: function (col) {
			return this.selectedCol === col;
		},
		isAsc: function (col) {
			if(this.selectedCol === col && this.selectedSort === 'asc'){
				return true;
			}
		},
		isDesc: function (col) {
			if(this.selectedCol === col && this.selectedSort === 'desc'){
				return true;
			}
		}
		
	},
	
	watch: {
		userId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalListLogins" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
			<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.User') }} - {{ $t('msgCore.loginData') }} <b><span>{{ user.name }} {{ user.surname }}</span></b></h6>
				</div>
		
				<div v-if="accData.modulAcc && auth.usersShowLoginData" class="modal-body">
					
					<div class="row">
						<div class="col-12">
								
							<!-- filters basic -->
							
							<!-- filters extended -->
							
						</div>
					</div>
					
					<!-- zakladny zoznam -->
					<div v-if="basicDataCount>0">
					
						<div class="row">
							<div class="col-12">
			
								<div class="table-responsive">
									<table class="table table-hover table-sm">
									
										<thead>
											<tr>
												
												<th>{{$t('msgCore.State')}}</th>
											
												<th>{{$t('msgCore.Created')}}</th>
											
												<th>{{$t('msgCore.SignedIn')}}</th>
												
												<th>{{$t('msgCore.SignedOut')}}</th>
												
												<th>{{$t('msgCore.Modified')}}</th>
												
												<th>{{$t('msgCore.IPaddress')}}</th>
												
												<th>{{$t('msgCore.lastActivity')}}</th>
												
												<th>{{$t('msgCore.id')}}</th>
												
											</tr>
										</thead>
										
										
										<tr v-for="(basicObj) in basicData" :key="basicObj" >
																					
											<td><span :class="basicObj.state_name_color">{{ $t('msgCore.'+basicObj.state_name) }}</span></td>
											
											<td>{{ basicObj.createdView }}</td>
											
											<td>{{ basicObj.signed_inView }}</td>
											
											<td>{{ basicObj.signed_outView }}</td>
											
											<td>
												<div v-if="basicObj.editor" class="personCircle v-center" v-bind:style="{background:basicObj.editorColor}" v-bind:title="basicObj.editor+', '+basicObj.modifiedView">{{ basicObj.editorNameLetter+''+basicObj.editorSurnameLetter }}</div>
											</td>
											
											<td>{{ basicObj.ipaddress }}</td>
											
											<td>{{ basicObj.lastActivityView }}</td>
											
											<td>{{ basicObj.id }}</td>
											
										</tr>
										
									</table>	
								</div>		
							
							</div>
						</div>
							
						<div class="row row-pagination">
							<div class="col-6 col-md-3 col-lg-2">
								<div class="form-group form-group-filter">		
									<select v-model='selectedRecords' class="form-control form-control-sm" v-on:change="loadDefaultValues()">
										
										<option v-for="(option) in optionsCountRecords" :key="option" :value="option.value">{{ option.title }}</option>
									
									</select>
								</div>	
							</div>
							
							<div class="col-6 col-md-3 col-lg-2">
								<div class="form-group form-group-filter">
									<span class="btn btn-default btn-sm">Počet: {{basicDataCount}}</span>
								</div>
							</div>
							
							<div class="col-12 col-md-6 col-lg-8">	
								<div class="form-group form-group-filter">
									<nav aria-label="...">
										<ul class="pagination pagination-sm">
											
											<li class="page-item"><a  v-on:click="selPage('prev')"  class="page-link" v-bind:class="{'in-active':selectedPage==1}" href="#" >Predch.</a></li>
											<span v-for="i in countPages" :key="i">
									
												<li v-if="i==1 && i!=selectedPage" class="page-item"><a class="page-link" href="#" v-on:click="selPage(i)" >{{i}}</a></li>

											
												<li v-else-if="i>=selectedPage-1 && i!=selectedPage && i<selectedPage" class="page-item"><a class="page-link" href="#" v-on:click="selPage(i)" >{{i}}</a></li>
										
									
												<li v-else-if="i==selectedPage" class="page-item active">
													<a id="<?php echo $i; ?>"  class="page-link" href="#" v-on:click="selPage(i)" >{{i}}</a>
												</li>
										

												<li v-else-if="i<=selectedPage+1 && i!=selectedPage && i>selectedPage" class="page-item"><a class="page-link" href="#" v-on:click="selPage(i)">{{i}}</a></li>
										
												<li v-else-if="i==countPages && i!=selectedPage" class="page-item"><a class="page-link" href="#" v-on:click="selPage(i)">{{i}}</a></li>
										
								
											</span>
											
											<li class="page-item"><a v-on:click="selPage('next')"  class="page-link" v-bind:class="{'in-active':selectedPage==countPages}" href="#" >Nasl.</a></li>
											
										
										</ul>
									</nav>
								</div>
								
							</div>
							
							
						</div>	
						
						
						
					</div>	
					
					<div v-else class="row">
						<div class="col-12">
							<div  class="alert alert-warning" role="alert">
								{{$t('msgCore.forThisSelectExistAnyData')}}
							</div>	
						</div>							
					</div>

				</div>
				
				<div v-else class="modal-body">
					<div class="row">
						<div class="col-12">
							<div class="alert alert-warning" role="alert">
								{{$t('msgCore.accessDenied')}}
							</div>
						</div>
					</div>
				</div>
				
				<div class="modal-footer">
					<div class="btn-group">
						<button type="button" v-on:click="closeModalBoxSecureHelper('myModalListLogins')" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
					</div>	
				</div>
					
			</div>

		</div>
		
	</div>
		
</template>


