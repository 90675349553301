<script>


import axios from 'axios';

import Popper from "vue3-popper";
	
//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import axios from 'axios';

export default defineComponent ({
	name: 'MenuItemEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		Popper
	},
	props: {'menuItemId': String, 'auth':Object, 'secData':Object},
	emits: ["menuItemId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			recordID:null,
			isNewRecord:true,
			
			optionsMenuitems: [],
			optionsAccessLevels:[],
			optionsStates:[],
			optionsModules:[],
			
			//form
			menuitem:[]
		}
	},
	
	mounted:function(){
		
		
		
		//console.log(this.menuItemId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			//console.log(this.menuItemId);				
			if(this.menuItemId){
				this.isNewRecord=false;
			}else{
				this.isNewRecord=true;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('menuItemId',this.menuItemId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/MenuItem/getMenuItemData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.menuitem = response.data.menuitem;
				
			
				self.optionsAccessLevels = response.data.optionsAccessLevels;
				self.optionsStates = response.data.optionsStates;
				self.optionsMenuitems = response.data.optionsMenuitems;
				self.optionsModules = response.data.optionsModules;
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,menuItemId){
			this.unlockMenuItem(menuItemId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		
		checkSave:function(){
			
			if(!this.menuitem.name){this.menuitem.nameError = true;}else{this.menuitem.nameError = false;}
			if(!this.menuitem.access){this.menuitem.accessError = true;}else{this.menuitem.accessError = false;}
			if(!this.menuitem.state){this.menuitem.stateError = true;}else{this.menuitem.stateError = false;}		
			if(!this.menuitem.accessMenu){this.menuitem.accessMenuError = true;}else{this.menuitem.accessMenuError = false;}
			
			if(!this.menuitem.nameError && !this.menuitem.accessError && !this.menuitem.stateError && !this.menuitem.accessMenuError){
				return true;
			}else{
				this.createMessageBox('warning','Menuitem',this.$t('msgCore.fillInAllData'));
				return false;
			}

		},	
		
		saveMenuItem:function(){
				
			if(this.checkSave()){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myMenuItem',JSON.stringify(this.menuitem));
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/MenuItem/controlMenuItemSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data.resCode==1){
							self.createMessageBox('success','MenuItem',this.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')
							self.closeModalBoxSecure('myModalEditMenuItem');
						}else if(response.data.resCode==0){
							self.createMessageBox('warning','MenuItem',this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalEditMenuItem');
						}else if(response.data.resCode==2){
							self.createMessageBox('danger','MenuItem',this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalEditMenuItem');
						}else if(response.data.resCode==3){
							self.createMessageBox('danger','MenuItem',this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalEditMenuItem');
						}
						self.menuitem = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 		
				
			}
			
		},
		
		//odomknutie zaznamu
		unlockMenuItem:function(menuItemId){
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',menuItemId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/MenuItem/controlMenuItemUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
				//	console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		}
		
	},
	
	watch: {
		menuItemId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
		<div id="myModalEditMenuItem" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
			<div class="modal-dialog modal-lg" style="width:100%; max-width: none;">
			<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="modal-title">{{ $t('msgCore.Menuitem') }} <span v-if="menuitem.isNewRecord">{{ $tc('msgCore.new',0) }}</span><span v-else><b>{{ menuitem.name }}</b> id: {{menuitem.id}}</span></h6>
					</div>
			
							<div v-if="accData.modulAcc && (auth.menuitemsEdit || auth.menuitemsNew)" class="modal-body">
								
								<div class="row">
									<div class="col-12">
										<div v-if="menuitem.isCheckedOut && secData.userId != menuitem.isCheckedOutId" class="angle">
											<span class="angle-inner" :class="{'angle-warning':menuitem.isCheckedOut}"><i class="fa" :class="{'angle-warning':menuitem.isCheckedOut, 'fa-exclamation-triangle':menuitem.isCheckedOut}" aria-hidden="true"></i> {{$t('msgCore.Edited')}} </span >
											
											<Popper v-if="article.isCheckedOut" :content="article.isCheckedOutName+', '+article.isCheckedOutDate">
												<button class="personCircle v-center" v-bind:style="{background:article.isCheckedOutColor}">{{ article.isCheckedOutLetterName+''+article.isCheckedOutLetterSurname }}</button>
											</Popper>
										</div>
									</div>
								</div>
								
								<div class="row">
									
									<div class="col-md-8 col-12">
									
											<div class="form-row">
												
																							
												<div class="form-group  col-12">
													<label :class="{'text-danger':menuitem.nameError}"><b>{{ $t('msgCore.Title') }}</b> </label>
													<input type="text" class="form-control form-control-sm" :class="{'is-invalid':menuitem.nameError}" v-on:change="checkSave()" v-model="menuitem.name" >
													<div v-show="menuitem.nameError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
												</div>
												
												
																								
												<div class="form-group col-md-4 col-12">
													<label><b>{{ $t('msgCore.Parent') }}</b></label>
																										
													<select v-model='menuitem.level_up' class="form-control form-control-sm ">
														<option  value="">{{ $t('msgCore.selectItem') }}</option>
														<option v-for="(option) in optionsMenuitems" :value="option.value" :key="option" >{{ $t('msgCore.'+option.title) }}</option>
													</select>
													
												</div>
												
												<div class="form-group col-md-4 col-12">
													<label :class="{'text-danger':menuitem.accessMenuError}"><b>{{ $t('msgCore.Access') }}</b></label>
																										
													<select v-model='menuitem.accessMenu' class="form-control form-control-sm " :class="{'is-invalid':menuitem.accessMenuError}" v-on:change="checkSave()">
														<option  value="">{{ $t('msgCore.selectLevel') }}</option>
														<option v-for="(option) in optionsAccessLevels" :value="option.value" :key="option" >{{ option.title }}</option>
													</select>
													<div v-show="menuitem.accessMenuError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectLevel')}}</span></div>
												</div>
												
												<div class="form-group col-md-4 col-12">
													<label><b>{{ $t('msgCore.Mod') }}</b></label>
																										
													<select v-model='menuitem.module' class="form-control form-control-sm " :class="{'is-invalid':menuitem.menuitemuleError}" v-on:change="checkSave()">
														<option  value="">{{ $t('msgCore.selectModule') }}</option>
														<option v-for="(option) in optionsModules" :value="option.value" :key="option" >{{ option.title }}</option>
													</select>
													<div v-show="menuitem.moduleError" class="error-input">Vyberte modul</div>
												</div>
												
											</div>	
									</div>	
									
									
									<div class="col-md-4 col-12 form-control-system-data">
									
										<div class="form-group col-12">
											<label :class="{'text-danger':menuitem.accessError}"><b>{{ $t('msgCore.AccessLevel') }}</b></label>
																								
											<select v-model='menuitem.access' class="form-control form-control-sm " :class="{'is-invalid':menuitem.accessError}" v-on:change="checkSave()">
												<option  value="">{{ $t('msgCore.selectLevel') }}</option>
												<option v-for="(option) in optionsAccessLevels" :value="option.value" :key="option" >{{ option.title }}</option>
											</select>
											<div v-show="menuitem.accessError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectLevel')}}</span></div>
										</div>
										
										<div class="form-group col-12">
											<label :class="{'text-danger':menuitem.stateError}"><b>{{ $t('msgCore.State') }}</b></label>
											
											<div  v-for="(option) in optionsStates" :key="option" class="form-check">
												<label class="form-check-label">
													<input class="form-check-input" type="radio" name="optionsStates"  v-model='menuitem.state' v-on:change="checkSave()" :value="option.value">
													{{ $t('msgCore.'+option.title) }}
													<span class="circle">
														<span class="check"></span>
													</span>
												</label>
											</div>
											<div v-show="menuitem.stateError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectState')}}</span></div>
										</div>
									
									</div>	
								</div>	
							</div>
							
							<div v-else class="modal-body">
								<div class="row">
									<div class="col-12">
										<div class="alert alert-warning" role="alert">
											{{$t('msgCore.accessDenied')}}
										</div>
									</div>
								</div>
							</div>
							
							
							<div class="modal-footer">
							
								<div class="btn-group">
									<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditMenuItem',menuitem.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
									
									<button type="button" :disabled="((!auth.menuitemsEdit) || (!auth.menuitemsNew)) || !accData.modulAcc" v-on:click="saveMenuItem()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
								</div>	
								
							</div>
						
				
					
					
				
				</div>

			</div>
			
		</div>
		
</template>


