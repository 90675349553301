<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
//library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
//import $ from 'jquery'; 

import axios from 'axios';
//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import axios from 'axios';

export default defineComponent ({
	name: 'MutationDelete',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: {'mutationId': String, 'auth':Object, 'secData':Object},
	emits: ["mutationId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			//form
			mutation:[]
		}
	},
	
	mounted:function(){
		
		//console.log(this.mutationId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
	
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			//console.log(this.mutationId);				
			if(this.mutationId){
				this.isNewRecord=false;
			}else{
				this.isNewRecord=true;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('mutationId',this.mutationId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Mutation/getMutationData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.mutation = response.data.mutation;
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,mutationId){
			this.unlockMutation(mutationId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		//odomknutie zaznamu
		unlockMutation:function(mutationId){
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',mutationId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Mutation/controlMutationUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},
		
		deleteMutation:function(mutationId){
			
			if(mutationId){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myMutation',JSON.stringify(this.mutation));
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Mutation/controlMutationDelete.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data==1){
							self.createMessageBox('success','Mutation',this.$t('msgCore.dataWasDeleted'));
							self.$emit('changeRecord', '')
							self.closeModalBoxSecure('myModalDeleteMutation');
						}else if(response.data==0){
							self.createMessageBox('warning','Mutation',this.$t('msgCore.dataCantBeDeleted'));
							self.closeModalBoxSecure('myModalDeleteMutation');
						}else if(response.data==2){
							self.createMessageBox('danger','Mutation',this.$t('msgCore.dataWasNotDeleted'));
							self.closeModalBoxSecure('myModalDeleteMutation');
						}else if(response.data==3){
							self.createMessageBox('danger','Mutation',this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalDeleteMutation');
						}
						self.group = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
			}
			
		}
	
		
	},
	
	watch: {
		mutationId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	
		<div id="myModalDeleteMutation" class="fade show " role="dialog" style="z-index: 1040;  display: none;">
			<div class="modal-dialog modal-lg" style="width:100%; max-width: none;">
				<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="modal-title">{{ $t('msgCore.DELETE_RECORD') }}</h6>
					</div>
			
						<div v-if="accData.modulAcc && (auth.mutationsDelete)" class="modal-body">
									
									
							<div class="form-row">
							
								
								<div class="form-group col-md-2 col-12">
																																			
									<label><b>{{ $t('msgCore.ID') }}</b></label><br>
									{{mutation.id}}
								</div>
								
								<div class="form-group col-md-10 col-12">
																							
									<label><b>{{ $t('msgCore.Title') }}</b></label><br>
									{{ mutation.title }}
								</div>
								
								<div class="form-group col-12">
																																			
									<label><b>{{ $t('msgCore.short') }}</b></label><br>
									{{ mutation.shorts }}
								</div>
								
																				
								<div class="form-group col-md-6 col-12">
									<label for="sum"><b>{{ $t('msgCore.AccessLevel') }}</b> </label><br>
									{{ mutation.access_name }}
								</div>
								
								
								
							</div>	
									
								
						</div>
						
						<div v-else class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="alert alert-warning" role="alert">
										{{$t('msgCore.accessDenied')}}
									</div>
								</div>
							</div>
						</div>
						
						
						
						<div class="modal-footer">
						
							<div class="btn-group">
								<button type="button" v-on:click="closeModalBoxSecureHelper('myModalDeleteMutation',mutation.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>

								<button type="button" :disabled="(!auth.mutationsDelete) || !accData.modulAcc" v-on:click="deleteMutation(mutation.id)" class="btn btn-danger btn-sm">{{ $t('msgCore.btnDelete') }}</button>
							</div>	
							
						</div>
						
				</div>

			</div>
			
		</div>
	
		
</template>


