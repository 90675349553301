<script>

import mesacnaNavstevnostBar  from '@/components/CustomReinoo/ReinooMonths/mesacnaNavstevnostBar.vue'

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
Fancybox.bind("[data-fancybox]", {
  // Your options go here
});

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';

import axios from 'axios';

export default {
	name: 'ReinooMonths',
	mixins: [ MixinGeneralFunct ],
	components: {
		mesacnaNavstevnostBar
	},
	props: ["auth","secData","params"],
	data() {
		return {
			prefix:'',
		
            
            accData:[],
           
            preloader:true,

            menuItems:[],
            actLangs:[],
            
            helpboxData: [],
            
            basicDataCount:0,
            basicData:[],
            selectedFilterText:'',
            
			selectedYear:'',
			selectedMonth:'',
			selectedCompany:'',

            selectedCategoryId:'',

            countPages:0,
			selectedPage:1,
			selectedRecords:10,
			selectedCol:'a.id',
			selectedSort:'desc',
			
			selectedIds:[],
			selectAllIds:false,
						
			optionsYears:[],
			optionsMonths:[],
			optionsCompanies:[],

			filterExtended:true,
			showFilter:true,

			graphOptionsNavstevnost:[],
			graphOptionsPriemerVstupovDen:[],
			graphOptionsPocetOsob:[],
			graphOptionsPriemerPracDni:[],

			grafNavstevnostData:[],
			grafPriemerDenData:[],
			grafPocetOsobData:[],
			grafPriemer:[],
			
			showedHelpbox:false


		}
	},
	
	mounted:function(){
		
		var d = new Date();
		var dy = d.getFullYear();
		this.selectedYear=dy;

		this.graphOptionsNavstevnost= {
			plugins: {
				title: {
					display: true,
					text: this.$t("msgCustomReinoo.mesacnaNavstevnost"),
					padding: {
						top: 10,
						bottom: 30
					}
				},
				legend: {
					display: true,
					
					position: 'top',
				},
				tooltip: {
					enabled: true,
					position: 'nearest'
				},
				
			},

			responsive: true,
			maintainAspectRatio: false,
			
			layout: {
				padding: {
				left: 0,
				right: 0,
				top: 20,
				bottom: 0
				}
			},
			
			scales: {
				x: {
					title: {
					display: true,
					text: 'mesiac'
					},
					barPercentage: 0.4
				},
				y: {
					display: true,
					position: 'left',
					title: {
						display: true,
						text:  'vstupov/mesiac'
					}
				}
			}
		}

		this.graphOptionsPriemerVstupovDen= {
			plugins: {
				title: {
					display: true,
					text: this.$t("msgCustomReinoo.priemerVstupovDen"),
					padding: {
						top: 10,
						bottom: 30
					}
				},
				legend: {
					display: true,
					
					position: 'top',
				},
				tooltip: {
					enabled: true,
					position: 'nearest'
				},
				
			},

			responsive: true,
			maintainAspectRatio: false,
			
			layout: {
				padding: {
				left: 0,
				right: 0,
				top: 20,
				bottom: 0
				}
			},
			
			scales: {
				x: {
					title: {
					display: true,
					text: 'mesiac'
					},
					barPercentage: 0.4
				},
				y: {
					display: true,
					position: 'left',
					title: {
						display: true,
						text:  'vstupov/deň'
					}
				}
			}
		}
		
		this.graphOptionsPocetOsob= {
			plugins: {
				title: {
					display: true,
					text: this.$t("msgCustomReinoo.pocetOsob"),
					padding: {
						top: 10,
						bottom: 30
					}
				},
				legend: {
					display: false,
					title: {
						display: false,
						text: 'Legend Title',
					},
					position: 'top',
				},
				tooltip: {
					enabled: true,
					position: 'nearest'
				},
				
			},
			aspectRatio: 1,
			responsive: true,
			maintainAspectRatio: false,
			
			layout: {
				padding: {
				left: 0,
				right: 0,
				top: 20,
				bottom: 0
				}
			},
			
			scales: {
				x: {
					title: {
					display: true,
					text: 'mesiac'
					},
					barPercentage: 0.4
				},
				y: {
					display: true,
					position: 'left',
					title: {
						display: true,
						text:  'osôb/mesiac'
					}
				}
			}
		}

		this.graphOptionsPriemerPracDni= {
			plugins: {
				title: {
					display: true,
					text: this.$t("msgCustomReinoo.priemerPracDni"),
					padding: {
						top: 10,
						bottom: 30
					}
				},
				legend: {
					display: true,
					
					position: 'top',
				},
				tooltip: {
					enabled: true,
					position: 'nearest'
				},
				
			},

			responsive: true,
			maintainAspectRatio: false,
			
			layout: {
				padding: {
				left: 0,
				right: 0,
				top: 20,
				bottom: 0
				}
			},
			
			scales: {
				x: {
					title: {
					display: true,
					text: 'mesiac'
					
					},
					barPercentage: 0.4
				},
				y: {
					display: true,
					position: 'left',
					title: {
						display: true,
						text:  'osôb/deň'
					}
				}
			}
		}
	},
	
	methods: {
		
		
		loadDefaultValues:function(){
			
			//show loader
			this.preloader=true
			
			var self = this;
			axios.get('/admins/app/custom/ReinooMonths/getMonthsData.php',{
				params:{
					selectedYear:this.selectedYear
					,selectedCompany:this.selectedCompany
					
				}
			})
			.then(function (response) {
				//console.log(response.data)
			
                //hide loader
				self.preloader=false

               //access data
				self.accData = response.data.accData;
				
				//helpbox data
				self.helpboxData = response.data.helpbox;
				
				self.basicData = response.data.basicData;
				self.basicDataCount=self.basicData.length;				
				self.optionsYears = response.data.optionsYears;
				self.optionsCompanies = response.data.optionsCompanies;
				self.labels = response.data.labels;
			
			
				self.grafPriemer = response.data.grafPriemer;
				
				
				
				self.grafPocetOsobData = response.data.grafPocetOsobData;
				self.grafPriemerDenData = response.data.grafPriemerDenData;
				
				self.grafNavstevnostCelkovaData = response.data.grafNavstevnostCelkovaData;
				self.grafNavstevnostData = response.data.grafNavstevnostData;
				
				self.labels = response.data.labels;
			
				
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
				
	
		
		//systemove funkcie k zoznamu
		
		
		//radenie zoznamu - orders by
		orderByCol:function(col){
			
			if(col === this.selectedCol) {
				this.selectedSort = this.selectedSort==='asc'?'desc':'asc';
			}
			this.selectedCol = col;
			
			this.loadDefaultValues();
		},
		
		//select page 
		selPage:function(x){
			
			if(x=='prev' || x=='next'){
				if(x=='prev'&&this.selectedPage!=1){this.selectedPage=this.selectedPage-1}
				if(x=='next'&&this.selectedPage!=this.countPages){this.selectedPage=this.selectedPage+1}
			}else{
				if(this.selectedPage != x){
					this.selectedPage = Number(x);
				}
			}
			//console.log(this.selectedPage);
			this.loadDefaultValues();
			
		},

		//active menu
		isActive: function (col) {
			return this.selectedCol === col;
		},
		isAsc: function (col) {
			if(this.selectedCol === col && this.selectedSort === 'asc'){
				return true;
			}
		},
		isDesc: function (col) {
			if(this.selectedCol === col && this.selectedSort === 'desc'){
				return true;
			}
		},
		
		
		
		//zobrazi filtre
		showFilters: function(){
			if(!this.showFilter) {
				this.showFilter = true;
			}else{
				this.showFilter = false;
			}
		},
		
		//vycisti text filter
		resetTextFilter: function(){
			this.selectedFilterText = '';
			this.loadDefaultValues();
		},
		
		// zobrazi napovedu
		showHelpbox: function(){
			if(!this.showedHelpbox) {
				this.showedHelpbox = true;
			}else{
				this.showedHelpbox = false;
			}
		},

		//vyber stranky v strankovaci
		reloadAfterChangeNrPage(selectedPage) {
			this.selectedPage=selectedPage
			this.loadDefaultValues();
		},

		//vyber poctu zaznamov
		reloadAfterChangeCountRecords(selectedCount) {
			this.selectedRecords=selectedCount
			this.loadDefaultValues();
		}

		// systemove funkcie k zoznamu KONIEC



				
	},	
	
			
	watch: {
		'auth.reinooPohybList': function () {
			this.loadDefaultValues();
		},

		'$i18n.locale'(){
								
			this.graphOptionsNavstevnost= {
				plugins: {
					title: {
						display: true,
						text: this.$t("msgCustomReinoo.mesacnaNavstevnost"),
						padding: {
							top: 10,
							bottom: 30
						}
					},
					legend: {
						display: true,
						
						position: 'top',
					},
					tooltip: {
						enabled: true,
						position: 'nearest'
					},
					
				},

				responsive: true,
				maintainAspectRatio: false,
				
				layout: {
					padding: {
					left: 0,
					right: 0,
					top: 20,
					bottom: 0
					}
				},
				
				scales: {
					x: {
						title: {
						display: true,
						text: 'mesiac'
						}
					},
					y: {
						display: true,
						position: 'left',
						title: {
							display: true,
							text:  'vstupov/mesiac'
						}
					}
				}
			}

			this.graphOptionsPriemerVstupovDen= {
				plugins: {
					title: {
						display: true,
						text: this.$t("msgCustomReinoo.priemerVstupovDen"),
						padding: {
							top: 10,
							bottom: 30
						}
					},
					legend: {
						display: true,
					
						position: 'top',
					},
					tooltip: {
						enabled: true,
						position: 'nearest'
					},
					
				},

				responsive: true,
				maintainAspectRatio: false,
				
				layout: {
					padding: {
					left: 0,
					right: 0,
					top: 20,
					bottom: 0
					}
				},
				
				scales: {
					x: {
						title: {
						display: true,
						text: 'mesiac'
						}
					},
					y: {
						display: true,
						position: 'left',
						title: {
							display: true,
							text:  'vstupov/deň'
						}
					}
				}
			}
			
			this.graphOptionsPocetOsob= {
				plugins: {
					title: {
						display: true,
						text: this.$t("msgCustomReinoo.pocetOsob"),
						padding: {
							top: 10,
							bottom: 30
						}
					},
					legend: {
						display: false,
						title: {
							display: false,
							text: 'Legend Title',
						},
						position: 'top',
					},
					tooltip: {
						enabled: true,
						position: 'nearest'
					},
					
				},

				responsive: true,
				maintainAspectRatio: false,
				
				layout: {
					padding: {
					left: 0,
					right: 0,
					top: 20,
					bottom: 0
					}
				},
				
				scales: {
					x: {
						title: {
						display: true,
						text: 'mesiac'
						}
					},
					y: {
						display: true,
						position: 'left',
						title: {
							display: true,
							text:  'osôb/mesiac'
						}
					}
				}
			}

			this.graphOptionsPriemerPracDni= {
				plugins: {
					title: {
						display: true,
						text: this.$t("msgCustomReinoo.priemerPracDni"),
						padding: {
							top: 10,
							bottom: 30
						}
					},
					legend: {
						display: true,
					
						position: 'top',
					},
					tooltip: {
						enabled: true,
						position: 'nearest'
					},
					
				},

				responsive: true,
				maintainAspectRatio: false,
				
				layout: {
					padding: {
					left: 0,
					right: 0,
					top: 20,
					bottom: 0
					}
				},
				
				scales: {
					x: {
						title: {
						display: true,
						text: 'mesiac'
						}
					},
					y: {
						display: true,
						position: 'left',
						title: {
							display: true,
							text:  'osôb/deň'
						}
					}
				}
			}
		}	
	},

	computed:{
		myGraphStyles () {
			return {
				height: `${300}px`,
				position: 'relative'
			}
		}
	},
	
}

</script>

<template>
	
	<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
	<div id="version" class="version"><p>{{accData.version}}</p></div>
				
		<div v-if="accData.modulAcc" > 
					
            <div class="row">
                <div class="col-12">
                                    
                        <div class="row">
                            <div class="col-6">
                                <h5>Prehľad podľa mesiacov za vybraný rok</h5>	 
                            </div> 
                            
                            <div class="col-6 right">
                                
                               
                            </div> 
                        </div>

                      

                        <!-- filters extended -->
						<div v-show="showFilter && filterExtended" class="row">
							<div class="input-group form-group-filter col-md-4 col-12">
								<select v-model='selectedYear' class="form-control form-control-sm" :class="{red:selectedYear}" v-on:change="loadDefaultValues()">
									<option value="">{{ $t('msgCustomReinoo.selectYear') }}</option>
									<option v-for="(option) in optionsYears" :value="option.value" :key="option" >{{ option.title }}</option>
								</select>
							</div>
							
							
							
							<div class="input-group form-group-filter col-md-4 col-12">
								<select v-model='selectedCompany' class="form-control form-control-sm" :class="{red:selectedCompany}" v-on:change="loadDefaultValues()">
									<option value="">{{ $t('msgCustomReinoo.selectCompany') }}</option>
									<option v-for="(option) in optionsCompanies" :value="option.value" :key="option">{{ option.title }}</option>
								</select>
							</div>				
						</div>
                        
                    
                </div>
            </div>	

            
            <!-- zakladny zoznam -->
            <div v-if="basicDataCount>0">
						
				<div class="row">
					<div class="col-12">
	
						<div class="table-responsive">
							<table class="table table-hover table-sm">
							
								<thead>
									<tr>
																						
										<th rowspan="2">
											Mesiac
										</th>
										
										<th rowspan="2">Počet osôb</th>
										
										<th colspan="3">Mesačná návštevnosť</th>
										
										
										<th colspan="2">Priemer vstupov na deň</th>
										
										
										<th colspan="2">Počet dní</th>
										
									</tr>
									
									<tr>
										<th>Celkom</th>
										<th>Prac.</th>
										<th>Neprac.</th>
										
										<th>Prac.</th>
										<th>Neprac.</th>
										
										<th>Prac.</th>
										<th>Neprac.</th>
									</tr>
								</thead>
								
								
								<tr v-for="(basicObj) in basicData" :key="basicObj" >
					
									<td >{{ basicObj.mesiac }}</td>
									<td >{{ basicObj.pocetOsob }}</td>
									
									<td >{{ basicObj.navstevnostCelkom }}</td>
									<td >{{ basicObj.navstevnostPrac }}</td>
									<td >{{ basicObj.navstevnostNeprac }}</td>
									
									<!--<td >{{ basicObj.avgUniqueDay }}</td>-->
									<td >{{ basicObj.dennyPriemerPrac }}</td>
									<td >{{ basicObj.dennyPriemerNerac }}</td>
									
									<!--<td >{{ basicObj.numDays }}</td>-->
									<td >{{ basicObj.pocetDniPrac }}</td>
									<td >{{ basicObj.pocetDniNeprac }}</td>
								</tr>
									

							
							</table>	
						</div>		
					
					</div>
				</div>

				<div class="row">
					<div class="col-12">
						<mesacnaNavstevnostBar v-bind:chartData=grafNavstevnostData v-bind:chartOptions=graphOptionsNavstevnost :styles="myGraphStyles"/>
					</div> 
				</div> 

				<div class="row">
					<div class="col-12">
						<mesacnaNavstevnostBar v-bind:chartData=grafPriemerDenData v-bind:chartOptions=graphOptionsPriemerVstupovDen :styles="myGraphStyles"/>
					</div> 
				</div> 

				<div class="row">
					<div class="col-12">
						<mesacnaNavstevnostBar v-bind:chartData=grafPocetOsobData v-bind:chartOptions=graphOptionsPocetOsob :styles="myGraphStyles"/>
					</div> 
				</div> 

				<div class="row">
					<div class="col-12">
						<mesacnaNavstevnostBar v-bind:chartData=grafPriemer v-bind:chartOptions=graphOptionsPriemerPracDni :styles="myGraphStyles"/>
					</div> 
				</div> 
				
					
			
								
			</div>	
            
            <div v-else class="row">
                <div class="col-12">
                    <div  class="alert alert-warning" role="alert">
                        {{$t('msgCore.forThisSelectExistAnyData')}}
                    </div>	
                </div>							
            </div>
            
        </div>	
        
        <div v-else class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="alert alert-warning" role="alert">
                        {{$t('msgCore.accessDenied')}}
                    </div>
                </div>
            </div>
        </div>
	
		
</template>


