<script>


import axios from 'axios';
//import { VideoPlayer } from 'vue-video-player'

//import datePicker from 'vue-bootstrap-datetimepicker';
//import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'
	
//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import axios from 'axios';

export default defineComponent ({
	name: 'UserEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
		Datepicker
	},
	props: {'userId': String, 'auth':Object, 'secData':Object},
	emits: ["userId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			recordID:null,
			isNewRecord:true,
			
			/*date: new Date(),
			options: {
				format: 'DD/MM/YYYY',
				useCurrent: false,
			},*/
			
			valid_upOptions: {
				//locale: 'sk',
				format: 'YYYY-MM-DD HH:mm',
				//useCurrent: true,
				//showClear: true,
				//showClose: true,
			},
			
			valid_downOptions: {
				locale: 'sk',
				format: 'YYYY-MM-DD HH:mm',
				useCurrent: true,
				showClear: true,
				showClose: true,
			},
			
			optionsAccessLevels: [],
			optionsActivationStates:[],
			optionsTypes:[],
			optionsStates:[],
			optionsMultiLogin:[],
			
			optionsCountries:[],
			optionsRegions:[],
			optionsDistricts:[],
			optionsCities:[],
						
			//form
			user:[],
			
			sameRecords:[]
		}
	},
	
	mounted:function(){
		
		
		
		//console.log(this.userId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			//console.log(this.userId);				
			if(this.userId){
				this.isNewRecord=false;
			}else{
				this.isNewRecord=true;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('recordID',this.userId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/User/getUserData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.user = response.data.user;
			//	console.log(appCard.card);
				self.optionsAccessLevels = response.data.optionsAccessLevels;
				self.optionsStates = response.data.optionsStates;
				self.optionsActivationStates = response.data.optionsActivationStates;
				self.optionsMultiLogin = response.data.optionsMultiLogin;
				self.optionsTypes = response.data.optionsTypes;
				
				self.optionsCountries = response.data.optionsCountries;
				self.optionsRegions = response.data.optionsRegions;
				self.optionsDistricts = response.data.optionsDistricts;
				self.optionsCities = response.data.optionsCities;
				
				self.sameRecords = '';
			
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,userId){
			this.unlockUser(userId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		// check duplicity person
		checkDuplicityUser:function(){
			var self=this;
			axios.get('/admins/app/core/Users/getDuplicityUsers.php',{
				params:{
					email: this.user.email
				}
			})
			.then(function (response) {
				//console.log('same', response.data)
				//access data
				self.accData = response.data.accData;
				
				//form data
				self.sameRecords = response.data.sameRecords;
				
			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		checkSave:function(){
			//console.log(this.user.surname);
			var emailPattern = /\S+@\S+\.\S+/;
			
			if(!this.user.surname){this.user.surnameError = true;}else{this.user.surnameError = false;}
			if(this.user.email){
				if(!this.user.email.match(emailPattern)){this.user.emailError = true;}else{this.user.emailError = false;}
			}else{
				{this.user.emailError = true;}
			}
			if(!this.user.active){this.user.activeError = true;}else{this.user.activeError = false;}
			if(!this.user.type){this.user.typeError = true;}else{this.user.typeError = false;}
			if(!this.user.state){this.user.stateError = true;}else{this.user.stateError = false;}
			if(!this.user.color){this.user.colorError = true;}else{this.user.colorError = false;}
			if(!this.user.valid_up){this.user.valid_upError = true;}else{this.user.valid_upError = false;}
			//if(!this.user.valid_down){this.user.valid_downError = true;}else{this.user.valid_downError = false;}
			if(this.user.valid_down && this.user.valid_down <= this.user.valid_up){this.user.valid_downError = true;}else{this.user.valid_downError = false;}
			if(!this.user.access){this.user.accessError = true;}else{this.user.accessError = false;}
			if(!this.user.multi){this.user.multiError = true;}else{this.user.multiError = false;}
			
			if(!this.user.surnameError && !this.user.emailError && !this.user.activeError && !this.user.typeError && !this.user.stateError && !this.user.colorError && !this.user.valid_upError && !this.user.valid_downError && !this.user.accessError && !this.user.multiError){
				return true;
			}else{
				this.createMessageBox('warning','User','fillInAllData');
				return false;
			}
		},	
		
		
		//odomknutie zaznamu
		unlockUser:function(userId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',userId);
			
			
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/User/controlUserUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						//self.createMessageBox('success','User','dataWasUnlocked');
						//self.loadDefaultValues();
						//self.$emit('userId', '') //tu pokracuj emit !!!!!!!!!!!!! pri zavreti modal okna
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//self.createMessageBox('warning','User','dataCantBeUnlocked');
						//self.loadDefaultValues();
					}else if(response.data==2){
						//self.createMessageBox('danger','User','dataWasNotUnlocked');
						//self.loadDefaultValues();
					}else if(response.data==3){
						//self.createMessageBox('danger','User','accessDenied');
						//self.loadDefaultValues();
					}
					
				}
			})
			.catch(error => {
				console.log(error)
			}); 
			//self.$emit('update:userId', '')
		},
		
		saveUser:function(){
			
			if(this.checkSave()){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myUser',JSON.stringify(this.user));
								
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/User/controlUserSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('save', response.data);
						if(response.data==1){
							self.createMessageBox('success','User',this.$t('msgCore.dataWasSaved'));
							
							self.$emit('changeRecord', '')
							self.closeModalBoxSecure('myModalEditUser');
						}else if(response.data==0){
							self.createMessageBox('warning','User',this.$t('msgCore.dataCantBeSaved'));
							
							self.closeModalBoxSecure('myModalEditUser');
						}else if(response.data==2){
							self.createMessageBox('danger','User',this.$t('msgCore.dataWasNotSaved'));
							
							self.closeModalBoxSecure('myModalEditUser');
						}else if(response.data==3){
							self.createMessageBox('danger','User',this.$t('msgCore.accessDenied'));
							
							self.closeModalBoxSecure('myModalEditUser');
						}
						self.user = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
				
				
			}
			
		}
	},
	
	watch: {
		userId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalEditUser" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		
			<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
				<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="modal-title">{{ $t('msgCore.User') }} <span v-if="!user.isNewRecord"><b>{{ user.name }} {{ user.surname }}</b> id: {{user.id}}</span></h6>
					</div>
			
							<div v-if="accData.modulAcc && (auth.usersNew || auth.usersEdit)" class="modal-body">
								
								<div class="row">
									<div class="col-12">
										<div v-if="user.isCheckedOut && secData.userId != user.isCheckedOutId" class="angle">
											<span class="angle-inner" :class="{'angle-warning':user.isCheckedOut}"><i class="fa" :class="{'angle-warning':user.isCheckedOut, 'fa-exclamation-triangle':user.isCheckedOut}" aria-hidden="true"></i> {{$t('msgCore.Edited')}} </span >
											
											<span v-if="user.isCheckedOut" class="personCircle v-center" v-bind:style="{background:user.isCheckedOutColor}" v-bind:title="user.isCheckedOutName+', '+user.isCheckedOutDate">{{ user.isCheckedOutLetterName+''+user.isCheckedOutLetterSurname }}</span>
										</div>
									</div>
								</div>
								
								<div class="row">
									<div class="col-12">
									
									<ul class="nav nav-tabs" role="tablist">
					
										<li class="nav-item">
											<a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">{{ $t('msgCore.basicData') }}</a>
										</li>
										
										<li v-if="params.showUsersExtendedData" class="nav-item">
											<a class="nav-link" id="menu3-tab" data-toggle="tab" href="#menu3" role="tab" aria-controls="menu3" aria-selected="false">{{ $t('msgCore.extendedData') }}</a>
										</li>
										
										
										
									</ul>
							
									<div class="tab-content" id="myTabContent">
							
										<div id="home" class="tab-pane fade show active" role="tabpanel">
										
											<div class="row">
												<div class="col-md-8 col-12">
														
													<div class="form-row">
														<div class="form-group col-md-2 col-12">
															<label><b>{{ $t('msgCore.TitleBefore') }}</b> </label>
															<input type="text" class="form-control form-control-sm" v-model="user.titleBefore" >
														</div>
														
														<div class="form-group col-md-3 col-12">
															<label><b>{{ $t('msgCore.Name') }}</b> </label>
															<input type="text" class="form-control form-control-sm" v-model="user.name" >
														</div>
														
														<div class="form-group col-md-3 col-12">
															<label :class="{'text-danger':user.surnameError}"><b>{{ $t('msgCore.Surname') }}</b> </label>
															<input type="text" class="form-control form-control-sm" :class="{'is-invalid':user.surnameError}" v-on:change="checkSave()" v-model="user.surname" >
															<div v-show="user.surnameError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
														</div>
														
														<div class="form-group col-md-2 col-12">
															<label><b>{{ $t('msgCore.TitleAfter') }}</b> </label>
															<input type="text" class="form-control form-control-sm" v-model="user.titleAfter" >
														</div>
														
													</div>													
														
														
														
													<div class="form-row">	
														<div class="form-group col-md-4 col-12">
															<label :class="{'text-danger':user.emailError}"><b>{{ $t('msgCore.Email') }}</b> </label>
															<input type="text" class="form-control form-control-sm" :class="{'is-invalid':user.emailError}" v-on:change="checkSave();checkDuplicityUser();" v-model="user.email" >
															<div v-show="user.emailError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
														</div>
														
														<div class="form-group col-md-4 col-12">
															<label><b>{{ $t('msgCore.Username') }}</b> </label>
															<input type="text" class="form-control form-control-sm" v-model="user.username" >
														</div>
													</div>	
													
													
													<div v-if="sameRecords.length>0" class="form-row">
														<div class="col-12">
															<label><i class="fa fa-exclamation-triangle text-danger"></i> {{ $t('msgCore.zhodneZaznamy') }}</label>
															<div class="table-responsive">
																<table class="table table-hover table-sm">
																	<tr v-for="(item) in sameRecords" :key="item">
																		<td>{{item.id}}</td>
																		<td>{{item.name}}</td>
																		<td>{{item.surname}}</td>
																		<td>{{item.email}}</td>
																	</tr>
																</table>
															</div>
														</div>
													</div>
														
														
													<div class="form-row">	
														<div class="form-group col-md-4 col-12">
															<label :class="{'text-danger':user.valid_upError}"><b>{{ $t('msgCore.Valid_up') }}</b> </label>
															
															<Datepicker v-model="user.valid_up" format="yyyy-MM-dd HH:mm" :locale="$i18n.locale"  @update:modelValue="checkSave()" :cancelText="$t('msgCore.Cancel')" :selectText="$t('msgCore.Select')" :class="{'is-invalid':user.valid_downError}"></Datepicker>
															<div v-show="user.valid_upError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
														</div>
														
														<div class="form-group col-md-4 col-12">
															<label><b>{{ $t('msgCore.Valid_down') }}</b> </label>
															<Datepicker v-model="user.valid_down" format="yyyy-MM-dd HH:mm" :locale="$i18n.locale"  :cancelText="$t('msgCore.Cancel')" :selectText="$t('msgCore.Select')" ></Datepicker>
															
														</div>
													</div>
													
													<div class="form-row">	
														<div class="form-group col-md-4 col-12">
															<label :class="{'text-danger':user.colorError}"><b>{{ $t('msgCore.Color') }}</b> </label>
															<input type="color" class="form-control form-control-sm" :class="{'is-invalid':user.colorError}" v-on:change="checkSave()" v-model="user.color" >
															<div v-show="user.colorError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
														</div>
														
														
														<div class="form-group col-md-4 col-12">
															<label :class="{'text-danger':user.activeError}"><b>{{ $t('msgCore.Activation') }}</b></label>
															
															<div  v-for="(option) in optionsActivationStates" :key="option" class="form-check" :class="{'disabled':!auth.usersChangeStateActive}">
																<label class="form-check-label">
																	<input :disabled="!auth.usersChangeStateActive" class="form-check-input" type="radio" name="optionsActivationStates"  v-model='user.active' v-on:change="checkSave()" :value="option.value">
																	{{ $t('msgCore.'+option.title) }}
																	<span class="circle">
																		<span class="check"></span>
																	</span>
																</label>
															</div>
															
															<div v-show="user.activeError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectState')}}</span></div>
														</div>
														
														<div class="form-group col-md-4 col-12">
															<label :class="{'text-danger':user.typeError}"><b>{{ $t('msgCore.Type') }}</b></label>
															
															<div  v-for="(option) in optionsTypes"  :key="option" class="form-check" :class="{'disabled':!auth.usersChangeStateActive}">
																<label class="form-check-label">
																	<input :disabled="!auth.usersChangeStateActive" class="form-check-input" type="radio" name="optionsTypes"  v-model='user.type' v-on:change="checkSave()" :value="option.value">
																	{{ $t('msgCore.'+option.title) }}
																	<span class="circle">
																		<span class="check"></span>
																	</span>
																</label>
															</div>
															
															<div v-show="user.typeError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectType')}}</span></div>
														</div>
													</div>	
											
												</div>
												
												<div class="col-md-4 col-12 form-control-system-data">
													<div class="form-row">
													
														<div class="form-group col-12">
															<label :class="{'text-danger':user.accessError}"><b>{{ $t('msgCore.AccessLevel') }}</b></label>
																												
															<select v-model='user.access' :disabled="!auth.usersChangeAccessLevel" class="form-control form-control-sm " :class="{'is-invalid':user.accessError}" v-on:change="checkSave()">
																<option value="">{{ $t('msgCore.selectLevel') }}</option>
																<option v-for="(option) in optionsAccessLevels" :key="option" :value="option.value">{{ option.title }}</option>
															</select>
															<div v-show="user.accessError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectLevel')}}</span></div>
														</div>
														
														<div class="form-group col-12">
															<label :class="{'text-danger':user.stateError}"><b>{{ $t('msgCore.State') }}</b></label>
															
															
															<div  v-for="(option) in optionsStates" :key="option" class="form-check">
																<label class="form-check-label">
																	<input class="form-check-input" type="radio" name="optionsStates"  v-model='user.state' v-on:change="checkSave()" :value="option.value">
																	{{ $t('msgCore.'+option.title) }}
																	<span class="circle">
																		<span class="check"></span>
																	</span>
																</label>
															</div>
															<div v-show="user.stateError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectState')}}</span></div>
														</div>
														
														<div class="form-group col-12">
															<label :class="{'text-danger':user.multiError}"><b>{{ $t('msgCore.MultiLogin') }}</b></label>

															<select v-model='user.multi' :disabled="!user.isAllowedSetMultiLogin" class="form-control form-control-sm " :class="{'is-invalid':user.multiError}" v-on:change="checkSave()">
																<option value="">{{ $t('msgCore.selectLevel') }}</option>
																<option v-for="(option, ) in optionsMultiLogin" :key="option" :value="option.value">{{ option.title }}</option>
															</select>
															<div v-show="user.multiError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectType')}}</span></div>
														</div>
														
													</div>
												</div>
												
											</div>
										</div>
										
										
										<div v-if="params.showUsersExtendedData" id='menu3' class='tab-pane fade' role='tabpanel'>
											
											<div class="row">
												<div class="col-12">
													
													<div class="form-row">
														
														<div class="form-group col-md-3 col-12">
															<label><b>{{ $t('msgCore.Country') }}</b> </label>
															
															<select v-model='user.country' class="form-control form-control-sm ">
																<option value="">{{ $t('msgCore.selectCountry') }}</option>
																<option v-for="(option, ) in optionsCountries" :key="option" :value="option.value">{{ option.title }}</option>
															</select>
														</div>
														
														<div class="form-group col-md-3 col-12">
															<label><b>{{ $t('msgCore.Region') }}</b> </label>
															
															<select v-model='user.region' class="form-control form-control-sm ">
																<option value="">{{ $t('msgCore.selectRegion') }}</option>
																<option v-for="(option, ) in optionsRegions" :key="option" :value="option.value">{{ option.title }}</option>
															</select>
														</div>
														
														<div class="form-group col-md-3 col-12">
															<label><b>{{ $t('msgCore.District') }}</b> </label>
															
															<select v-model='user.district' class="form-control form-control-sm ">
																<option value="">{{ $t('msgCore.selectDistrict') }}</option>
																<option v-for="(option, ) in optionsDistricts"  :key="option" :value="option.value">{{ option.title }}</option>
															</select>
														</div>
														
														<div class="form-group col-md-3 col-12">
															<label><b>{{ $t('msgCore.City') }}</b> </label>
															
															<select v-model='user.city' class="form-control form-control-sm ">
																<option value="">{{ $t('msgCore.selectCity') }}</option>
																<option v-for="(option, ) in optionsCities" :key="option" :value="option.value">{{ option.title }}</option>
															</select>
														</div>
														
													</div>
													
													
													<div class="form-row">
																																			
														<div class="form-group col-md-4 col-12">
															<label><b>{{ $t('msgCore.Phone') }}</b> </label>
															<input type="text" class="form-control form-control-sm" v-model="user.phone" >
														</div>
														
														<div class="form-group col-md-4 col-12">
															<label><b>{{ $t('msgCore.Street') }}</b> </label>
															<input type="text" class="form-control form-control-sm" v-model="user.street" >
														</div>
													
													</div>
												</div>
											</div>	
												
										</div>
										
										
										
									</div>
									
									</div>
								</div>	
									
							</div>
							
							<div v-else class="modal-body">
								<div class="row">
									<div class="col-12">
										<div class="alert alert-warning" role="alert">
											{{$t('msgCore.accessDenied')}}
										</div>
									</div>
								</div>
							</div>
							
							<div class="modal-footer">
							
								<div class="btn-group">
									<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditUser',user.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
									
									<button type="button" :disabled="((!auth.usersEdit) || (!auth.usersNew)) || !accData.modulAcc || sameRecords.length>0" v-on:click="saveUser()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
								</div>	
								
							</div>
						
				
					
					
				
				</div>

			</div>
			
		</div>
		
		
</template>


