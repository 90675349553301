<script>



import axios from 'axios';
//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import axios from 'axios';

export default defineComponent ({
	name: 'ModAuths',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: {'modId': String, 'auth':Object, 'secData':Object},
	emits: ["modId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			mod:[],
			newAuthorization:'',
			
			authorizations:[]
			
		}
	},
	
	mounted:function(){
		
		
		
		//console.log(this.modId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			//console.log(this.modId);				
			if(this.modId){
				this.isNewRecord=false;
			}else{
				this.isNewRecord=true;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('recordID',this.modId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Mod/getAuthorizationData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
				self.authorizations = response.data.authorizations;
				self.mod = response.data.mod;
			
			})
				.catch(function (error) {
				console.log(error);
			
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,modId){
			this.unlockMod(modId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		saveAuthorization:function(){
				
			var bodyFormData = new FormData();
			bodyFormData.set('newAuthorization',this.newAuthorization);
			bodyFormData.set('myRecordID',this.modId);
			
				
			if(this.newAuthorization){
				var self = this;
				axios({
					method: 'post',
					url: '/admins/app/core/Mod/controlAuthorizationSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log(response.data);
						if(response.data==1){
							self.createMessageBox('success','Authorization','dataWasSaved');
							self.loadDefaultValues();
							self.newAuthorization='';
							//self.$emit('changeRecord', '')
						}else if(response.data==0){
							self.createMessageBox('warning','Authorization','dataCantBeSaved');
							self.$emit('changeRecord', '')
						}else if(response.data==2){
							self.createMessageBox('danger','Authorization','dataWasNotSaved');
						}else if(response.data==3){
							self.createMessageBox('danger','Authorization','accessDenied');
						}else if(response.data==4){
							self.createMessageBox('danger','Authorization','existDuplicity');
						}
						
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
			}
			
		},
		
		deleteAuthorization:function(authorizationId){
			
			if(authorizationId){
				
				var bodyFormData = new FormData();
				bodyFormData.set('authorizationId',authorizationId);
				
				var self = this;
				axios({
					method: 'post',
					url: '/admins/app/core/Mod/controlAuthorizationDelete.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
					//	console.log('success2', response.data);
						if(response.data==1){
							self.createMessageBox('success','Authorization','dataWasDeleted');
							self.loadDefaultValues();
							//self.$emit('changeRecord', '')
						}else if(response.data==0){
							self.createMessageBox('warning','Authorization','dataCantBeDeleted');
						}else if(response.data==2){
							self.createMessageBox('danger','Authorization','dataWasNotDeleted');
						}else if(response.data==3){
							self.createMessageBox('danger','Authorization','accessDenied');
						}
						
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
				
			}
			
		},
		
		/*saveAuthorization:function(){
			
			var dataGroups = JSON.stringify(this.groups);
			
			var bodyFormData = new FormData();
			bodyFormData.set('myGroups',dataGroups);
			bodyFormData.set('modId',this.modId);
			
			
				var self = this;
				axios({
					method: 'post',
					url: '/admins/app/core/Mod/controlAuthorizationSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						console.log(response.data);
						if(response.data.resCode==1){
							self.createMessageBox('success','Authorization','dataWasSaved');
							self.$emit('changeRecord', '')
						}else if(response.data.resCode==0){
							self.createMessageBox('warning','Authorization','dataCantBeSaved');
						}else if(response.data.resCode==2){
							self.createMessageBox('danger','Authorization','dataWasNotSaved');
						}else if(response.data.resCode==3){
							self.createMessageBox('danger','Authorization','accessDenied');
						}
						self.groups = [];
						self.closeModalBoxSecure('myModalModAuths');
						
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
				
				
				
			//}
			
		},*/
		
		//odomknutie zaznamu
		unlockMod:function(modId){
		//console.log(modId);
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',modId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Mod/controlModUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
				//	console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		}
		
		
	},
	
	watch: {
		modId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	
	<div id="myModalModAuths" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
			<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
			<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="modal-title">{{ $t('msgCore.Mod') }} <b><span>{{ mod.title }}</span></b></h6>
					</div>
			
					<div v-if="accData.modulAcc && (auth.modsChangeAuths)" class="modal-body">
					
						<div class="row">
							<div class="col-12">
								<p>Oprávnenia</p>
							</div>	
						</div>	
						
						<div class="row">
							<div class="col-12">
								<div class="input-group col-md-4 col-12">
									<input type="text"  :disabled="(!auth.modsAddAuths)" class="form-control form-control-sm" v-model='newAuthorization' :placeholder="$t('msgCore.Title')">
									<button type="button" :disabled="(!auth.modsAddAuths)" :title="$t('msgCore.btnFilter')" class="btn btn-primary btn-sm" v-on:click="saveAuthorization()"><i class="smire-icon fas fa-plus"></i> {{ $t('msgCore.btnAdd') }}</button>
								</div>
							</div>	
						</div>
						
						<div class="row">
							<div class="col-12">
								<table class="table table-sm">
									<tr v-for="(authorization) in authorizations" :key="authorization">
										<td>{{authorization.id}}</td>
										<td>{{authorization.name}}</td>
										<td>
											<button type="button" :disabled="(!auth.modsDeleteAuths || !authorization.isBlocked)" v-on:click="deleteAuthorization(authorization.id)" :title="$t('msgCore.btnDelete')" id="myModalBtn" class="btn btn-danger btn-sm"><i class="fas fa-trash"></i></button>
										</td>
									</tr>
								</table>
							</div>
						</div>
						
					</div>
					
					<div v-else class="modal-body">
						<div class="row">
							<div class="col-12">
								<div class="alert alert-warning" role="alert">
									{{$t('msgCore.accessDenied')}}
								</div>
							</div>
						</div>
					</div>
					
					<div class="modal-footer">
					
						<div class="btn-group">
							<button type="button" v-on:click="closeModalBoxSecureHelper('myModalModAuths',mod.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
						</div>	
						
					</div>
				
					
				
				</div>

			</div>
			
		</div>
		
</template>


