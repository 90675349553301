<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
//library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
//import $ from 'jquery'; 

import axios from 'axios';
//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import axios from 'axios';

export default defineComponent ({
	name: 'UserDelete',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: {'userId': String, 'auth':Object, 'secData':Object},
	emits: ["userId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			basicData:[],
			
			groups:[]
		}
	},
	
	mounted:function(){
		
		
		
		//console.log(this.userId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			//console.log(this.userId);				
			if(this.userId){
				this.isNewRecord=false;
			}else{
				this.isNewRecord=true;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('recordID',this.userId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/User/getUserGroupData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
				self.groups = response.data.groups;
				self.basicData = response.data.basicData;
			
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,userId){
			this.unlockUser(userId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		saveUserGroup:function(){
			
			var dataGroups = JSON.stringify(this.groups);
			
			var bodyFormData = new FormData();
			bodyFormData.set('myGroups',dataGroups);
			bodyFormData.set('myRecordID',this.userId);
			
			
			//if(this.advance.currency && this.advance.account && this.advance.payment_type && this.advance.sum){
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/User/controlUserGroupSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log(response.data)
						if(response.data.resCode==1){
							self.createMessageBox('success','UserGroup',this.$t('msgCore.dataWasSaved'));
							self.unlockUser(this.userId);
							self.$emit('changeRecord', '')
							self.groups = [];
							self.closeModalBoxSecure('myModalEditUserGroup');
						}else if(response.data.resCode==0){
							self.createMessageBox('warning','UserGroup',this.$t('msgCore.dataCantBeSaved'));
						}else if(response.data.resCode==2){
							self.createMessageBox('danger','UserGroup',this.$t('msgCore.dataWasNotSaved'));
						}else if(response.data.resCode==3){
							self.createMessageBox('danger','UserGroup',this.$t('msgCore.accessDenied'));
							
						}
						
						
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
				
				
				
			//}
			
		},
		
		//odomknutie zaznamu
		unlockUser:function(){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',this.userId);
			
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/User/controlUserUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						//self.createMessageBox('success','User','dataWasUnlocked');
						//self.loadDefaultValues();
						//self.$emit('userId', '') //tu pokracuj emit !!!!!!!!!!!!! pri zavreti modal okna
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//self.createMessageBox('warning','User','dataCantBeUnlocked');
						//self.loadDefaultValues();
					}else if(response.data==2){
						//self.createMessageBox('danger','User','dataWasNotUnlocked');
						//self.loadDefaultValues();
					}else if(response.data==3){
						//self.createMessageBox('danger','User','accessDenied');
						//self.loadDefaultValues();
					}
					
				}
			})
			.catch(error => {
				console.log(error)
			}); 
			//self.$emit('update:userId', '')
		},
		
		
	},
	
	watch: {
		userId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	
	<div id="myModalEditUserGroup" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
			<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.User') }} <b><span>{{ basicData.name }} {{ basicData.surname }}</span></b></h6>
				</div>
		
				<div v-if="accData.modulAcc && (auth.usersChangeGroups)" class="modal-body">
				
					<div class="row">
						<div class="col-12">
							<p><b>{{$t('msgCore.accessGroups')}}</b></p>
						</div>	
					</div>	
					
					
					<div class="row">
						
						<div class="col-12" >
						
							<div  v-for="(group) in groups" :key="group" class="togglebutton">
								<label>
									<input v-model='group.isSelected' type="checkbox">
									<span class="toggle"></span>
									{{group.title}}
								</label>
							</div>
						
						</div>
						
					</div>	
					
						
				</div>
				
				<div v-else class="modal-body">
					<div class="row">
						<div class="col-12">
							<div class="alert alert-warning" role="alert">
								{{$t('msgCore.accessDenied')}}
							</div>
						</div>
					</div>
				</div>
				
				<div class="modal-footer">
				
					<div class="btn-group">
						<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditUserGroup',basicData.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
						
						<button type="button" :disabled="(!auth.usersChangeGroups) || !accData.modulAcc" v-on:click="saveUserGroup()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
					</div>	
					
				</div>
				
			</div>

		</div>
		
	</div>
	
	
		
		
</template>


