<script>

 

import axios from 'axios';

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import axios from 'axios';

export default defineComponent ({
	name: 'GroupEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
		//Datepicker
	},
	props: {'groupId': String, 'auth':Object, 'secData':Object},
	emits: ["groupId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			recordID:null,
			isNewRecord:true,
			
			optionsAccessLevels:[],
			optionsStates:[],
			
			//form
			group:[]
		}
	},
	
	mounted:function(){
		
		
		
		//console.log(this.groupId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			//console.log(this.groupId);				
			if(this.groupId){
				this.isNewRecord=false;
			}else{
				this.isNewRecord=true;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('recordID',this.groupId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Group/getGroupData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.group = response.data.group;
				
				self.optionsAccessLevels = response.data.optionsAccessLevels;
				self.optionsStates = response.data.optionsStates;
		
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,groupId){
			this.unlockGroup(groupId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		
		checkSave:function(){
				
			if(!this.group.title){this.group.titleError = true;}else{this.group.titleError = false;}
			if(!this.group.access){this.group.accessError = true;}else{this.group.accessError = false;}
			if(!this.group.state){this.group.stateError = true;}else{this.group.stateError = false;}
			
			if(!this.group.titleError && !this.group.accessError && !this.group.stateError){
				return true;
			}else{
				this.createMessageBox('warning','Group','fillInAllData');
				return false;
			}
		},	
		
		saveGroup:function(){
							
			if(this.checkSave()){
				
				var dataGroup = JSON.stringify(this.group);
		
				var bodyFormData = new FormData();
				bodyFormData.set('myGroup',dataGroup);
				
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Group/controlGroupSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data==1){
							self.createMessageBox('success','Group',this.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')
							//self.closeModalBoxSecureHelper('myModalEditGroup',self.groupId);
							self.closeModalBoxSecure('myModalEditGroup');
							
						}else if(response.data==0){
							self.createMessageBox('warning','Group',this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalEditGroup');
						}else if(response.data==2){
							self.createMessageBox('danger','Group',this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalEditGroup');
						}else if(response.data==3){
							self.createMessageBox('danger','Group',this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalEditGroup');
						}
						self.group = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
				
			}
			
		},
		
		//odomknutie zaznamu
		unlockGroup:function(groupId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',groupId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Group/controlGroupUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},
		
	},
	
	watch: {
		groupId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
		<div id="myModalEditGroup" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
			<div class="modal-dialog modal-lg" style="width:100%; max-width: none;">
				<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="modal-title">{{ $t('msgCore.Group') }} <span v-if="group.isNewRecord">{{ $tc('msgCore.new',1) }}</span><span v-else><b>{{ group.title }}</b> id: {{group.id}}</span></h6>
					</div>
						
							<div v-if="accData.modulAcc && (auth.groupsEdit || auth.groupsNew)" class="modal-body">
								
								<div class="row">
									<div class="col-12">
										<div v-if="group.isCheckedOut && secData.userId != group.isCheckedOutId" class="angle">
											<span class="angle-inner" :class="{'angle-warning':group.isCheckedOut}"><i class="fa" :class="{'angle-warning':group.isCheckedOut, 'fa-exclamation-triangle':group.isCheckedOut}" aria-hidden="true"></i> {{$t('msgCore.Edited')}} </span >
											
											<span v-if="group.isCheckedOut" class="personCircle v-center" v-bind:style="{background:group.isCheckedOutColor}" v-bind:title="group.isCheckedOutName+', '+group.isCheckedOutDate">{{ group.isCheckedOutLetterName+''+group.isCheckedOutLetterSurname }}</span>
										</div>
									</div>
								</div>			
								
								<div class="row">
									
									<div class="col-md-8 col-12">
									
											<div class="form-row">
												
												<div class="form-group col-md-4 col-12">
													<label :class="{'text-danger':group.titleError}"><b>{{ $t('msgCore.Title') }}</b> </label>
													<input type="text" class="form-control form-control-sm" :class="{'is-invalid':group.titleError}" v-on:change="checkSave()" v-model="group.title" >
													<div v-show="group.titleError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
												</div>
												
											</div>	
									</div>

									<div class="col-md-4 col-12 form-control-system-data">									
									
										<div class="form-group col-12">
											<label :class="{'text-danger':group.accessError}"><b>{{ $t('msgCore.AccessLevel') }}</b></label>
																								
											<select v-model='group.access' class="form-control form-control-sm " :class="{'is-invalid':group.accessError}" v-on:change="checkSave()">
												<option  value="">{{ $t('msgCore.selectLevel') }}</option>
												<option v-for="option in optionsAccessLevels" :key="option" :value="option.value">{{ option.title }}</option>
											</select>
											<div v-show="group.accessError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectLevel')}}</span></div>
										</div>
										
										<div class="form-group col-12">
											<label :class="{'text-danger':group.stateError}"><b>{{ $t('msgCore.State') }}</b></label>
											
												<div  v-for="option in optionsStates" :key="option" class="form-check">
													<label class="form-check-label">
														<input class="form-check-input" type="radio" name="optionsStates"  v-model='group.state' v-on:change="checkSave()" :value="option.value">
															{{ $t('msgCore.'+option.title) }}
															<span class="circle">
																<span class="check"></span>
															</span>
													</label>
												</div>
											<div v-show="group.stateError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectState')}}</span></div>
										</div>
									
									</div>	
								</div>
								
							</div>
							
							<div v-else class="modal-body">
								<div class="row">
									<div class="col-12">
										<div class="alert alert-warning" role="alert">
											{{$t('msgCore.accessDenied')}}
										</div>
									</div>
								</div>
							</div>
							
							
							
							
							
							<div class="modal-footer">
							
								<div class="btn-group">
									<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditGroup',group.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
									
									<button type="button" :disabled="((!auth.groupsEdit) || (!auth.groupsNew)) || !accData.modulAcc" v-on:click="saveGroup()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
								</div>	
								
							</div>
						
				
					
					
				
				</div>

			</div>
			
		</div>
		
</template>


