<script>

import $ from 'jquery'; 

import MutationEdit from '@/components/Core/Mutations/MutationEdit.vue'
import MutationDelete from '@/components/Core/Mutations/MutationDelete.vue'


import Popper from "vue3-popper";

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';

import axios from 'axios';

export default {
	name: 'Mutations',
	mixins: [ MixinGeneralFunct ],
	components: {
		Popper
		,MutationEdit,MutationDelete
	},
	props: ["auth","secData","params"],
	data() {
		return {
			prefix:'',
		
			
			accData:[],
			
			preloader:true,
			
			helpboxData: [],
			
			basicDataCount:0,
			basicData:[],
			selectedFilterText:'',
			
			selectedMutationId:'',
			
						
			showFilter:false,
			filterExtended:false,
			
			showedHelpbox:false
		}
	},
	
	mounted:function(){
		
	},
	
	methods: {
		
		//na fontende aj preview aj tabula, zober povodne idcka a idcka po aktualizacii a tie naviac zobraz na par sekund inou farbou, alebo ako nove.
		
		//default data
		
		
		loadDefaultValues:function(){
			
			//show loader
			this.preloader=true
			
			
			var self = this;
			axios.get('/admins/app/core/Mutations/getMutationList.php',{
				params:{
					filterText: this.selectedFilterText
					
					
				}
			})
			.then(function (response) {
				//console.log(response.data)
				//hide loader
				self.preloader=false
				
				//access data
				self.accData = response.data.accData;
				
				//helpbox data
				self.helpboxData = 'guidebook.guideMutations';
				
				self.basicDataCount = response.data.basicDataCount;
				self.basicData = response.data.basicData;
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
				
		//odomknutie zaznamu
		unlockMutation:function(mutationId){
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',mutationId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Mutation/controlMutationUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.createMessageBox('success','Mutation',this.$t('msgCore.dataWasUnlocked'));
						self.loadDefaultValues();
					}else if(response.data==0){
						self.createMessageBox('warning','Mutation',this.$t('msgCore.dataCantBeUnlocked'));
						self.loadDefaultValues();
					}else if(response.data==2){
						self.createMessageBox('danger','Mutation',this.$t('msgCore.dataWasNotUnlocked'));
						self.loadDefaultValues();
					}else if(response.data==3){
						self.createMessageBox('danger','Mutation',this.$t('msgCore.accessDenied'));
						self.loadDefaultValues();
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},
		
		
		
		
		
				
		
		
		
		
		
		//zobrazi filtre
		showFilters: function(){
			if(!this.showFilter) {
				this.showFilter = true;
			}else{
				this.showFilter = false;
			}
		},
		
		//vycisti text filter
		resetTextFilter: function(){
			this.selectedFilterText = '';
			this.loadDefaultValues();
		},
		
		// zobrazi napovedu
		showHelpbox: function(){
			if(!this.showedHelpbox) {
				this.showedHelpbox = true;
			}else{
				this.showedHelpbox = false;
			}
		},
		// systemove funkcie k zoznamu KONIEC
		
		
		//modal box user editacia
		loadModalFormMutationEdit:function(recordID){
			this.cSession();
			//console.log(recordID)
			this.selectedMutationId=recordID;
			$('#myModalEditMutation').addClass('modal');
			$('#myModalEditMutation').modal({backdrop: "static"});
		},
		
		//modal box user delete
		loadModalFormMutationDelete:function(recordID){
			this.cSession();
			//console.log(recordID)
			this.selectedMutationId=recordID;
			$('#myModalDeleteMutation').addClass('modal');
			$('#myModalDeleteMutation').modal({backdrop: "static"});
		},
		
		reloadAfterChange() {
			//console.log(e);
			this.loadDefaultValues();
			this.selectedMutationId='';
			//this.cart.push(e);
			//this.total = this.shoppingCartTotal;
		},

		//vyber stranky v strankovaci
		reloadAfterChangeNrPage(selectedPage) {
			this.selectedPage=selectedPage
			this.loadDefaultValues();
		},
		
		//vyber poctu zaznamov
		reloadAfterChangeCountRecords(selectedCount) {
			this.selectedRecords=selectedCount
			this.loadDefaultValues();
		},

		
	},	
	
			
	watch: {
		'auth.mutationsList': function () {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
}

</script>

<template>
	
	<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
	<div id="version" class="version"><p>{{accData.version}}</p></div>
				
		<div v-if="accData.modulAcc"> 
		
			<div class="row">
				<div class="col-12">
				
				
					<div class="row">
						<div class="col-6">
							<h5>{{ $t('msgCore.Mutations') }}</h5>	 
						</div> 
						
						<div class="col-6 right">
							
							<button type="button" :disabled="!auth.levelsNew" v-on:click="loadModalFormMutationEdit(null)" class="btn btn-primary btn-sm"><i class="smire-icon fas fa-plus"></i> {{ $t('msgCore.btnAdd') }}</button>
							
						</div> 
					</div>
																	
							
					<!-- filters basic -->
					<div class="row">
					
						<div class="input-group col-md-4 col-12">
							<button type="button" :title="$t('msgCore.btnFilter')" class="btn btn-info btn-sm my-btn" v-on:click="showFilters()">
								<span v-if="filterExtended">
									<i v-if="!showFilter" class="smire-icon fas fa-search-plus"></i> 
									<i v-else class="smire-icon fas fa-search-minus"></i>
								</span>
								<span v-else>
									<i class="smire-icon fas fa-search"></i> 
								</span>
								{{ $t('msgCore.btnFilter') }}
							</button>
							<input type="text" class="form-control form-control-sm" v-model='selectedFilterText' :placeholder="$t('msgCore.searchTitle')" v-on:change="loadDefaultValues()">
							<button type="button" :title="$t('msgCore.btnStorno')" class="btn btn-outline-info btn-sm my-btn" v-on:click="resetTextFilter()">
								<i class="smire-icon fas fa-times"></i>
							</button>
						</div>
						
					</div>

					<!-- filters extended -->
					<div v-show="showFilter && filterExtended" class="row">
						<div class="input-group form-group-filter col-12">
						</div>
					</div>
					
				</div>
			</div>				
			
			<!-- zakladny zoznam -->
			<div v-if="basicDataCount>0">
			
				<div class="row">
					<div class="col-12">
	
						<div class="table-responsive">
							<table class="table table-hover table-sm">
							
								<thead>
									<tr>
										<th class="orderBy">id</th>
										
										
										<th>
											{{$t('msgCore.Action')}}
										</th>
										
										<th>{{$t('msgCore.State')}}</th>
										
										
										<th>{{$t('msgCore.Title')}}</th>
										
										<th>{{$t('msgCore.short')}}</th>
										
										
										<th>{{$t('msgCore.Created')}}</th>
										
										<th>{{$t('msgCore.Modified')}}</th>
										
										<th>{{$t('msgCore.AccessLevel')}}/{{$t('msgCore.Users')}}</th>
										
																			
									</tr>
								</thead>
								
								
								<tr v-for="(basicObj ) in basicData" :key="basicObj" >
								
									<td>{{ basicObj.id }}</td>
								
																	
									<td>
										<div class="input-group">	
											
											<button v-if="basicObj.isCheckedOut" type="button" :disabled="!auth.mutationsUnlock" v-on:click="unlockMutation(basicObj.id)" v-bind:title="basicObj.checkedOutName+', '+basicObj.isCheckedOutDate" class="btn btn-sm"><i class="smire-icon fas fa-lock"></i></button>
																				
											<div class="btn-group">				
												<button type="button" :disabled="!auth.mutationsEdit" v-on:click="loadModalFormMutationEdit(basicObj.id)" :title="$t('msgCore.btnEdit')" id="myModalBtn" class="btn btn-secondary btn-sm"><i class="fas fa-edit"></i></button>
										
												<button v-if="basicObj.isAllowedRemove" type="button" :disabled="!auth.mutationsDelete" v-on:click="loadModalFormMutationDelete(basicObj.id)" :title="$t('msgCore.btnDelete')" id="myModalBtn" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
															
											</div>	
											
											
											
										</div>	
									</td>
									
									<td :title="basicObj.state_name">
										<i class="fas fa-circle" :class="{'text-success':basicObj.state==1,'text-danger':basicObj.state==2}" ></i>
									</td>	
									
									
									
									<td >{{ basicObj.title }}</td>							
									
									<td >{{ basicObj.shorts }}</td>
									
									
									
									<td>
										<Popper :content="basicObj.creator+', '+basicObj.createdView">
											<button class="personCircle v-center" v-bind:style="{background:basicObj.creatorColor}">{{ basicObj.creatorNameLetter+''+basicObj.creatorSurnameLetter }}</button>
										</Popper>
									</td>

									<td>
										<Popper v-if="basicObj.editor" :content="basicObj.editor+', '+basicObj.modifiedView">
											<button class="personCircle v-center" v-bind:style="{background:basicObj.editorColor}">{{ basicObj.editorNameLetter+''+basicObj.editorSurnameLetter }}</button>
										</Popper>
									</td>
									
								
									<td >{{ basicObj.access_name }}<br>
									
										<span v-for="(user) in basicObj.users" :key="user" >
											<span >{{ user.name }} {{ user.surname }}</span>,
										</span>
									
									</td>
									
									
								</tr>
									

							
							</table>	
						</div>		
					
					</div>
				</div>
					
				<div class="row row-pagination">
					<div class="col-6 col-md-3 col-lg-2">
						
					</div>
					
					<div class="col-6 col-md-3 col-lg-2">
						<div class="form-group form-group-filter">
							<span class="btn btn-default btn-sm">Počet: {{basicDataCount}}</span>
						</div>
					</div>
					
					<div class="col-12 col-md-6 col-lg-8">	
						
					</div>
				</div>	
				
			</div>	
					
			<div v-else class="row">
				<div class="col-12">
					<div  class="alert alert-warning" role="alert">
						{{$t('msgCore.forThisSelectExistAnyData')}}
					</div>	
				</div>							
			</div>
						
								
		</div>
			
			
		
		<div v-else >
			<div class="row">
				<div class="col-12">
					<div class="alert alert-warning" role="alert">
						{{$t('msgCore.accessDenied')}}
					</div>
				</div>
			</div>
		</div>
	
		<MutationEdit :auth="auth" :secData="secData" :mutationId="String(selectedMutationId)" @changeRecord="reloadAfterChange"></MutationEdit>
		
		<MutationDelete :auth="auth" :secData="secData" :mutationId="String(selectedMutationId)" @changeRecord="reloadAfterChange"></MutationDelete>
	
		
</template>


