<script>


import axios from 'axios';

	
//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'


export default defineComponent ({
	name: 'GalleryPreview',
	mixins: [ MixinGeneralFunct ],
	components: {
		
	},
	props: {'galleryId': String, 'auth':Object, 'secData':Object},
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
						
			optionsGalleries:[],
			selectedGallery:'',

			//form
			gallery:[],
			photos:[]
		}
	},
	
	mounted:function(){
		
		
		//this.loadDefaultValues();
		//console.log(this.galleryId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			var bodyFormData = new FormData();
			bodyFormData.set('galleryId',this.galleryId);
		
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Gallery/getGalleryPreviewData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.gallery = response.data.gallery;
				self.photos = response.data.photos;
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},

		
		
		closeModalBoxSecureHelper:function(myform){
			this.closeModalBoxSecure(myform);
		},
		
		//otocenie fotky o 90
		rotatePhoto:function(recordID){
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',recordID);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Gallery/controlPhotoRotate.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log('success2', response.data);
					if(response.data==1){
						self.createMessageBox('success','Photo',self.$t('msgCore.photoWasRotated'));
						self.loadDefaultValues();
					}else if(response.data==0){
						self.createMessageBox('warning','Photo',self.$t('msgCore.photoCantBeRotated'));
					}else if(response.data==2){
						self.createMessageBox('danger','Photo',self.$t('msgCore.photoWasNotRotated'));
					}else if(response.data==3){
						self.createMessageBox('danger','Photo',self.$t('msgCore.accessDenied'));
					}
					
					
				}
			})
			.catch(error => {
					console.log(error.response)
			}); 
		}
		
	},
	
	watch: {
		galleryId() {
			this.loadDefaultValues();
		},
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalPreviewGallery" class="fade show " role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
			<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.Preview') }} <span><b>{{ gallery.title }}</b> </span></h6>
				</div>
		
				<div class="modal-body">
					<div class="row">
						<div class="col-12">
							<div v-for="photo in photos" :key="photo" class="crop_gallery2">
								<a class="fancybox" data-fancybox="gallery" :href="photo.fotoDataOrigo"><img :src="photo.fotoData" :title="photo.title"></a>
								<button type="button" :disabled="!auth.photosRotate" v-on:click="rotatePhoto(photo.id)" :title="$t('msgCore.btnRotate')" class="btn btn-primary btn-sm"><i class="smire-icon fas fa-sync"></i></button>
							</div>
						</div>
					</div>
				</div>
				
				<div class="modal-footer">
					<div class="btn-group">
						<button type="button" v-on:click="closeModalBoxSecureHelper('myModalPreviewGallery')" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>

						
					</div>	
				</div>
						
			</div>

		</div>
		
	</div>
	
	
</template>


