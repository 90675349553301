<script>

export default {
	name: 'LangSwitch',
	
	components: {
	
	},
	
	data() {
		return {
			actLang:''
		}
	},
	
	props: {'propActLang': String, 'langsData': Array},
	
	mounted:function(){
		if(localStorage.Lang!=null) {this.actLang=localStorage.Lang} else { this.actLang='sk'}
	},
	
	methods: {
		
		setLang:function(langId){
			localStorage.Lang=langId;
			this.actLang=langId;
			this.$emit('update:propActLang', langId)
			//console.log(localStorage.Lang)
		},
	
	},	
	
	watch: {
		
	},

	computed:{
		
	},
	
}

</script>

<template>
	<div class="col-2 text-right langs">
		<span v-for='(i) in langsData' :key="i" ><span :class='{active:actLang==i.shorts}' v-on:click='setLang(i.shorts)'>{{i.shorts}}&nbsp;</span> </span>
	</div>
</template>


