<script>

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import Popper from 'popper.js'
import 'bootstrap/dist/js/bootstrap.min.js'
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false
//import '@fancyapps/fancybox/dist/jquery.fancybox.min.js'


import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faVideo, faUserFriends, faListAlt, faFileAlt, faGlobe, faPlayCircle, faHome, faPen, faArrowAltCircleRight, faSignOutAlt, faSignInAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faEye, faEyeSlash, faUser, faQrcode, faCamera, faThList, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
library.add( faBars, faVideo, faUserFriends, faListAlt, faFileAlt, faGlobe, faPlayCircle, faHome, faPen, faArrowAltCircleRight, faSignOutAlt, faSignInAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faEye, faEyeSlash, faUser, faQrcode, faCamera, faThList, faSyncAlt)
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import $ from 'jquery';



import myLangs from './components/Common/LangSwitch.vue'
import myMenu from './components/Common/MainMenu.vue'
import mainVersion from './components/Common/MainVersion.vue'
import mainLogo from './components/Common/MainLogo.vue'

//import myQuestions from './components/Questions.vue'

//general functions
import MixinGeneralFunct from './mixins/GeneralFunctions';

import axios from 'axios';



export default {
	name: 'App',
	mixins: [ MixinGeneralFunct ],
	components: {
		myLangs
		,myMenu
		,mainVersion
		,mainLogo
		,"font-awesome-icon":FontAwesomeIcon
	},
	
	directives: {
		focus: {
			// directive definition
			mounted(el) {
				el.focus()
			}
		}
	},
	data() {
		return {
			prefix:'',
			user:[],
			userData:[],
			
			auth:[],
			secData:[],
			params:[],

			version:'',
			actLangs:[],
			
			checkRegisterUser:null,
			checkRegisterEvent:null,
			eventCounts:[{streams:0,documents:0,expo:0,program:0,speakers:0,records:0}],
			
			cookiesTime: 259200,
			event:[],
			
			alerts:[],
			
			menuItems:[],
			
			menuIsOpened:true,
			showPersonalmenu:false,
			
			t0:0,
			t1:0,
		
		}
	},
	
	mounted:function(){
		this.loadDefaultValues();
		//console.log(this.$route.path)
	},
	
	methods: {
		
		//default data
		loadDefaultValues:function(){
			
			this.loadAccessData();
			this.loadMenuItem();
			this.loadVersion();
			
			//console.log(this.userData);
		
			var self = this;
			axios.get('/admins/app/core/Common/getDefaultDataLogin.php')
			.then(function (response) {
				//console.log("loadDefData");
				//console.log(response.data);
				self.userData = response.data.userData;
				//console.log(self.userData);
				
				self.user = response.data.user;
				//console.log(self.user);
				//console.log(self.user.isReg);
				if(!self.user.isReg){
					self.$router.push({ path: '/login' })
				}
				
			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		//toto presun pod default data, tiez polozky menu
		//access data
		loadAccessData:function(){
			var self = this;
			axios.get('/admins/app/core/Common/loadAccessData.php')
			.then(function (response) {
				//console.log(response.data);
				self.secData = response.data.secData;
				self.auth = response.data.secData.auth;
				self.$i18n.locale = response.data.langData; 
				localStorage.Lang = response.data.langData;
				self.params = response.data.params;
				self.actLangs = response.data.actLangs;
			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		//menu data
		loadMenuItem:function(){
			var self = this;
			axios.get('/admins/app/core/Common/loadMenuData.php')
			.then(function (response) {
				//console.log(response.data);
				self.menuItems = response.data.mainMenu;
			})
				.catch(function (error) {
				console.log(error);
			});
		},

		//version data
		loadVersion:function(){
			var self = this;
			axios.get('/admins/app/core/Common/loadVersion.php')
			.then(function (response) {
				//console.log(response.data);
				self.version = response.data.version;
			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		
		//zobrazi osobne menu
		showPersonalMenu: function(){
			this.showPersonalmenu = this.showPersonalmenu===true?false:true;
		},
		
		
		// zatvaranie modalbox
		closeModalBoxSecure:function(myform){
			$("#"+myform).modal("hide");
			$("#"+myform).removeClass("modal");
		},
		
		
		
		
		
		//odhlasenie reg ucastnika
		logOutMe:function(){
			
			this.t0 = performance.now()
			
			//loader
			$('#preloader').show();
			
			
			var self = this;
			axios.get('/admins/app/core/Common/controlLogout.php')
			.then(function (response) {
				
				self.t1 = performance.now()
				
				self.user = response.data.userData;
				
				self.loadOnRegisterUser();
				
				//hide loader
				$('#preloader').fadeOut(200);
				
				if(!self.user.isReg){
					//location.reload();
					self.$router.push({ path: '/login' })
					//console.log("smerujem");
					self.menuItems=[];
				}
				//setTimeout(function(){ window.location.href = window.location.protocol+'//'+window.location.hostname+'/'; }, 100);
			})
				.catch(function (error) {
				console.log(error);
			});
		},
				
		loadOnRegisterUser:function(){
			if(this.checkRegisterUser){
				this.checkRegisterUser=false;
			}else{
				this.checkRegisterUser=true;
			}
		},
		
		//funkcia zabezpeci nacitanie registrovaneho obsahu eventu
		//Uvod.vue
		loadOnRegisterEvent:function(){
			if(this.checkRegisterEvent){
				this.checkRegisterEvent=false;
			}else{
				this.checkRegisterEvent=true;
			}
		},
		
		
		
		
		
		//zobrazenie menu
		showHideMenu:function(){
			if(this.menuIsOpened){
				this.menuIsOpened=false;
			}else{
				this.menuIsOpened=true;
			}
			//console.log(this.menuIsOpened)
		},
		
		//modal logout Event
		loadModalLogOutEvent:function(){
			$('#myModalLogOutEvent').addClass('modal');
			$('#myModalLogOutEvent').modal({backdrop: "static"});
		},
		
		
		
	},
	
	
	
	created(){
		if(localStorage.Lang!=null) this.$i18n.locale=localStorage.Lang; 
	},
}
</script>


<template>



<div class="wrapper">

        <nav id="sidebar" :class="{'active':!menuIsOpened}">
			<div class="select-lang bg-info"><myLangs v-model:propActLang="$i18n.locale" v-model:langsData="actLangs"></myLangs></div>
			<div class="menu"><myMenu :menuData="menuItems" :propActLang="$i18n.locale"></myMenu></div>

			<div class="sidebar-bottom"><mainLogo></mainLogo></div>
			<div v-if='user.isReg' class="sidebar-version"><mainVersion :version="version"></mainVersion></div>
		</nav>

        <div id="content" :class="{'active':!menuIsOpened}">
		
				<div id="msg"></div>
				
				<div id="content-head" :class="{'active':!menuIsOpened}">
					<div><div class='top-menu-box-left'><button type='button' id='sidebarCollapse' class='btn btn-login-style' v-on:click="showHideMenu()"><font-awesome-icon :icon="['fas', 'bars']" class='fa-login-style' /></button></div></div>
					<!--<div v-if='helpboxData' v-on:click="showHelpbox()" class="top-menu-box-right">
						<a href="#"><i v-if="!showedHelpbox" class="fas fa-question fa-login-style"></i><i v-else class='fas fa-times fa-login-style'></i></a>
					</div>-->
					<div v-if='user.isReg' class='top-menu-box-right'><a v-on:click='showPersonalMenu()' href='#'><font-awesome-icon :icon="['fas', 'user']" class='fa-login-style' /></a></div>

					<div v-if="showPersonalmenu && user.isReg" id="personalMenu" class="personalMenu">
						<a :href="'/'+ params.appFolder +'user-change-password'"><p>Zmena údajov</p></a>
						<a v-on:click='logOutMe()' :title="$t('msgCore.SignOut')" href='#'><p>{{$t('msgCore.SignOut')}}</p></a>
					</div>
				</div>	
				
				<div id="content-main">
				
					<!-- zakladny zoznam -->
					
					<div class="container-fluid" >
						
							<router-view :auth="auth" :secData="secData" :params="params" ></router-view>
						
					</div>
				</div>	
			
		</div>	
		
</div>



	

	
	
	


</template>

<style lang="scss">
	@import "./assets/scss/custom.scss";
</style>

<style lang="css">
	
	@import "./assets/css/all.min.css";
	@import "./assets/css/themePopper.css";
</style>