<script>

import axios from 'axios';
	
//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'


export default defineComponent ({
	name: 'MutationEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		
	},
	props: {'mutationId': String, 'auth':Object, 'secData':Object},
	emits: ["mutationId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			isNewRecord:true,
			
			optionsAccessLevels:[],
			optionsStates:[],
			
			optionsMutationTypes:[],
			
			//form
			mutation:[]
		}
	},
	
	mounted:function(){
		
		
		
		//console.log(this.mutationId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			//console.log(this.mutationId);				
			if(this.mutationId){
				this.isNewRecord=false;
			}else{
				this.isNewRecord=true;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('mutationId',this.mutationId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Mutation/getMutationData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.mutation = response.data.mutation;
				
				self.optionsAccessLevels = response.data.optionsAccessLevels;
				self.optionsStates = response.data.optionsStates;

				self.optionsMutationTypes = response.data.optionsMutationTypes;
		
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,mutationId){
			this.unlockMutation(mutationId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
				
		checkSave:function(){
				
			if(!this.mutation.title){this.mutation.titleError = true;}else{this.mutation.titleError = false;}
			if(!this.mutation.shorts){this.mutation.shortsError = true;}else{this.mutation.shortsError = false;}
			if(!this.mutation.access){this.mutation.accessError = true;}else{this.mutation.accessError = false;}
			if(!this.mutation.state){this.mutation.stateError = true;}else{this.mutation.stateError = false;}
			
			if(!this.mutation.titleError && !this.mutation.accessError && !this.mutation.shortsError && !this.mutation.stateError && !this.mutation.typeError){
				return true;
			}else{
				this.createMessageBox('warning','Mutation',this.$t('msgCore.fillInAllData'));
				return false;
			}
		},	
		
		saveMutation:function(){
				
			if(this.checkSave()){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myMutation',JSON.stringify(this.mutation));
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Mutation/controlMutationSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data.resCode==1){
							self.createMessageBox('success','Mutation',this.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')
							self.closeModalBoxSecure('myModalEditMutation');
						}else if(response.data.resCode==0){
							self.createMessageBox('warning','Mutation',this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalEditMutation');
						}else if(response.data.resCode==2){
							self.createMessageBox('danger','Mutation',this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalEditMutation');
						}else if(response.data.resCode==3){
							self.createMessageBox('danger','Mutation',this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalEditMutation');
						}
						self.mutation = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 		
				
			}
			
		},
		
		//odomknutie zaznamu
		unlockMutation:function(mutationId){
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',mutationId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Mutation/controlMutationUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
				//	console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		}
		
	},
	
	watch: {
		mutationId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
		<div id="myModalEditMutation" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
			<div class="modal-dialog modal-lg" style="width:100%; max-width: none;">
				<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="modal-title">{{ $t('msgCore.Mutation') }} <span v-if="mutation.isNewRecord">{{ $tc('msgCore.new',0) }} • </span><span v-else><b>{{ mutation.title }}</b> id: {{mutation.id}}</span></h6>
					</div>
						
					<div v-if="accData.modulAcc && (auth.mutationsEdit || auth.mutationsAdd)" class="modal-body">
						
						<div class="row">
							<div class="col-12">
								<div v-if="mutation.isCheckedOut && secData.userId != mutation.isCheckedOutId" class="angle">
									<span class="angle-inner" :class="{'angle-warning':mutation.isCheckedOut}"><i class="fa" :class="{'angle-warning':mutation.isCheckedOut, 'fa-exclamation-triangle':mutation.isCheckedOut}" aria-hidden="true"></i> {{$t('msgCore.Edited')}} </span >
									
									<span v-if="mutation.isCheckedOut" class="personCircle v-center" v-bind:style="{background:mutation.isCheckedOutColor}" v-bind:title="mutation.isCheckedOutName+', '+mutation.isCheckedOutDate">{{ mutation.isCheckedOutLetterName+''+mutation.isCheckedOutLetterSurname }}</span>
								</div>
							</div>
						</div>			
						
						<div class="row">
							
							<div class="col-md-8 col-12">
							
									<div class="form-row">
										
										<div class="form-group col-md-4 col-12">
											<label :class="{'text-danger':mutation.titleError}"><b>{{ $t('msgCore.Title') }}</b> </label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid':mutation.titleError}" v-on:change="checkSave()" v-model="mutation.title" >
											<div v-show="mutation.titleError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
										</div>
										
										<div class="form-group col-md-8 col-12">
											<label :class="{'text-danger':mutation.shortsError}"><b>{{ $t('msgCore.short') }}</b> </label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid':mutation.shortsError}" v-on:change="checkSave()" v-model="mutation.shorts" >
											<div v-show="mutation.shortsError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
										</div>
										
									</div>
									
									
							</div>

							<div class="col-md-4 col-12 form-control-system-data">									
							
								<div class="form-group col-12">
									<label :class="{'text-danger':mutation.accessError}"><b>{{ $t('msgCore.AccessLevel') }}</b></label>
																						
									<select v-model='mutation.access' class="form-control form-control-sm " :class="{'is-invalid':mutation.accessError}" v-on:change="checkSave()">
										<option  value="">{{ $t('msgCore.selectLevel') }}</option>
										<option v-for="(option) in optionsAccessLevels" :value="option.value" :key="option" >{{ option.title }}</option>
									</select>
									<div v-show="mutation.accessError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectLevel')}}</span></div>
								</div>
								
								<div class="form-group col-12">
									<label :class="{'text-danger':mutation.stateError}"><b>{{ $t('msgCore.State') }}</b></label>
									
										<div  v-for="(option) in optionsStates" :key="option" class="form-check">
										<label class="form-check-label">
											<input class="form-check-input" type="radio" name="optionsStates"  v-model='mutation.state' v-on:change="checkSave()" :value="option.value">
											{{ $t('msgCore.'+option.title) }}
											<span class="circle">
											<span class="check"></span>
											</span>
										</label>
										</div>
									<div v-show="mutation.stateError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectState')}}</span></div>
								</div>
								
							
							</div>	
						</div>
						
					</div>
					
					<div v-else class="modal-body">
						<div class="row">
							<div class="col-12">
								<div class="alert alert-warning" role="alert">
									{{$t('msgCore.accessDenied')}}
								</div>
							</div>
						</div>
					</div>
					
					<div class="modal-footer">
					
						<div class="btn-group">
							<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditMutation',mutation.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
							
							<button type="button" :disabled="((!auth.mutationsEdit) || (!auth.mutationsAdd)) || !accData.modulAcc" v-on:click="saveMutation()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
						</div>	
						
					</div>
						
				</div>

			</div>
			
		</div>
		
		
</template>


