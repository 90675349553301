<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
//library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
//import $ from 'jquery'; 

import axios from 'axios';
//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import axios from 'axios';

export default defineComponent ({
	name: 'PhotoDelete',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: {'photoId': String, 'auth':Object, 'secData':Object},
	emits: ["photoId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			recordID:null,
			
			//form
			photo:[]
		}
	},
	
	mounted:function(){
		
		//console.log(this.photoId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
	
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			//console.log(this.photoId);				
			if(this.photoId){
				this.isNewRecord=false;
			}else{
				this.isNewRecord=true;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('photoId',this.photoId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Photo/getPhotoData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.photo = response.data.photo;
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,photoId){
			this.unlockPhoto(photoId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		//odomknutie zaznamu
		unlockPhoto:function(photoId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',photoId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Photo/controlPhotoUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},
		
		deletePhoto:function(photoId){
			
			if(photoId){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myPhoto',JSON.stringify(this.photo));
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Photo/controlPhotoDelete.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data.resCode==1){
							self.createMessageBox('success','Photo',this.$t('msgCore.dataWasDeleted'));
							self.$emit('changeRecord', '')
							self.closeModalBoxSecure('myModalDeletePhoto');
						}else if(response.data.resCode==0){
							self.createMessageBox('warning','Photo',this.$t('msgCore.dataCantBeDeleted'));
							self.closeModalBoxSecure('myModalDeletePhoto');
						}else if(response.data.resCode==2){
							self.createMessageBox('danger','Photo',this.$t('msgCore.dataWasNotDeleted'));
							self.closeModalBoxSecure('myModalDeletePhoto');
						}else if(response.data.resCode==3){
							self.createMessageBox('danger','Photo',this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalDeletePhoto');
						}
						self.photo = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
			}
			
		}
	
		
	},
	
	watch: {
		photoId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	
	<div id="myModalDeletePhoto" class="fade show " role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.Photo') }} <span><b>{{ photo.title }}</b> id: {{photo.id}}</span></h6>
				</div>
		
				<div class="modal-body">
					<div class="row">
						<div class="col-12">
							<p>{{ $t('msgCore.DELETE_RECORD') }}?</p>
						</div>
					</div>
				</div>
				
				<div class="modal-footer">
					<div class="btn-group">
						<button type="button" v-on:click="closeModalBoxSecureHelper('myModalDeletePhoto',photo.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>

						<button type="button" :disabled="(!auth.photosDelete) || !accData.modulAcc" v-on:click="deletePhoto(photo.id)" class="btn btn-danger btn-sm">{{ $t('msgCore.btnDelete') }}</button>
					</div>	
				</div>
	
			</div>

		</div>
			
	</div>
	
		
</template>


