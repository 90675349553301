<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
//library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import Popper from "vue3-popper";


import axios from 'axios';

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import tinymce from 'tinymce';
//import axios from 'axios';



export default defineComponent ({
	name: 'DocumentEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
		Popper
		
	},
	props: {'documentId': String, 'auth':Object, 'secData':Object},
	emits: ["documentId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			recordID:null,
			isNewRecord:true,
		
			optionsAccessLevels: [],
			optionsStates:[],
			
			
			//form
			document:[]
			
		}
	},
	
	mounted:function(){
	
		//console.log(this.documentId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			//console.log(this.documentId);				
			if(this.documentId){
				this.isNewRecord=false;
			}else{
				this.isNewRecord=true;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('documentId',this.documentId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Document/getDocumentData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
			//	console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.document = response.data.document;
				
				self.optionsAccessLevels = response.data.optionsAccessLevels;
				self.optionsStates = response.data.optionsStates;
				
		
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,documentId){
			this.unlockDocument(documentId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		
		checkSave:function(){
				
			if(!this.document.title){this.document.titleError = true; }else{this.document.titleError = false;}
			if(!this.document.state){this.document.stateError = true; }else{this.document.stateError = false;}
			if(!this.document.access){this.document.accessError = true; }else{this.document.accessError = false;}
			
			if(this.document.title && this.document.state && this.document.access){
				return true;
			}else{
				this.createMessageBox('warning','Document','fillInAllData');
				return false;
			}
		},	
	
		saveDocument:function(){
							
			if(this.checkSave()){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myDocument',JSON.stringify(this.document));
				
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Document/controlDocumentSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
					//	console.log('success2', response.data);
						if(response.data.resCode==1){
							self.createMessageBox('success','Document',this.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')
						
							self.closeModalBoxSecure('myModalEditDocument');
							
						}else if(response.data.resCode==0){
							self.createMessageBox('warning','Document',this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalEditDocument');
						}else if(response.data.resCode==2){
							self.createMessageBox('danger','Document',this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalEditDocument');
						}else if(response.data.resCode==3){
							self.createMessageBox('danger','Document',this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalEditDocument');
						}
						self.document = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
				
			}
			
		},
		
		//odomknutie zaznamu
		unlockDocument:function(documentId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',documentId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Document/controlDocumentUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
				//	console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		}
		
	},
	
	watch: {
		documentId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalEditDocument" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
		<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.Document') }} <span v-if="!document.isNewRecord"><b>{{ document.title }}</b> id: {{document.id}}</span></h6>
				</div>
			
				<div v-if="accData.modulAcc && (auth.documentsEdit)" class="modal-body">
						
						<div class="row">
							<div class="col-12">
								<div v-if="document.isCheckedOut && secData.userId != document.isCheckedOutId" class="angle">
									<span class="angle-inner" :class="{'angle-warning':document.isCheckedOut}"><i class="fa" :class="{'angle-warning':document.isCheckedOut, 'fa-exclamation-triangle':document.isCheckedOut}" aria-hidden="true"></i> {{$t('msgCore.Edited')}} </span >
									
									<Popper v-if="document.isCheckedOut" :content="document.isCheckedOutName+', '+document.isCheckedOutDate">
										<button class="personCircle v-center" v-bind:style="{background:document.isCheckedOutColor}">{{ document.isCheckedOutLetterName+''+document.isCheckedOutLetterSurname }}</button>
									</Popper>
								</div>
							</div>
						</div>
						
						<div class="row">
						
							<div class="col-md-8 col-12">
						
								<div class="form-row">
																																
									<div class="form-group col-12">
										<label :class="{'text-danger':document.titleError}"><b>{{ $t('msgCore.Title') }}</b> </label>
										<input type="text" class="form-control form-control-sm" :class="{'is-invalid':document.titleError}" v-model="document.title" v-on:change="checkSave()">
										<div v-show="document.titleError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
									</div>
									
									
									
									<div class="form-group col-12">
										<label><b>{{ $t('msgCore.Note') }}</b> </label>
										<input type="text" class="form-control form-control-sm" v-model="document.note" >
										
									</div>
									
								</div>
							</div>	
								
							<div class="col-md-4 col-12 form-control-system-data">
								
								<div class="form-row">
								
									<div class="form-group col-12">
										<label :class="{'text-danger':document.accessError}"><b>{{ $t('msgCore.AccessLevel') }}</b></label>
																							
										<select v-model='document.access' class="form-control form-control-sm " :class="{'is-invalid':document.accessError}" v-on:change="checkSave()">
											<option value="">{{ $t('msgCore.selectLevel') }}</option>
											<option v-for="(option) in optionsAccessLevels" :value="option.value" :key="option" >{{ option.title }}</option>
										</select>
										<div v-show="document.accessError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectLevel')}}</span></div>
									</div>
									
									<div class="form-group col-12">
										<label :class="{'text-danger':document.stateError}"><b>{{ $t('msgCore.State') }}</b></label>
										
											<div  v-for="(option) in optionsStates" :key="option" class="form-check">
											<label class="form-check-label">
												<input class="form-check-input" type="radio" name="optionsStates"  v-model='document.state' v-on:change="checkSave()" :value="option.value">
												{{ $t('msgCore.'+option.title) }}
												<span class="circle">
												<span class="check"></span>
												</span>
											</label>
											</div>
											<div v-show="document.stateError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectState')}}</span></div>
									</div>
								
								</div>
								
							</div>										
								
						</div>	
							
					
				</div>
				
				<div v-else class="modal-body">
					<div class="row">
						<div class="col-12">
							<div class="alert alert-warning" role="alert">
								{{$t('msgCore.accessDenied')}}
							</div>
						</div>
					</div>
				</div>
				
				<div class="modal-footer">
				
					<div class="btn-group">
						<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditDocument',document.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
						
						<button type="button" :disabled="(!auth.documentsEdit) || !accData.modulAcc" v-on:click="saveDocument()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
					</div>	
					
				</div>
				
			</div>

		</div>
			
	</div>
		

	
</template>


