<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
//library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import $ from 'jquery'; 

import MyPagination from '@/components/SystemElements/Pagination.vue'
import MyCountRecords from '@/components/SystemElements/CountRecordsOptions.vue'

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
Fancybox.bind("[data-fancybox]", {
  // Your options go here
});

import Popper from "vue3-popper";

//import '@fancyapps/fancybox/dist/jquery.fancybox.min.js'

import CategoryEdit from '@/components/Core/LibraryCategories/CategoryEdit.vue'
import CategoryDelete from '@/components/Core/LibraryCategories/CategoryDelete.vue'
//import GroupAuthorizations from '@/components/Core/Articles/GroupAuthorizations.vue'


//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';

import axios from 'axios';

export default {
	name: 'LibraryCategories',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		CategoryEdit,CategoryDelete
		,Popper
		,MyPagination,MyCountRecords
        //,GroupAuthorizations
	},
	props: ["auth","secData","params"],
	data() {
		return {
			prefix:'',
		
            
            accData:[],
			preloader:true,

            menuItems:[],
            actLangs:[],
            
            helpboxData: [],
            
            basicDataCount:0,
            basicData:[],
            selectedFilterText:'',
            
            selectedCategoryId:'',

            countPages:0,

			selectedPage:1,
			selectedRecords:10,
			selectedCol:'a.id',
			selectedSort:'desc',
			
			selectedIds:[],
			selectAllIds:false,
						
		
			filterExtended:false,
			showFilter:false,
			
			showedHelpbox:false


		}
	},
	
	mounted:function(){
	
	},
	
	methods: {
		
		//na fontende aj preview aj tabula, zober povodne idcka a idcka po aktualizacii a tie naviac zobraz na par sekund inou farbou, alebo ako nove.
		
		//default data
		
		
		loadDefaultValues:function(){
			
			//show loader
			this.preloader=true
			
			//var filterArticles = [];
			/*this.selectedArticles = [];
			for(let i = 0; i < this.optionsArticles.length; i++){
				if(this.optionsArticles[i].isSelected==true){
					this.selectedArticles.push(this.optionsArticles[i].id);
				}
			}*/
			
			//var filterArticlesTmp = JSON.stringify(this.selectedArticles);
			
			var self = this;
			axios.get('/admins/app/core/LibraryCategories/getLibraryCategoriesList.php',{
				params:{
					filterText: this.selectedFilterText,
					filterCategory: this.selectedCategory,
					
					//system
					selectedIds: this.selectedIds,
					orderCol: this.selectedCol,
					orderSort: this.selectedSort,
					page: this.selectedPage,
					records: this.selectedRecords
					
				}
			})
			.then(function (response) {
				//console.log(response.data)
			
                //hide loader
				self.preloader=false

                //access data
				self.accData = response.data.accData;
				
				//helpbox data
				self.helpboxData = 'guidebook.guideArticles';
										
			//	if(app.accessToEmployee){
					

					self.countPages = response.data.countPages;

					self.basicDataCount = response.data.basicDataCount;
					self.basicData = response.data.basicData;
					
					
					
					//oznacenie poloziek cez strankovac
					var indexToSelect;
					var selectedIdsTEMP = [];
					selectedIdsTEMP = response.data.selectedIds; 
					self.selectedIds = [];
					for(let i = 0; i < selectedIdsTEMP.length; i++){
						self.selectedIds.push(Number(selectedIdsTEMP[i]));
					}
					for(let i = 0; i < self.basicData.length; i++){
						indexToSelect = self.selectedIds.indexOf(self.basicData[i].id);
						if(indexToSelect>=0){
							self.basicData[i].selectedId=true;
						}
					}
					self.selectAllIds = false;
					
					
					
					//app.countLimit();
					
			//	}
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
				
		//odomknutie zaznamu
		unlockCategory:function(groupId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',groupId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/LibraryCategory/controlLibraryCategoryUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					console.log(response.data);
					if(response.data==1){
						self.createMessageBox('success','Category',this.$t('msgCore.dataWasUnlocked'));
						self.loadDefaultValues();
					}else if(response.data==0){
						self.createMessageBox('warning','Category',this.$t('msgCore.dataCantBeUnlocked'));
						self.loadDefaultValues();
					}else if(response.data==2){
						self.createMessageBox('danger','Category',this.$t('msgCore.dataWasNotUnlocked'));
						self.loadDefaultValues();
					}else if(response.data==3){
						self.createMessageBox('danger','Category',this.$t('msgCore.accessDenied'));
						self.loadDefaultValues();
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},
		
		
		//systemove funkcie k zoznamu
		//(od)oznacenie vsetkych poloziek
		unSelectIds:function(){
			if(this.selectAllIds){
				for(let i = 0; i < this.basicData.length; i++){
					this.basicData[i].selectedId=true;
					this.selectedIds.push(this.basicData[i].id);
				}
				this.selectedIds = this.selectedIds.filter( this.onlyUnique );
			}else{
				for(let i = 0; i < this.basicData.length; i++){
					this.basicData[i].selectedId=false;
					var indexToRemove = this.selectedIds.indexOf(this.basicData[i].id);
					if(indexToRemove>=0){
						this.selectedIds.splice(indexToRemove, 1);
						
					}
					//this.selectedIds = [];
				}
				this.selectedIds = this.selectedIds.filter( this.onlyUnique );
			}
			//console.log(this.selectedIds);
		},
		
		//oznacenie jednej polozky
		checkSelectIds:function(index,id){
			if(this.basicData[index].selectedId==true){
				this.selectedIds.push(id);
			}else{
				var indexToRemove = this.selectedIds.indexOf(id);
				this.selectedIds.splice(indexToRemove, 1);
			}
			
			this.selectedIds = this.selectedIds.filter( this.onlyUnique );	
			//console.log(this.selectedIds);
		},
		
		//radenie zoznamu - orders by
		orderByCol:function(col){
			
			if(col === this.selectedCol) {
				this.selectedSort = this.selectedSort==='asc'?'desc':'asc';
			}
			this.selectedCol = col;
			
			this.loadDefaultValues();
		},
		
		//select page 
		selPage:function(x){
			
			if(x=='prev' || x=='next'){
				if(x=='prev'&&this.selectedPage!=1){this.selectedPage=this.selectedPage-1}
				if(x=='next'&&this.selectedPage!=this.countPages){this.selectedPage=this.selectedPage+1}
			}else{
				if(this.selectedPage != x){
					this.selectedPage = Number(x);
				}
			}
			//console.log(this.selectedPage);
			this.loadDefaultValues();
			
		},

		//active menu
		isActive: function (col) {
			return this.selectedCol === col;
		},
		isAsc: function (col) {
			if(this.selectedCol === col && this.selectedSort === 'asc'){
				return true;
			}
		},
		isDesc: function (col) {
			if(this.selectedCol === col && this.selectedSort === 'desc'){
				return true;
			}
		},
		
		
		
		//zobrazi filtre
		showFilters: function(){
			if(!this.showFilter) {
				this.showFilter = true;
			}else{
				this.showFilter = false;
			}
		},
		
		//vycisti text filter
		resetTextFilter: function(){
			this.selectedFilterText = '';
			this.loadDefaultValues();
		},
		
		// zobrazi napovedu
		showHelpbox: function(){
			if(!this.showedHelpbox) {
				this.showedHelpbox = true;
			}else{
				this.showedHelpbox = false;
			}
		},
		// systemove funkcie k zoznamu KONIEC
		
		
		//modal box article editacia
		loadModalFormCategoryEdit:function(recordID){
			this.cSession();
			//console.log(recordID)
			this.selectedCategoryId=String(recordID);
			$('#myModalEditCategory').addClass('modal');
			$('#myModalEditCategory').modal({backdrop: "static"});
		},
		
		//modal box article delete
		loadModalFormCategoryDelete:function(recordID){
			this.cSession();
			this.selectedCategoryId=String(recordID);
			$('#myModalDeleteCategory').addClass('modal');
			$('#myModalDeleteCategory').modal({backdrop: "static"});
		},
		
		reloadAfterChange() {
			//console.log(e);
			this.loadDefaultValues();
			this.selectedCategoryId='';
			//this.cart.push(e);
			//this.total = this.shoppingCartTotal;
		},

		//vyber stranky v strankovaci
		reloadAfterChangeNrPage(selectedPage) {
			this.selectedPage=selectedPage
			this.loadDefaultValues();
		},
		
		//vyber poctu zaznamov
		reloadAfterChangeCountRecords(selectedCount) {
			this.selectedRecords=selectedCount
			this.loadDefaultValues();
		},

		//zmena stavu
		changeState:function(recordId,state){
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('recordId',recordId);
			bodyFormData.set('state',state);
			
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/LibraryCategory/controlLibraryCategoryChangeState.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
				//	console.log('success2', response.data);
					if(response.data.resCode==1){
						self.createMessageBox('success','Category',this.$t('msgCore.dataWasSaved'));
						self.loadDefaultValues();
					}else if(response.data.resCode==0){
						self.createMessageBox('warning','Category',this.$t('msgCore.dataCantBeSaved'));
					}else if(response.data.resCode==2){
						self.createMessageBox('danger','Category',this.$t('msgCore.dataWasNotSaved'));
					}else if(response.data.resCode==3){
						self.createMessageBox('danger','Category',this.$t('msgCore.accessDenied'));
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
	
		}
		
	},	
		
	watch: {
		'auth.libraryCategoriesList': function () {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
}

</script>

<template>
	
	<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
	<div id="version" class="version"><p>{{accData.version}}</p></div>
				
		<div v-if="accData.modulAcc" > 
					
            <div class="row">
                <div class="col-12">
            
					<div class="row">
						<div class="col-6">
							<h5>{{ $t('msgCore.LibraryCategories') }}</h5>	 
						</div> 
						
						<div class="col-6 right">
							
							<button type="button" :disabled="!auth.articlesNew" v-on:click="loadModalFormCategoryEdit(null)" class="btn btn-primary btn-sm"><i class="smire-icon fas fa-plus"></i> {{ $t('msgCore.btnAdd') }}</button>
							
						</div> 
					</div>

					<!-- filters basic -->
					<div class="row">
						<div class="input-group col-md-4 col-12">
							<button type="button" :title="$t('msgCore.btnFilter')" class="btn btn-info btn-sm my-btn" v-on:click="showFilters()">
								<span v-if="filterExtended">
									<i v-if="!showFilter" class="smire-icon fas fa-search-plus"></i> 
									<i v-else class="smire-icon fas fa-search-minus"></i>
								</span>
								<span v-else>
									<i class="smire-icon fas fa-search"></i> 
								</span>
								{{ $t('msgCore.btnFilter') }}
							</button>
							<input type="text" class="form-control form-control-sm" v-model='selectedFilterText' :placeholder="$t('msgCore.searchTitle')" v-on:change="loadDefaultValues()">
							<button type="button" :title="$t('msgCore.btnStorno')" class="btn btn-outline-info btn-sm my-btn" v-on:click="resetTextFilter()">
								<i class="smire-icon fas fa-times"></i>
							</button>
						</div>
					</div>
					

					<!-- filters extended -->
					<div v-show="showFilter && filterExtended" class="row">
						<div class="col-12">
						
						</div>					
					</div>
                        
                    
                </div>
            </div>	

            
            <!-- zakladny zoznam -->
           <div v-if="basicDataCount>0">
						
				<div class="row">
					<div class="col-12">
	
						<div class="table-responsive">
							<table class="table table-hover table-sm">
							
								<thead>
									<tr>
										<th class="orderBy" v-on:click="sort('id')" :class="{ active: isActive('id'), asc: isAsc('id'), desc: isDesc('id')}">id</th>
									
										<th width="10" class="align-middle"><div class="form-check"><label class="form-check-label"><input v-model="selectAllIds" v-on:change="unSelectIds()" class="form-check-input" type="checkbox"><span class="form-check-sign"><span class="check"></span></span></label></div></th>
									
										<th>
											{{$t('msgCore.Action')}}
										</th>
										
										<th colspan="2">{{$t('msgCore.State')}}</th>
										
										<th>{{$t('msgCore.Title')}}</th>
										
										
										
										<th>{{$t('msgCore.Created')}}</th>
										
										<th>{{$t('msgCore.Modified')}}</th>
										
										<th>{{$t('msgCore.AccessLevel')}}</th>									
									</tr>
								</thead>
								
								
								<tr v-for="(basicObj, index) in basicData" :key="basicObj" >
								
									<td>{{ basicObj.id }}</td>
									
									<td class="align-middle">
										<div class="form-check">
											<label class="form-check-label">
											<input v-model.number='basicObj.selectedId' class="form-check-input" type="checkbox" v-on:change="checkSelectIds(index,basicObj.id);">
											<span class="form-check-sign"><span class="check"></span></span>
											</label>
										</div>	
									</td>
								
									<td>
									
										<button v-if="basicObj.isCheckedOut" type="button" :disabled="!auth.libraryCategoriesUnlock" v-on:click="unlockCategory(basicObj.id)" v-bind:title="basicObj.checkedOutName+', '+basicObj.isCheckedOutDate" class="btn btn-sm"><i class="smire-icon fas fa-lock"></i></button>
									
										<div class="input-group">	
																	
											<div class="btn-group">				
												<button type="button" :disabled="!auth.libraryCategoriesEdit" v-on:click="loadModalFormCategoryEdit(basicObj.id)" :title="$t('msgCore.btnEdit')" id="myModalBtn" class="btn btn-secondary btn-sm"><i class="smire-icon fas fa-edit"></i></button>
												
												<button type="button" :disabled="!auth.libraryCategoriesDelete" v-on:click="loadModalFormCategoryDelete(basicObj.id)" :title="$t('msgCore.btnDelete')" id="myModalBtn" class="btn btn-danger btn-sm"><i class="smire-icon fas fa-trash-alt"></i></button>
																										
											</div>	
										
										</div>	
									</td>
									
									<td  :title="$t('msgCore.' + basicObj.state_name)">
										<i class="fas fa-circle" :class="{'text-success':basicObj.state==1,'text-danger':basicObj.state==2}" ></i>
									</td>	

									<td>
										<div class="togglebutton">
											<label>
												<input v-model='basicObj.isActive' :disabled="!auth.libraryCategoriesChangeState" type="checkbox" v-on:change="changeState(basicObj.id,basicObj.state)">
												<span class="toggle"></span>
											</label>
										</div>
									</td>	
													
									<td >{{ basicObj.title }}</td>

									
									<td >
										<Popper :content="basicObj.creator+', '+basicObj.createdView">
											<button class="personCircle v-center" v-bind:style="{background:basicObj.creatorColor}">{{ basicObj.creatorNameLetter+''+basicObj.creatorSurnameLetter }}</button>
										</Popper>
									</td>
										
									<td >
										<Popper v-if="basicObj.editor" :content="basicObj.editor+', '+basicObj.modifiedView">
											<button class="personCircle v-center" v-bind:style="{background:basicObj.editorColor}">{{ basicObj.editorNameLetter+''+basicObj.editorSurnameLetter }}</button>
										</Popper>
									</td>
									
								
									<td >{{ basicObj.access_name }}</td>
									
									
								</tr>
									

							
							</table>	
						</div>		
					
					</div>
				</div>
					
				<div class="row row-pagination">
					<div class="col-6 col-md-3 col-lg-2">
						<MyCountRecords :selectedRecords="selectedRecords" @changeAfterSelectCount="reloadAfterChangeCountRecords"></MyCountRecords>
					</div>
					
					<div class="col-6 col-md-3 col-lg-2">
						<div class="form-group form-group-filter">
							<span class="btn btn-default btn-sm" v-on:click="createMessageBox('alert','mymodel','btnFilter')">Počet: {{basicDataCount}}</span>
						</div>
					</div>
					
					<div class="col-12 col-md-6 col-lg-8">	
						<MyPagination :countPages="countPages" :selectedPage="selectedPage" @changeAfterSelectPage="reloadAfterChangeNrPage"></MyPagination>
					</div>
				</div>	
							
			</div>	
			
			<div v-else class="row">
				<div class="col-12">
					<div  class="alert alert-warning" role="alert">
						{{$t('msgCore.forThisSelectExistAnyData')}}
					</div>	
				</div>							
			</div>
            
        </div>	
        
        <div v-else class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="alert alert-warning" role="alert">
                        {{$t('msgCore.accessDenied')}}
                    </div>
                </div>
            </div>
        </div>

		<CategoryEdit :auth="auth" :secData="secData" :categoryId="selectedCategoryId" @changeRecord="reloadAfterChange"></CategoryEdit>
		
		<CategoryDelete :auth="auth" :secData="secData" :categoryId="selectedCategoryId" @changeRecord="reloadAfterChange"></CategoryDelete>
		
		
</template>


