<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
//library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
//import $ from 'jquery'; 

import axios from 'axios';
//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import axios from 'axios';

export default defineComponent ({
	name: 'UserDelete',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: {'groupId': String, 'auth':Object, 'secData':Object},
	emits: ["groupId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			basicData:[],
			
			mods:[]
			
		}
	},
	
	mounted:function(){
		
		
		
		//console.log(this.groupId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			//console.log(this.groupId);				
			if(this.groupId){
				this.isNewRecord=false;
			}else{
				this.isNewRecord=true;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('recordID',this.groupId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Group/getAuthorizationData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
				self.mods = response.data.mods;
				self.basicData = response.data.basicData;
			
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,groupId){
			this.unlockGroup(groupId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		saveGroupAuthorization:function(){
			//console.log(this.mods);
			var dataAuthorizations = JSON.stringify(this.mods);
			
			var bodyFormData = new FormData();
			bodyFormData.set('myAuthorizations',dataAuthorizations);
			bodyFormData.set('groupId',this.groupId);
							
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Group/controlGroupAuthorizationSave.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data.resCode==1){
						self.createMessageBox('success','Authorization',this.$t('msgCore.dataWasSaved'));
						self.$emit('changeRecord', '')
						self.closeModalBoxSecure('myModalAuthorizationGroup');
					}else if(response.data.resCode==0){
						self.createMessageBox('warning','Authorization',this.$t('msgCore.dataCantBeSaved'));
					}else if(response.data.resCode==2){
						self.createMessageBox('danger','Authorization',this.$t('msgCore.dataWasNotSaved'));
					}else if(response.data.resCode==3){
						self.createMessageBox('danger','Authorization',this.$t('msgCore.accessDenied'));
					}
					self.mods = [];
				}
			})
			.catch(error => {
				console.log(error.response)
			});
				
		},
		
		//odomknutie zaznamu
		unlockGroup:function(groupId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',groupId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Group/controlGroupUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		}
		
		
	},
	
	watch: {
		groupId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	
	<div id="myModalAuthorizationGroup" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
			<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.Group') }} <b><span>{{ basicData.title }}</span></b></h6>
				</div>
		
				<div v-if="accData.modulAcc && (auth.groupsChangeAuths)" class="modal-body">
				
					<div class="row">
						<div class="col-12">
							<p>Oprávnenia</p>
						</div>	
					</div>	
					
					<div class="form-row">
						
							<div class="col-sm-4 col-md-3 col-lg-2 col-12" v-for="(mod) in mods" :key="mod">
								<p><b>{{mod.title}}</b></p>
								<!--<div class="form-check col-12" v-for="(authorization, index2) in mod.authorizations" >
									<label class="form-check-label">
										<input v-model='authorization.isSelected' type="checkbox">
										{{authorization.title}}
									</label>
								</div>	-->
								<div   v-for="(authorization) in mod.authorizations" :key="authorization" class="togglebutton">
									<label>
										<input v-model='authorization.isSelected' type="checkbox">
										<span class="toggle"></span>
										{{authorization.title}}
									</label>
								</div>
							</div>
						
					</div>
					
				</div>
				
				<div v-else class="modal-body">
					<div class="row">
						<div class="col-12">
							<div class="alert alert-warning" role="alert">
								{{$t('msgCore.accessDenied')}}
							</div>
						</div>
					</div>
				</div>
				
				<div class="modal-footer">
				
					<div class="btn-group">
						<button type="button" v-on:click="closeModalBoxSecureHelper('myModalAuthorizationGroup',basicData.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
						
						<button type="button" :disabled="(!auth.groupsChangeAuths) || !accData.modulAcc" v-on:click="saveGroupAuthorization()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
					</div>	
					
				</div>
			
				
			
			</div>

		</div>
		
	</div>
	
	
		
		
</template>


