<script>

export default {
	name: 'Pagination',
	
	components: {
	
	},
	
	data() {
		return {
			actLang:''
		}
	},
	
	emits: ["changeAfterSelectPage"],

	props: {'countPages': Number, 'selectedPage': Number},
	
	mounted:function(){
		
	},
	
	methods: {
		
		selPage:function(x){
			var y = this.selectedPage;
			if(x=='prev' || x=='next'){
				if(x=='prev'&&y!=1){y=y-1}
				if(x=='next'&&y!=this.countPages){y=y+1}
			}else{
				if(y != x){
					y = Number(x);
				}
			}
			this.$emit('changeAfterSelectPage', y)
		}
	},	
	
	watch: {
		
	},

	computed:{
		
	},
	
}

</script>

<template>
	<div class="form-group form-group-filter">
		<nav aria-label="...">
			<ul class="pagination pagination-sm">
			
			<li class="page-item"><a  v-on:click="selPage('prev')"  class="page-link" v-bind:class="{'in-active':selectedPage==1}" href="#" >{{$t('msgCore.PrevPage')}}</a></li>
			
			<span v-for="i in countPages" :key="i">
	
				<li v-if="i==1 && i!=selectedPage" class="page-item"><a class="page-link" href="#" v-on:click="selPage(i)" >{{i}}</a></li>

			
				<li v-else-if="i>=selectedPage-1 && i!=selectedPage && i<selectedPage" class="page-item"><a class="page-link" href="#" v-on:click="selPage(i)" >{{i}}</a></li>
		
	
				<li v-else-if="i==selectedPage" class="page-item active">
					<a id="<?php echo $i; ?>"  class="page-link" href="#" v-on:click="selPage(i)" >{{i}}</a>
				</li>
		

				<li v-else-if="i<=selectedPage+1 && i!=selectedPage && i>selectedPage" class="page-item"><a class="page-link" href="#" v-on:click="selPage(i)">{{i}}</a></li>
		
				<li v-else-if="i==countPages && i!=selectedPage" class="page-item"><a class="page-link" href="#" v-on:click="selPage(i)">{{i}}</a></li>
		

			</span>
			
			<li class="page-item"><a v-on:click="selPage('next')"  class="page-link" v-bind:class="{'in-active':selectedPage==countPages}" href="#" >{{$t('msgCore.NextPage')}}</a></li>
			
			
			</ul>
		</nav>
	</div>
</template>


