<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
//library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
//import $ from 'jquery'; 

import axios from 'axios';
//import { VideoPlayer } from 'vue-video-player'

//import datePicker from 'vue-bootstrap-datetimepicker';
//import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

//import Datepicker from 'vue3-date-time-picker';
//import 'vue3-date-time-picker/dist/main.css'
	
//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import axios from 'axios';

export default defineComponent ({
	name: 'ParameterEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
		//Datepicker
	},
	props: {'parameterId': String, 'auth':Object, 'secData':Object},
	emits: ["parameterId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			isNewRecord:true,
			
			optionsAccessLevels:[],
			optionsStates:[],
			
			optionsParameterTypes:[],
			
			//form
			parameter:[]
		}
	},
	
	mounted:function(){
		
		
		
		//console.log(this.parameterId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			//console.log(this.parameterId);				
			if(this.parameterId){
				this.isNewRecord=false;
			}else{
				this.isNewRecord=true;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('parameterId',this.parameterId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Parameter/getParameterData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.parameter = response.data.parameter;
				
				self.optionsAccessLevels = response.data.optionsAccessLevels;
				self.optionsStates = response.data.optionsStates;

				self.optionsParameterTypes = response.data.optionsParameterTypes;
		
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,parameterId){
			this.unlockParameter(parameterId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		
		checkSave:function(){
				
			if(!this.parameter.title){this.parameter.titleError = true;}else{this.parameter.titleError = false;}
			
			if(!this.parameter.access){this.parameter.accessError = true;}else{this.parameter.accessError = false;}
			if(!this.parameter.state){this.parameter.stateError = true;}else{this.parameter.stateError = false;}
			if(!this.parameter.type){this.parameter.typeError = true;}else{this.parameter.typeError = false;}
			
			if(!this.parameter.titleError && !this.parameter.accessError && !this.parameter.stateError && !this.parameter.typeError){
				return true;
			}else{
				this.createMessageBox('warning','Parameter',this.$t('msgCore.fillInAllData'));
				return false;
			}
		},	
		
		saveParameter:function(){
				
			if(this.checkSave()){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myParameter',JSON.stringify(this.parameter));
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Parameter/controlParameterSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data.resCode==1){
							self.createMessageBox('success','Parameter',this.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')
							self.closeModalBoxSecure('myModalEditParameter');
						}else if(response.data.resCode==0){
							self.createMessageBox('warning','Parameter',this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalEditParameter');
						}else if(response.data.resCode==2){
							self.createMessageBox('danger','Parameter',this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalEditParameter');
						}else if(response.data.resCode==3){
							self.createMessageBox('danger','Parameter',this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalEditParameter');
						}
						self.parameter = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 		
				
			}
			
		},
		
		//odomknutie zaznamu
		unlockParameter:function(parameterId){
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',parameterId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Parameter/controlParameterUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
				//	console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		}
		
	},
	
	watch: {
		parameterId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
		<div id="myModalEditParameter" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
			<div class="modal-dialog modal-lg" style="width:100%; max-width: none;">
				<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="modal-title">{{ $t('msgCore.Parameter') }} <span v-if="parameter.isNewRecord">{{ $tc('msgCore.new',0) }} • </span><span v-else><b>{{ parameter.title }}</b> id: {{parameter.id}}</span></h6>
					</div>
						
						<div v-if="accData.modulAcc && (auth.parametersEdit || auth.parametersAdd)" class="modal-body">
							
							<div class="row">
								<div class="col-12">
									<div v-if="parameter.isCheckedOut && secData.userId != parameter.isCheckedOutId" class="angle">
										<span class="angle-inner" :class="{'angle-warning':parameter.isCheckedOut}"><i class="fa" :class="{'angle-warning':parameter.isCheckedOut, 'fa-exclamation-triangle':parameter.isCheckedOut}" aria-hidden="true"></i> {{$t('msgCore.Edited')}} </span >
										
										<span v-if="parameter.isCheckedOut" class="personCircle v-center" v-bind:style="{background:parameter.isCheckedOutColor}" v-bind:title="parameter.isCheckedOutName+', '+parameter.isCheckedOutDate">{{ parameter.isCheckedOutLetterName+''+parameter.isCheckedOutLetterSurname }}</span>
									</div>
								</div>
							</div>			
							
							<div class="row">
								
								<div class="col-md-8 col-12">
								
										<div class="form-row">
											
											<div class="form-group col-md-4 col-12">
												<label :class="{'text-danger':parameter.titleError}"><b>{{ $t('msgCore.Title') }}</b> </label>
												<input type="text" class="form-control form-control-sm" :class="{'is-invalid':parameter.titleError}" v-on:change="checkSave()" v-model="parameter.title" >
												<div v-show="parameter.titleError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
											</div>
											
											<div class="form-group col-md-8 col-12">
												<label><b>{{ $t('msgCore.Value') }}</b> </label>
												<input type="text" class="form-control form-control-sm" v-model="parameter.value" >
												
											</div>
											
										</div>
										
										<div class="form-row">
										
											<div class="form-group col-12">
												<label><b>{{ $t('msgCore.Note') }}</b> </label>
												<textarea class="form-control form-control-sm" rows="2" v-model="parameter.description" ></textarea>
											</div>
											
										</div>
										
								</div>

								<div class="col-md-4 col-12 form-control-system-data">									
								
									<div class="form-group col-12">
										<label :class="{'text-danger':parameter.accessError}"><b>{{ $t('msgCore.AccessLevel') }}</b></label>
																							
										<select v-model='parameter.access' class="form-control form-control-sm " :class="{'is-invalid':parameter.accessError}" v-on:change="checkSave()">
											<option  value="">{{ $t('msgCore.selectLevel') }}</option>
											<option v-for="option in optionsAccessLevels" :value="option.value" :key="option" >{{ option.title }}</option>
										</select>
										<div v-show="parameter.accessError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectLevel')}}</span></div>
									</div>
									
									<div class="form-group col-12">
										<label :class="{'text-danger':parameter.stateError}"><b>{{ $t('msgCore.State') }}</b></label>
										
											<div  v-for="(option) in optionsStates" :key="option" class="form-check">
											<label class="form-check-label">
												<input class="form-check-input" type="radio" name="optionsStates"  v-model='parameter.state' v-on:change="checkSave()" :value="option.value">
												{{ $t('msgCore.'+option.title) }}
												<span class="circle">
												<span class="check"></span>
												</span>
											</label>
											</div>
										<div v-show="parameter.stateError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectState')}}</span></div>
									</div>
									
									
									<div class="form-group col-12">
										<label :class="{'text-danger':parameter.typeError}"><b>{{ $t('msgCore.Type') }}</b></label>
										
										<div  v-for="(option) in optionsParameterTypes" :key="option" class="form-check">
										<label class="form-check-label">
											<input class="form-check-input" type="radio" name="optionsParameterTypes"  v-model='parameter.type' v-on:change="checkSave()" :value="option.value">
											{{ option.title }}
											<span class="circle">
											<span class="check"></span>
											</span>
										</label>
										</div>
										
										<div v-show="parameter.typeError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectType')}}</span></div>
									</div>
								
									
									
								
								</div>	
							</div>
							
						</div>
						
						<div v-else class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="alert alert-warning" role="alert">
										{{$t('msgCore.accessDenied')}}
									</div>
								</div>
							</div>
						</div>
						
						
						<div class="modal-footer">
						
							<div class="btn-group">
								<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditParameter',parameter.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
								
								<button type="button" :disabled="((!auth.parametersEdit) || (!auth.parametersAdd)) || !accData.modulAcc" v-on:click="saveParameter()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
							</div>	
							
						</div>
						
				
					
					
				
				</div>

			</div>
			
		</div>
		
		
</template>


