<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
//library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
//import $ from 'jquery'; 

import axios from 'axios';
//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import axios from 'axios';

export default defineComponent ({
	name: 'UserDelete',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: {'userId': String, 'auth':Object, 'secData':Object},
	emits: ["userId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			recordID:null,
			isNewRecord:true,

			valid_upOptions: {
				locale: 'sk',
				format: 'YYYY-MM-DD HH:mm',
				useCurrent: true,
				showClear: true,
				showClose: true,
			},
			
			valid_downOptions: {
				locale: 'sk',
				format: 'YYYY-MM-DD HH:mm',
				useCurrent: true,
				showClear: true,
				showClose: true,
			},
			
			optionsAccessLevels: [],
			optionsActivationStates:[],
			optionsTypes:[],
			optionsStates:[],
			optionsMultiLogin:[],
			
			optionsCountries:[],
			optionsRegions:[],
			optionsDistricts:[],
			optionsCities:[],
						
			//form
			user:[],
			
			sameRecords:[]
		}
	},
	
	mounted:function(){
		
		
		
		//console.log(this.userId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			//console.log(this.userId);				
			if(this.userId){
				this.isNewRecord=false;
			}else{
				this.isNewRecord=true;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('recordID',this.userId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/User/getUserData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.user = response.data.user;
			//	console.log(appCard.card);
				self.optionsAccessLevels = response.data.optionsAccessLevels;
				self.optionsStates = response.data.optionsStates;
				self.optionsActivationStates = response.data.optionsActivationStates;
				self.optionsMultiLogin = response.data.optionsMultiLogin;
				self.optionsTypes = response.data.optionsTypes;
				
				self.optionsCountries = response.data.optionsCountries;
				self.optionsRegions = response.data.optionsRegions;
				self.optionsDistricts = response.data.optionsDistricts;
				self.optionsCities = response.data.optionsCities;
				
				self.sameRecords = '';
			
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,userId){
			this.unlockUser(userId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		//odomknutie zaznamu
		unlockUser:function(userId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',userId);
			
			
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/User/controlUserUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						//self.createMessageBox('success','User','dataWasUnlocked');
						//self.loadDefaultValues();
						//self.$emit('userId', '') //tu pokracuj emit !!!!!!!!!!!!! pri zavreti modal okna
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//self.createMessageBox('warning','User','dataCantBeUnlocked');
						//self.loadDefaultValues();
					}else if(response.data==2){
						//self.createMessageBox('danger','User','dataWasNotUnlocked');
						//self.loadDefaultValues();
					}else if(response.data==3){
						//self.createMessageBox('danger','User','accessDenied');
						//self.loadDefaultValues();
					}
					
				}
			})
			.catch(error => {
				console.log(error)
			}); 
			//self.$emit('update:userId', '')
		},
		
		deleteUser:function(recordID){
				
			if(recordID){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myRecordID',recordID);
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/User/controlUserDelete.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data==1){
							self.createMessageBox('success','User',this.$t('msgCore.dataWasDeleted'));
							//app.loadDefaultValues();
							self.$emit('changeRecord', '')
							self.closeModalBoxSecure('myModalDeleteUser');
							self.user = [];
						}else if(response.data==0){
							self.createMessageBox('warning','User',this.$t('msgCore.dataCantBeDeleted'));
							
						}else if(response.data==2){
							self.createMessageBox('danger','User',this.$t('msgCore.dataWasNotDeleted'));
							
						}else if(response.data==3){
							self.createMessageBox('danger','User',this.$t('msgCore.accessDenied'));
						}
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
			}
			
		},
		
		
	},
	
	watch: {
		userId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	
	<div id="myModalDeleteUser" class="fade show " role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
			<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.DELETE_RECORD') }} <b><span>{{ user.name }} {{ user.surname }}</span></b></h6>
					
					
					
				</div>
		
						<div v-if="accData.modulAcc && (auth.usersDelete)" class="modal-body">
						
															
							
													
									<form id="myAdvanceDataDelete">
										<div class="form-row">
										
											
											<div class="form-group col-md-2 col-12">
																																						
												<label><b>{{ $t('msgCore.ID') }}</b></label><br>
												{{user.id}}
											</div>
											
											<div class="form-group col-md-4 col-12">
																																						
												<label><b>{{ $t('msgCore.Name') }}</b></label><br>
												{{ user.name }}
											</div>
											
											<div class="form-group col-md-4 col-12">
																																						
												<label><b>{{ $t('msgCore.Surname') }}</b></label><br>
												{{ user.surname }}
											</div>
											
																							
											<div class="form-group col-md-6 col-12">
												<label for="sum"><b>{{ $t('msgCore.AccessLevel') }}</b> </label><br>
												{{ user.access_name }}
											</div>
											
											
											
										</div>	
									</form>
								
						</div>
						
						<div v-else class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="alert alert-warning" role="alert">
										{{$t('msgCore.accessDenied')}}
									</div>
								</div>
							</div>
						</div>
								
						
						<div class="modal-footer">
						
							<div class="btn-group">
								<button type="button" v-on:click="closeModalBoxSecureHelper('myModalDeleteUser',user.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>

								<button type="button" :disabled="(!auth.usersDelete) || !accData.modulAcc" v-on:click="deleteUser(user.id)" class="btn btn-danger btn-sm">{{ $t('msgCore.btnDelete') }}</button>
							</div>	
							
						</div>
					
			</div>

		</div>
		
	</div>
	
		
		
</template>


